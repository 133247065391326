/* eslint-disable @typescript-eslint/no-empty-interface */
import { PostalService } from '@services/postal';
import { flow, getRoot, Instance, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '..';

const { model, number, string, array, maybeNull } = types;

const IncidentItem = model('IncidentItem', {
	complaint_result_name: maybeNull(string),
	direction: maybeNull(number),
	id: number,
	info: maybeNull(string),
	market_created_at: string,
	market_status: number,
	operator_name: string,
	postal_complaint_type_description: string,
	postal_complaint_type_id: number,
	postal_item_number: maybeNull(string),
	postal_weight: string,
	store: string,
	store_id: number,
	store_name: string,
});

const IncidentList = model('IncidentList', {
	id: maybeNull(number),
	list: maybeNull(array(IncidentItem)),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);
	return {
		setId: (id: number | null) => {
			self.id = id;
		},
		loadIncidents: flow(function* (id: number) {
			try {
				root.setLoading(true);
				const res = yield PostalService.getIncidents(id);
				self.list = res?.data || null;
			} catch (err) {
				console.log('loadIncidents', err);
			} finally {
				root.setLoading(false);
			}
		}),
		close: () => {
			self.id = null;
			self.list = null;
		},
	};
});

export default IncidentList;

export interface IncidentList extends SnapshotIn<typeof IncidentList> {}

export interface IncidentItem extends Instance<typeof IncidentItem> {}
