import colors from '@config/colors';
import { ConfigProvider, Divider, Typography } from 'antd';
import { FC } from 'react';

type Props = { tariff: number | null; pack: number | null; final: number | null };

const Summary: FC<Props> = ({ tariff, pack, final }) => {
	return (
		<ConfigProvider theme={{ components: { Divider: { colorSplit: colors.grey3 } } }}>
			<div style={{ display: 'flex', background: colors.grey5, padding: '16px 24px' }}>
				<SummaryItem name="Тариф" value={`${tariff?.toFixed(2) || 0}`} />
				<div style={{ margin: '0 24px' }}>
					<Divider type="vertical" style={{ height: '100%' }} />
				</div>
				<SummaryItem name="Упаковка" value={`${pack?.toFixed(2) || 0}`} />
				<div style={{ margin: '0 24px' }}>
					<Divider type="vertical" style={{ height: '100%' }} />
				</div>
				<SummaryItem name="Итого" value={`${final?.toFixed(2) || 0}`} />
			</div>
		</ConfigProvider>
	);
};

const SummaryItem: FC<{ name: string; value: string }> = ({ name, value }) => (
	<div style={{ flex: 1 }}>
		<Typography.Text style={{ color: colors.grey1 }}>{name}</Typography.Text>
		<h3>{value}</h3>
	</div>
);

export default Summary;
