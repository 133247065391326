import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { ConfigProvider, Popover as AntPopover, PopoverProps as AntPopoverProps } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import Content from './components/content';
import styles from './index.module.scss';

type FilterProps = Omit<AntPopoverProps, 'title' | 'content' | 'placement'>;

const Filter: FC<FilterProps> = ({ className, ...props }) => {
	const visit = useCurrentVisit();
	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					boxShadowSecondary:
						'0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
				},
			}}
		>
			<AntPopover
				{...props}
				className={cn(className, styles.wrap)}
				rootClassName={styles.popover}
				content={<Content />}
				placement="bottomLeft"
				destroyTooltipOnHide
				arrow={false}
			>
				<Button
					className={styles.button}
					icon={<Icon fontSize={16} imageName="Filter" hasMark={visit?.filterTypes.length !== 5 && true} />}
				/>
				{visit?.filterTypes.length !== 5 && (
					<Button
						className={styles.clear}
						icon={<Icon className={styles.filter__icon} fontSize={10} imageName="Close" />}
						onClick={() => visit?.setFilterTypes(-1)}
					/>
				)}
			</AntPopover>
		</ConfigProvider>
	);
};

export default observer(Filter);
