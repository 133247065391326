import Modal from '@components/modal';
import { ModalProps as AntModalProps } from 'antd';
import { FC } from 'react';

import styles from './index.module.scss';

const ActionConfirmModal: FC<AntModalProps> = ({ ...props }) => {
	return (
		<Modal
			classNameModal={styles.modal}
			cancelText="Отмена"
			hasLines={false}
			centered
			width={416}
			closable={false}
			{...props}
		/>
	);
};

export default ActionConfirmModal;
