import { ConfigProvider, Input as AntInput, InputProps as AntInputProps, InputRef } from 'antd';
import cn from 'classnames';
import { CSSProperties, useEffect, useState } from 'react';
import React from 'react';
import InputMask from 'react-input-mask';

import styles from './index.module.scss';

type InputProps = Omit<
	AntInputProps,
	'addonAfter' | 'addonBefore' | 'allowClear' | 'bordered' | 'showCount' | 'prefix' | 'size' | 'type'
> & { wrapperStyle?: CSSProperties; isPhone?: boolean };

const Input = React.forwardRef<InputRef, InputProps>(
	({ wrapperStyle, id, placeholder, className, required, isPhone, ...props }, ref) => {
		const [inputClassName, setInputClassName] = useState<string | undefined>('');
		useEffect(() => {
			if (isPhone) {
				setInputClassName(document.querySelector('input.phone-input + input')?.className);
			}
		}, [isPhone]);
		return (
			<ConfigProvider theme={{ token: { controlHeight: 38, borderRadius: 4 } }}>
				<div className={cn(className, styles.wrap)} style={wrapperStyle}>
					{isPhone ? (
						<>
							<InputMask
								{...props}
								value={String(props.value)}
								className={cn(styles.input, inputClassName, styles.input__phone, 'phone-input')}
								id={id}
								placeholder=" "
								mask="+375 99 999 99 99"
								maskChar=""
								required={required}
							/>
							<AntInput ref={ref} />
						</>
					) : (
						<AntInput
							{...props}
							className={styles.input}
							id={id}
							placeholder=" "
							bordered
							required={required}
							ref={ref}
						/>
					)}
					{placeholder && (
						<label className={styles.label} htmlFor={id}>
							{placeholder} {required && <span className={styles.asterisk}>*</span>}
						</label>
					)}
				</div>
			</ConfigProvider>
		);
	}
);

export default Input;
