import { FC } from 'react';

import CargoSpaces from './components/cargo-spaces';
import CreateSpaceModal from './components/create-space-modal';
import MovingModal from './components/moving-modal';
import TableGoodsModal from './components/table-goods-modal';

const Consolidation: FC = () => {
	return (
		<>
			<CargoSpaces />
			<MovingModal />
			<CreateSpaceModal />
			<TableGoodsModal />
		</>
	);
};

export default Consolidation;
