import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import { Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Content: FC = () => {
	const { visits } = useStore();
	const visit = useCurrentVisit();
	return (
		<ul className={styles.list}>
			{['Все', 'Заявки', 'Посылки', 'НП', 'Заказы'].map((text, i) => {
				const type = i - 1;
				return (
					<li className={styles.item} key={i}>
						<Checkbox
							className={styles.checkbox}
							onChange={() => {
								if (!visit) return;

								if (visit.lastFilter !== null && visit.lastFilter === type) {
									visits.setConfirmModal('Внимание! Данное действие приведет к очистке визита!');
								} else {
									visit.setFilterTypes(type);
								}
							}}
							checked={visit?.filterTypes.includes(type)}
							disabled={type < 0 && visit?.filterTypes.length === 5}
						>
							{text}
						</Checkbox>
						<span className={styles.count}>{visit?.getCountItemsByType(type) || ''}</span>
					</li>
				);
			})}
		</ul>
	);
};

export default observer(Content);
