import { api } from '@http/index';
import { Operator } from '@store/operator';
import axios, { AxiosResponse } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL + '/api/pos';
const apiPos = axios.create({ baseURL: API_BASE_URL });

apiPos.interceptors.request.use((config) => {
	config.headers.OperatorOpsToken = localStorage.getItem('token');
	return config;
});

const getOperator = (): Promise<AxiosResponse<Operator>> => api.get('operator/info');

// Отправить websocket
const broadcastEvent = (number: string): Promise<AxiosResponse<Operator>> =>
	apiPos.post(
		'broadcast-event',
		{ name: 'routing.number', data: { number } },
		{
			headers: { CashRegisterToken: '1O7bh13MN8QB6RP5MOqIGsUCjqyTfjMkRPxbiLTC1' },
		}
	);

const OperatorService = { getOperator, broadcastEvent };

export default OperatorService;
