import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import ItemsModal from './components/items-modal';
import EmallTable from './components/table';

let timerId: any;

const Emall: FC = () => {
	const {
		acceptance: { emall },
	} = useStore();

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(emall.loadOrders, 100);
		return () => clearTimeout(timerId);
	}, []);

	return (
		<>
			<EmallTable />
			<ItemsModal />
		</>
	);
};

export default observer(Emall);
