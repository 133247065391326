import { ConfigProvider, Input as AntInput } from 'antd';
import { TextAreaProps as AntTextAreaProps } from 'antd/es/input';
import { InputFocusOptions } from 'antd/es/input/Input';
import { TextAreaRef as AntTextAreaRef } from 'antd/es/input/TextArea';
import cn from 'classnames';
import { CSSProperties } from 'react';
import React from 'react';

import styles from './index.module.scss';

type TextAreaProps = Omit<AntTextAreaProps, 'prefix'> & { wrapperStyle?: CSSProperties };

interface TextAreaRef {
	focus: (options?: InputFocusOptions) => void;
	blur: () => void;
	resizableTextArea?: AntTextAreaRef['resizableTextArea'];
}

const { TextArea: AntTextArea } = AntInput;

const TextArea = React.forwardRef<TextAreaRef, TextAreaProps>(
	({ wrapperStyle, id, placeholder, className, required, ...props }, ref) => {
		return (
			<ConfigProvider
				theme={{
					token: { controlHeight: 38, borderRadius: 2 },
				}}
			>
				<div className={cn(className, styles.wrap)} style={wrapperStyle}>
					<AntTextArea
						{...props}
						className={styles.input}
						id={id}
						placeholder=" "
						bordered
						required={required}
						ref={ref}
						rows={3}
					/>
					{placeholder && (
						<label className={styles.label} htmlFor={id}>
							{placeholder} {required && <span className={styles.asterisk}>*</span>}
						</label>
					)}
				</div>
			</ConfigProvider>
		);
	}
);

export default TextArea;
