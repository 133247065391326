import colors from '@config/colors';
import { ConfigProvider, Tabs as AntTabs, TabsProps as AntTabsProps } from 'antd';
import { FC } from 'react';

type TabsProps = AntTabsProps;

const Tabs: FC<TabsProps & { horizontalItemGutter?: number }> = ({ horizontalItemGutter, ...props }) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: colors.blue,
				},
				components: {
					Tabs: { margin: 0, horizontalItemGutter: horizontalItemGutter || 16 },
				},
			}}
		>
			<div>
				<AntTabs style={{ display: 'inline-block' }} {...props} />
			</div>
		</ConfigProvider>
	);
};

export default Tabs;
