import { api } from '@http/index';
import { Order } from '@models/index';
import { OrderItems } from '@store/visits/order-items';
import { AxiosResponse } from 'axios';

interface OrderItemReasonType {
	order_item_id: number | null;
	reason_id: number | null;
}

export interface CancelReasonType {
	cancel_reason_id?: number | null;
	order_items?: OrderItemReasonType[];
}

export interface CorrectionReasonType {
	order_items?: OrderItemReasonType[];
	reason_id?: number | null;
}

const getOrders = (orderId: number): Promise<AxiosResponse<Order>> => {
	return api.get(`order/${orderId}/all`);
};

const getOrder = (orderId: number): Promise<AxiosResponse<Order>> => {
	return api.get(`order/${orderId}`);
};

const getOrderItems = ({ orderId }: { orderId: number }): Promise<AxiosResponse<OrderItems>> => {
	return api.get(`order/${orderId}/items`);
};

const cancelOrder = ({ orderId }: { orderId: number }, data?: CancelReasonType): Promise<AxiosResponse<void>> => {
	return api.post(`order/${orderId}/cancel`, data);
};

const cancelCorrection = ({ orderId }: { orderId: number }): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/${orderId}/correction/cancel`);
};

const saveCorrection = (orderId: number, data?: CorrectionReasonType): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/${orderId}/correction`, data);
};

const changeOrderItem = ({
	orderId,
	itemId,
	count,
}: {
	orderId: number;
	itemId?: number;
	count?: number;
}): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`/order/${orderId}/item/${itemId}/correction`, { received_quantity: count });
};

const confirmOrder = (data: {
	orders: number[];
	cash_payment: number;
	cashless_payment: number;
}): Promise<AxiosResponse<OrderItems>> => {
	return api.post(`order/received`, data);
};

const extendOrder = (id: number): Promise<AxiosResponse<{ message: string }>> => {
	return api.put(`/order/${id}/storage/extend`);
};

const OrderService = {
	getOrders,
	getOrder,
	getOrderItems,
	cancelOrder,
	changeOrderItem,
	cancelCorrection,
	saveCorrection,
	confirmOrder,
	extendOrder,
};

export default OrderService;
