import { ConfigProvider, Popover as AntPopover, PopoverProps as AntPopoverProps } from 'antd';
import React, { FC } from 'react';

import Content from './components/content';
import styles from './index.module.scss';

export interface Item {
	text: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

type ContextMenuProps = Omit<AntPopoverProps, 'title' | 'content' | 'placement'> & { items: Item[] };

const ContextMenu: FC<ContextMenuProps> = ({ children, items, ...props }) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					boxShadowSecondary:
						'0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
				},
			}}
		>
			<AntPopover
				{...props}
				rootClassName={styles.popover}
				content={<Content items={items} />}
				placement="bottomRight"
				destroyTooltipOnHide
				arrow={false}
			>
				{children}
			</AntPopover>
		</ConfigProvider>
	);
};

export default ContextMenu;
