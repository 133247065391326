import { api } from '@http/index';
import { AxiosResponse } from 'axios';

const sendSmsCode = (id: number): Promise<AxiosResponse<{ message: string; attempts_remaining: number }>> =>
	api.post(`notifications/send-verification-sms`, { clientId: id });

const sendSmsClientCode = (id: number): Promise<AxiosResponse<{ message: string }>> =>
	api.post(`notifications/send-verification-code-sms`, { clientId: id });

const checkSms = (id: number, smsCode: string): Promise<AxiosResponse<{ message: string }>> =>
	api.post(`notifications/check-sms`, { clientId: id, smsCode });

export const NotificationsService = { sendSmsCode, checkSms, sendSmsClientCode };
