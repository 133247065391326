import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ModalUnionCheck: FC<AntModalProps> = ({ className, ...props }) => {
	const visit = useCurrentVisit();

	const onClose = (): void => {
		visit?.setShowModalUnionCheck(false);
	};

	const onConfirm = (value: 0 | 1): void => {
		visit?.confirmOrders(value);
		onClose();
	};

	return (
		<AntModal
			{...props}
			open={visit?.showModalUnionCheck}
			title={<h2 className={styles.title}>Провести общую оплату заказов Емолл одним чеком?</h2>}
			className={cn(className, styles.modal)}
			width={416}
			footer={
				<footer className={styles.footer}>
					<Button onClick={() => onConfirm(1)}>Да</Button>
					<Button onClick={() => onConfirm(0)}>Нет</Button>
				</footer>
			}
			onCancel={onClose}
			centered
		/>
	);
};

export default observer(ModalUnionCheck);
