import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { Steps } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';
import InspectsDataClient from './inspects-data-client';
import InspectNumberPhone from './inspects-number-phone';

const ModalIndentify: FC = () => {
	const { indentification } = useStore();
	return (
		<Modal
			centered
			title="Идентификация клиента"
			open={indentification.isOpenModal}
			width={720}
			destroyOnClose
			onOk={() => indentification.setIsOpenModal(false)}
			onCancel={() => indentification.setIsOpenModal(false)}
			footer={
				<footer className={styles.footer}>
					<Button onClick={() => indentification.setIsOpenModal(false)}>Отмена</Button>
					<Button disabled={!indentification.isCheckedDocuments} onClick={indentification.confirm} type="primary">
						Продолжить
					</Button>
				</footer>
			}
		>
			<div>
				<div className={styles.steps}>
					<Steps
						current={indentification.step}
						progressDot
						items={[{ title: 'Проверка данных клиента' }, { title: 'Проверка номера телефона' }]}
					/>
				</div>
				{indentification.step === 0 ? <InspectsDataClient /> : <InspectNumberPhone />}
			</div>
		</Modal>
	);
};
export default observer(ModalIndentify);
