import IconButton from '@components/buttons/icon_btn';
import Input from '@components/input';
import Modal from '@components/modal';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { ConfigProvider, InputNumber as AntInputNumber } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import AddGoodsModal from '../add-goods-modal';
import styles from './index.module.scss';

const CreateSpaceModal: FC = () => {
	const [signError, setSignError] = useState('');
	const {
		operator,
		shipment: {
			emall: { consolidation },
		},
	} = useStore();

	const close = (): void => {
		consolidation.modalCreateSpace.setShow(false);
		setSignError('');
		consolidation.modalCreateSpace.setSign('');
		consolidation.modalCreateSpace.setGoods(null);
		leave();
	};

	const submit = (): void => {
		consolidation.addSpace('КБТ').finally(close);
	};

	const handleSignChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		setSignError('');
		consolidation.modalCreateSpace.setSign(evt.target.value);
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (consolidation.modalCreateSpace.show && !consolidation.modalCreateSpace.goods) {
			window.Echo &&
				operator?.id &&
				listenChannel(
					({ number }) => consolidation.modalCreateSpace.addGoods(+number),
					'.routing.number',
					operator?.id
				);
		}

		return leave;
	}, [consolidation.modalCreateSpace.show]);

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			title="Создание грузоместа КБТ"
			okText="Готово"
			cancelText="Отмена"
			open={consolidation.modalCreateSpace.show}
			onCancel={close}
			onOk={submit}
			centered
			width={640}
			destroyOnClose
			okButtonProps={{
				style: consolidation.modalCreateSpace.goods ? {} : { display: 'none' },
				disabled: !consolidation.modalCreateSpace.sign,
			}}
		>
			{consolidation.modalCreateSpace.goods ? (
				<ConfigProvider
					theme={{
						token: {
							controlHeight: 34,
							borderRadius: 4,
							colorBgContainerDisabled: '#fff',
							colorTextDisabled: colors.grey1,
						},
					}}
				>
					<div className={styles.goods}>
						<span>{consolidation.modalCreateSpace.goods.market_id || ''}</span>
						<span className={styles.goods__name}>{consolidation.modalCreateSpace.goods.name || ''}</span>
						<AntInputNumber
							className={styles.goods__count}
							value={consolidation.modalCreateSpace.goods.count}
							defaultValue={1}
							min={1}
							max={100}
							formatter={(value) => `${value} шт.`}
							parser={(value) => value?.replace(' шт.', '') as unknown as number}
							onChange={consolidation.modalCreateSpace.setGoodsCount}
						/>
						<IconButton
							className={styles.goods__del}
							imageName="Close"
							color="#001529"
							onClick={() => {
								consolidation.modalCreateSpace.setBarcode(null);
								consolidation.modalCreateSpace.setGoods(null);
							}}
						/>
					</div>
					<Input
						className={cn(styles.sign, { [styles.sign__error]: signError })}
						value={consolidation.modalCreateSpace.sign}
						onChange={handleSignChange}
						maxLength={50}
						placeholder="Номер пломбы"
						required
					/>
					{signError ? <p className={styles.error}>{signError}</p> : null}
				</ConfigProvider>
			) : (
				<p className={styles.description}>
					<span>
						Отсканируйте штрихкод товара <br />
						или
					</span>{' '}
					<a onClick={() => consolidation.showModalBarcodeGoods(true)}>введите вручную</a>
				</p>
			)}
			<AddGoodsModal />
		</Modal>
	);
};

export default observer(CreateSpaceModal);
