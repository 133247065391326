import Icon, { ImageName } from '@images/index';
import { FC } from 'react';

import styles from './index.module.scss';

export interface Item {
	text: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	iconName?: ImageName;
}

type ContentProps = {
	items: Item[];
};

const Content: FC<ContentProps> = ({ items }) => {
	return (
		<ul className={styles.list}>
			{items.map(({ text, onClick, iconName }, i) => (
				<li className={styles.item} key={i}>
					<a className={styles.link} onClick={onClick}>
						{iconName && <Icon className={styles.icon} imageName={iconName} fontSize={24} />}
						<span>{text}</span>
					</a>
				</li>
			))}
		</ul>
	);
};

export default Content;
