import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import PostalInfoModal from '@pages/main/visits/components/visit/compoments/postal-info-modal';
import {
	DocumentKeys,
	documentTypes,
} from '@pages/main/visits/components/visit/compoments/postal-table/components/documentTypes';
import { TaskService } from '@services/task';
import { MailTask } from '@store/tasks';
import { message, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { unpropagatedClickHandler } from 'utils/unpropagatedClickHandler';

import styles from './index.module.scss';

const MailTasksTable: FC = () => {
	const store = useStore();
	const [selectedItem, setSelectedItem] = useState<MailTask | null>(null);
	const [messageApi, contextHolder] = message.useMessage({
		top: 160,
		duration: 2,
		prefixCls: 'messages',
	});
	const columns: ColumnsType<MailTask> = [
		{
			dataIndex: 'info',
			render: (_, { number, task_status_name }) => (
				<div style={{ display: 'flex', gap: 8 }}>
					<div style={{ paddingTop: 6 }}>
						<Icon imageName="Mail" />
					</div>
					<div>
						<div style={{ fontWeight: 500 }}>{number}</div>
						<div>{task_status_name}</div>
					</div>
				</div>
			),
			width: 199,
		},
		{
			dataIndex: 'name',
			render: (_, { receiver_fio, sender_fio }) => (
				<>
					<div>{sender_fio}</div>
					<div>{receiver_fio}</div>
				</>
			),
			width: 280,
		},
		{
			dataIndex: 'phone',
			render: (_, { receiver_phone_number, sender_phone_number }) => (
				<div style={{ width: 136 }}>
					<div>{sender_phone_number}</div>
					<div>{receiver_phone_number}</div>
				</div>
			),
			width: 162,
		},
		{
			dataIndex: 'dimensions',
			render: (_, { weight, width, height, length }) => (
				<>
					{length + width + height > 0 && <div>{`${length}*${width}*${height}`}</div>}
					<div>{`${weight} кг.`}</div>
				</>
			),
			width: 190,
		},
		{
			dataIndex: 'positions',
			render: (_, { additional_services }) => {
				const services = [];
				for (const serv in additional_services) {
					// @ts-ignore
					if (additional_services[String(serv)]) {
						services.push(getServiceName(serv));
					}
				}
				return (
					<div>
						{services.map((value) => (
							<Tag key={value}>{value}</Tag>
						))}
					</div>
				);
			},
		},
		{
			render: (_, { is_processed, id }) =>
				(!is_processed && (
					<Button
						type="primary"
						onClick={unpropagatedClickHandler(() => {
							store.setLoading(true);
							TaskService.processPostal(id)
								.then(({ data }) =>
									messageApi.open({ type: 'success', content: data.message, className: styles.message })
								)
								.catch((err) => store.notice.setNotice({ message: err?.error || err?.message || '' }))
								.finally(() => {
									store.setLoading(false);
									store.loadTask();
								});
						})}
						style={{ width: 109 }}
					>
						Обработать
					</Button>
				)) ||
				null,
			width: 97,
		},
		{
			render: (_, { additional_services: { is_cash_on }, number, id, is_juristic, type }) => (
				<div className={styles.actions} onClick={(e) => e.stopPropagation()}>
					<ContextMenu
						items={(
							[
								'label',
								'thermal',
								'return-application',
								// 'software-receipt',
								is_juristic === 0 && 'get-acceptance-for-individuals',
								is_cash_on === 1 && 'np-acceptance-receipt',
								type === 1 && 'get-issuance-for-individuals',
							].filter((item) => item) as DocumentKeys[]
						).map((type) => ({
							text: documentTypes[type],
							onClick: () => store.print.setPrintPreview({ type, number: number || '', id }),
						}))}
					>
						<IconButton imageName="Sheet" style={{ margin: '0 4px' }} />
					</ContextMenu>
				</div>
			),
			width: 72,
		},
	];
	return (
		<>
			{contextHolder}
			<Table
				showHeader={false}
				pagination={false}
				columns={columns}
				dataSource={store.tasks?.filteredPostals}
				onRow={(obj) => ({
					onClick: () => {
						setSelectedItem(obj);
					},
					className: styles.row,
				})}
				className={styles.table}
				locale={{ emptyText: 'Нет данных' }}
			/>
			<PostalInfoModal id={selectedItem?.id || null} type="postal" onClose={() => setSelectedItem(null)} />
		</>
	);
};

export default observer(MailTasksTable);

const getServiceName = (key: string): string => {
	switch (key) {
		case 'is_fragile':
			return 'Хрупкость';
		case 'is_inventory':
			return 'Опись';
		case 'is_completeness':
			return 'Проверка комплектности';
		case 'is_oversize':
			return 'Громоздкость';
		case 'is_declared':
			return 'ОЦ';
		case 'is_cash_on':
			return 'НП';
	}
	return '';
};
