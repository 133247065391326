import Button from '@components/buttons/button';
import Input from '@components/input';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC } from 'react';

import styles from './index.module.scss';

const ClientSearch: FC = () => {
	const { indentification } = useStore();

	const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
		indentification.setSearchValue(e.target.value);
	};

	return (
		<div className={styles.wrapper}>
			<Input onChange={handleInput} value={indentification.searchValue} className={styles.input} placeholder="Поиск" />
			<Button htmlType="submit" onClick={indentification.searchUser} disabled={!indentification.searchValue}>
				Поиск
			</Button>
		</div>
	);
};

export default observer(ClientSearch);
