import PdfModal from '@components/pdf-modal';
import { useStore } from '@hooks/useStore';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import cn from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import AcceptanceHeader from './components/header';
import styles from './index.module.scss';

let timerId: any;

const Acceptance: FC = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [noShadow, setNoShadow] = useState(true);
	const location = useLocation();
	const path = location.pathname.split('/')[2];
	const { acceptance } = useStore();

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(acceptance.loadHeaderInfo, 100);
		return () => clearTimeout(timerId);
	}, []);

	return (
		<Layout>
			<Header
				className={cn(styles.header, {
					[styles.header__inner]: path === 'emall',
					[styles.header__no_shadow]: noShadow,
				})}
			>
				<AcceptanceHeader />
			</Header>
			<Content>
				<OverlayScrollbarsComponent
					className={cn(styles.scroll, { [styles.scroll__inner]: path === 'emall' })}
					options={{ scrollbars: { autoHide: 'move' } }}
					events={{
						scroll: () => setNoShadow(!ref.current?.parentElement?.scrollTop),
					}}
				>
					<div className={styles.scroll__wrap} ref={ref}>
						<Outlet />
					</div>
				</OverlayScrollbarsComponent>
			</Content>
			<PdfModal />
		</Layout>
	);
};

export default Acceptance;
