import Input from '@components/input';
import Select, { SelectProps } from '@components/select';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { Postal } from '@models/index';
import StoreService, { Store } from '@services/store';
import { InputRef } from 'antd';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

type Stores = Awaited<ReturnType<typeof StoreService.getStores>>['data'];

const PostOfficeSelection: FC<
	SelectProps & {
		getParamStore?: (val: any) => void;
		needParamStore?: string;
		wrapperStyle?: CSSProperties;
		filter?: (value: Store) => boolean;
		error?: string;
	}
> = ({ needParamStore, getParamStore, wrapperStyle, filter, error, ...props }) => {
	const [stores, setStores] = useState<Stores>([]);
	const [inputValue, setInputValue] = useState<string>('');
	const allStores = useRef<Stores>([]);
	const inputRef = useRef<InputRef>(null);
	const visit = useCurrentVisit();
	const postal = visit?.processedPostal as Postal;

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
		setInputValue(value);
		setStores(allStores.current.filter((el) => el.name.toLowerCase().includes(value.toLocaleLowerCase())));
	};

	useEffect(() => {
		const id = setTimeout(async () => {
			try {
				const stores = await StoreService.getStores();
				allStores.current = stores.data;
				setStores(stores.data);
			} catch (err: any) {
				console.log('getStores', err);
			}
		}, 100);

		return () => clearTimeout(id);
	}, []);

	useEffect(() => {
		if (getParamStore && needParamStore && props.value) {
			const currentStore = stores.find((store) => store.id === props.value);
			//@ts-ignore
			currentStore && getParamStore(currentStore[needParamStore]);
		}
	}, [props.value, stores, needParamStore && postal?.weight.value]);

	return (
		<div>
			<Select
				dropdownRender={(menu) => (
					<>
						<div style={{ padding: 8 }}>
							<Input value={inputValue} onChange={handleInputChange} ref={inputRef} />
						</div>
						<div className={styles.options}>{menu}</div>
					</>
				)}
				{...props}
				options={stores
					.filter((item) => (filter ? filter(item) : true))
					.map((store) => ({ value: store.id, label: store.name }))}
				onDropdownVisibleChange={(open) => setTimeout(() => open && inputRef.current && inputRef.current.focus(), 200)}
				className={styles.select}
				wrapperStyle={wrapperStyle}
				onSelect={() => {
					setInputValue('');
					setStores(allStores.current);
				}}
			/>
			{error ? <span className={styles.error}>{error}</span> : null}
		</div>
	);
};

export default observer(PostOfficeSelection);
