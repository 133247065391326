import { Select } from 'antd/lib';
import { FC } from 'react';

import styles from './index.module.scss';

export type ReasonSelectorProps = {
	options?: {
		label: string;
		value: number;
	}[];
} & Parameters<typeof Select>[0];

const ReasonSelector: FC<ReasonSelectorProps> = ({ options, ...props }) => {
	return (
		<Select
			className={styles.select}
			placeholder={<span className={styles.title}>Выберите причину</span>}
			options={options}
			{...props}
		/>
	);
};

export default ReasonSelector;
