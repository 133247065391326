import { FC } from 'react';

import styles from './index.module.scss';

interface TextSelectionProps {
	text: string;
	position: number;
	length: number;
}

const TextSelection: FC<TextSelectionProps> = ({ text, position, length }) => {
	return (
		<span>
			{text.slice(0, position)}
			<span className={styles.highlight}>{text.slice(position, position + length)}</span>
			{text.slice(position + length)}
		</span>
	);
};

export default TextSelection;
