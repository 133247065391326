/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import { ShipmentService } from '@services/shipment';
import { cast, destroy, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '..';

const { string, optional, number, union, literal, model, maybeNull, array } = types;

const Order = model('order', {
	id: number,
	order_market_id: number,
	receiver_fio: maybeNull(string),
	bags: array(
		model('bags', {
			name: string,
			is_scanned: optional(union(literal(0), literal(1)), 0),
		})
	),
});

const Item = model('items', {
	id: number,
	name: '',
	order_market_id: number,
	is_scanned: optional(union(literal(0), literal(1)), 0),
	isNewError: false,
	key: maybeNull(number),
});

const ItemsModal = model('modal-items', {
	routing_items: maybeNull(array(Item)),
	scanned_count: number,
	count: number,
})
	.actions((self) => {
		return {
			scanBarcode: flow(function* (barcode: string) {
				const root = getRoot<typeof RootStore>(self);

				if (!self.routing_items) {
					root.notice.setNotice({ message: 'Нет пакетов' });
					return;
				}

				root.setLoading(true);

				try {
					const item = self.routing_items.find(({ name }) => name === barcode);

					if (item) {
						yield ShipmentService.putItem(item.id, { is_scanned: 1 });
						root.acceptance.emall.loadItems();
					} else {
						const res = yield AcceptanceService.getEmallItemName(barcode);
						self.routing_items.unshift({ ...res.data, isNewError: true, key: res.data.id });
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
					throw err?.error || err?.message || 'Ошибка';
				} finally {
					root.setLoading(false);
				}
			}),
			deleteItem: flow(function* (item: IItem) {
				const root = getRoot<typeof RootStore>(self);

				if (!self.routing_items) {
					root.notice.setNotice({ message: 'Нет пакетов' });
					return;
				}

				root.setLoading(true);

				try {
					if (item.isNewError) {
						self.routing_items = cast(self.routing_items.filter(({ id }) => id !== item.id));
					} else {
						yield ShipmentService.putItem(item.id, { is_scanned: 0 });
						root.acceptance.emall.loadItems();
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
		};
	})
	.views((self) => ({
		get errorCount() {
			return self.routing_items?.filter(({ isNewError }) => isNewError).length;
		},
	}));

const Emall = model('emall', {
	orders: maybeNull(array(Order)),
	modalItems: maybeNull(ItemsModal),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);
	return {
		loadOrders: flow(function* () {
			root.setLoading(true);
			self.orders = null;
			try {
				const res = yield AcceptanceService.getEmallOrders();
				self.orders = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadItems: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getEmallItems();
				const addedItems = self.modalItems?.routing_items?.filter(({ isNewError }) => isNewError) || [];
				const items = [...addedItems, ...res.data.routing_items];

				self.modalItems = self.modalItems ? { ...res.data, routing_items: cast(items) } : ItemsModal.create(res.data);
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalItems() {
			self.modalItems = null;
		},
		hideOrder(order: IOrder) {
			destroy(order);
		},
	};
});

export default Emall;

export interface IItemsModal extends SnapshotIn<typeof ItemsModal> {}
export interface IItem extends SnapshotIn<typeof Item> {}
export interface IOrder extends SnapshotIn<typeof Order> {}
