import { ConfigProvider, Popover as AntPopover, PopoverProps as AntPopoverProps } from 'antd';
import React, { FC } from 'react';

import styles from './index.module.scss';

type PopoverProps = Omit<AntPopoverProps, 'title' | 'placement'>;

const Popover: FC<PopoverProps> = ({ children, ...props }) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					boxShadowSecondary: '0px 2px 8px rgba(0, 0, 0, 0.15)',
				},
			}}
		>
			<AntPopover
				{...props}
				rootClassName={styles.popover}
				// content={<Content items={items} />}
				placement="bottomRight"
				destroyTooltipOnHide
			>
				{children}
			</AntPopover>
		</ConfigProvider>
	);
};

export default Popover;
