import colors from '@config/colors';
import { Badge as AntBadge, BadgeProps as AntBadgeProps, ConfigProvider } from 'antd';
import { FC } from 'react';

type BadgeProps = Omit<AntBadgeProps, 'color' | 'status'> & { color: 'blue' | 'red' };

const Badge: FC<BadgeProps> = ({ children, dot, color, ...props }) => {
	return (
		<ConfigProvider theme={{ token: { fontSizeSM: dot ? 18 : 12, colorText: 'unset' } }}>
			<AntBadge dot={dot} color={colors[color]} {...props}>
				{children}
			</AntBadge>
		</ConfigProvider>
	);
};

export default Badge;
