import IconButton from '@components/buttons/icon_btn';
import colors from '@config/colors';
import { FC } from 'react';

interface CounterProps {
	value: number;
	onAdd?: () => void;
	onRemove?: () => void;
}

const commonStyle: React.CSSProperties = {
	width: 36,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
};

const Counter: FC<CounterProps> = ({ value, onAdd, onRemove }) => (
	<div style={{ display: 'flex', alignItems: 'center', height: '100%', background: colors.grey4, userSelect: 'none' }}>
		<div style={commonStyle}>
			<IconButton
				imageName="Minus"
				stroke={colors.black}
				fontSize={13}
				onClick={onRemove}
				style={{ lineHeight: 1, height: '90%' }}
			/>
		</div>
		<div style={{ ...commonStyle, minWidth: 36, fontWeight: 500, lineHeight: '22px' }}>{value}</div>
		<div style={commonStyle}>
			<IconButton
				imageName="Plus"
				onClick={onAdd}
				stroke={colors.black}
				style={{ lineHeight: 1, height: '90%' }}
				fontSize={13}
			/>
		</div>
	</div>
);

export default Counter;
