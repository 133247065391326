import { useStore } from '@hooks/useStore';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const Auth: FC = () => {
	const { token, login } = useParams();
	const store = useStore();

	if (token) {
		const user = { name: login ?? 'no name' };
		localStorage.setItem('token', token);
		localStorage.setItem('user', JSON.stringify(user));
		store.setUser(user);
	}
	return <Navigate to="/" replace />;
};

export default Auth;
