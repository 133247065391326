/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, string, maybeNull } = types;

const Operator = model('User', {
	FIO: string,
	login: string,
	id: number,
	is_pos_enabled: false,
	store: model('Store', { id: number, name: string }),
	call_center_phones: maybeNull(
		model('Phones', {
			evropochta: string,
			emall: string,
		})
	),
}).actions((self) => ({
	setPos(value: boolean) {
		self.is_pos_enabled = value;
	},
}));

export default Operator;

export interface Operator extends SnapshotIn<typeof Operator> {}
