import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import Input from '@components/input';
import { Typography } from 'antd';
import React, { FC, useState } from 'react';
import { formatPhoneNumber } from 'utils/formatPhone';

import styles from './index.module.scss';

type InitialDataType = {
	name: string;
	patronymic: string;
	surname: string;
	phone: string;
};

export interface PersonProps {
	editable?: boolean;
	title: string;
	name?: string;
	phone?: string;
	initialData?: InitialDataType;
	onChange?: (data: InitialDataType) => void;
}

const Person: FC<PersonProps> = ({
	editable,
	title,
	name,
	phone,
	initialData = { name: '', patronymic: '', surname: '', phone: '' },
	onChange,
}) => {
	const [edit, setEdit] = useState(false);
	const [data, setData] = useState(initialData);
	const setField = (field: string, value: string): void => {
		setData((prev) => ({ ...prev, [field]: value }));
	};
	const isValid = (): boolean => !!(data.name && data.surname && data.phone);

	const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>, name: string): void => {
		const newValue = value.trim();
		setField(name, newValue ? newValue : '');
	};

	if (edit)
		return (
			<div>
				<span className={styles.title__edit}>{title}</span>
				<div className={styles.form}>
					<Input placeholder="Фамилия" required value={data.surname} onChange={(e) => handleChange(e, 'surname')} />
					<Input placeholder="Имя" required value={data.name} onChange={(e) => handleChange(e, 'name')} />
					<Input placeholder="Отчество" value={data.patronymic} onChange={(e) => handleChange(e, 'patronymic')} />
					<Input
						placeholder="Телефон"
						required
						value={data.phone}
						onChange={(e) => setField('phone', e.target.value)}
						isPhone
					/>
					<div className={styles.buttons}>
						<Button
							onClick={() => {
								setEdit(false);
								setData(initialData);
							}}
						>
							Отмена
						</Button>
						<Button
							type="primary"
							onClick={() => {
								onChange && onChange(data);
								setEdit(false);
							}}
							disabled={!isValid()}
						>
							Сохранить
						</Button>
					</div>
				</div>
			</div>
		);
	else
		return (
			<div>
				<div className={styles.wrap__title}>
					<span className={styles.title}>{title}</span>
					{editable && <IconButton imageName="Edit" onClick={() => setEdit(true)} fontSize={16} />}
				</div>
				<Typography className={styles.name}>{name}</Typography>
				<Typography>{formatPhoneNumber(phone)}</Typography>
			</div>
		);
};

export default Person;
