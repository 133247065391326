import TextSelection from '@components/text-selection';
import { FC } from 'react';

interface TextSelectionNameProps {
	text: string;
	selection: string;
}

const TextSelectionName: FC<TextSelectionNameProps> = ({ text, selection }) => {
	const values = text.split(' ');
	const highlight = selection.toLocaleLowerCase();

	return text.toLocaleLowerCase().includes(highlight) ? (
		<span>
			{values.map((value, i) => {
				if (value.toLocaleLowerCase().includes(highlight)) {
					const position = value.toLocaleLowerCase().indexOf(highlight);
					const length = highlight.length;

					return <TextSelection key={i} text={`${value} `} position={position} length={length} />;
				}

				return `${value} `;
			})}
		</span>
	) : (
		<span>{text}</span>
	);
};

export default TextSelectionName;
