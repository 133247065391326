// import colors from '@config/colors';
import colors from '@config/colors';
import { ConfigProvider, Radio as AntRadio, RadioGroupProps } from 'antd';
import { FC } from 'react';

// import styles from './index.module.scss';
type RadioButtonProps = Omit<RadioGroupProps, 'optionType'>;

const RadioButton: FC<RadioButtonProps> = ({ ...props }) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Radio: { colorPrimary: colors.blue, buttonPaddingInline: 16, borderRadius: 2 },
				},
			}}
		>
			<AntRadio.Group optionType="button" {...props} />
		</ConfigProvider>
	);
};

export default RadioButton;
