import Button from '@components/buttons/button';
import Input from '@components/input';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { addZero } from 'utils/addZero';

import styles from './index.module.scss';

const InspectNumberPhone: FC = () => {
	const { indentification } = useStore();
	const [time, setTime] = useState<number | null>(null);
	const [textButton, setTextButton] = useState('Отправить код');
	const [isShow, setIsShow] = useState(false);

	useEffect(() => {
		const timer = setInterval(() => {
			if (time !== null && time >= 0) {
				if (time === 0) {
					setTextButton(`Отправить еще код`);
					clearInterval(timer);
				} else {
					setTextButton(`Отправить еще код 00:${addZero(time)}`);
				}
				setTime(time - 1);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [time]);

	const handleSendCodeClick = (): void => {
		indentification.sendCode().then(() => {
			setIsShow(true);
			setTime(indentification.user?.vc_attempts_remaining === 0 ? 0 : 30);
		});
	};

	if (!indentification.user) return null;
	return (
		<div className={styles.wrapper}>
			<div>
				<p>{`${indentification.user.surname} ${indentification.user.name} ${indentification.user.patronymic_name}`}</p>
				<p>{indentification.user.address_name}</p>
			</div>
			<div>
				<Input
					disabled
					className={styles.phone}
					isPhone
					placeholder="Телефон"
					value={indentification.user.phone_number}
				/>
				<div className={styles.wrapper__inspect}>
					<Button
						disabled={indentification.user.vc_attempts_remaining === 0 || (time !== null && time >= 0)}
						onClick={handleSendCodeClick}
					>
						{textButton}
					</Button>
					{isShow && (
						<p className={styles.status}>
							{indentification.user.vc_attempts_remaining > 0
								? `Осталось попыток: ${indentification.user.vc_attempts_remaining}`
								: 'Попытки исчерпаны, новые появятся завтра'}
						</p>
					)}
				</div>
				{isShow && (
					<>
						<Input
							className={styles.code}
							placeholder="Код"
							value={indentification.valueCode || ''}
							onChange={(evt) => indentification.setValueCode(evt.target.value)}
							status={indentification.smsCodeError ? 'error' : undefined}
						/>
						{indentification.smsCodeError && (
							<span className={styles.code__message}>{indentification.smsCodeError}</span>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default observer(InspectNumberPhone);
