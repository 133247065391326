import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import { useStore } from '@hooks/useStore';
import { Order, Postal } from '@models/index';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { FC, useState } from 'react';

import { DocumentKeys, documentTypes } from '../documentTypes';
import styles from './index.module.scss';

export interface ActionsProps {
	id: number;
	isEditPostal?: boolean;
	type: 'postal' | 'payment' | 'requistion' | 'order';
	postal?: Postal;
	order?: Order;
	onClick: (action: 'report' | 'print' | 'incident' | 'delete' | 'correction' | 'edit') => void;
}

const incidentStatuses: (number | undefined)[] = [10, 15];

const Actions: FC<ActionsProps> = ({ id, type, onClick, postal, order, isEditPostal }) => {
	const store = useStore();
	const [visible, setVisible] = useState(false);

	return (
		<div
			className={cn(styles.actions, { [styles.actions__visible]: visible })}
			onClick={(evt) => evt.stopPropagation()}
		>
			{isEditPostal && (
				<Tooltip title="Редактировать">
					<IconButton imageName="Edit" onClick={() => onClick('edit')} />
				</Tooltip>
			)}
			{(type === 'postal' || type === 'requistion') && (
				<ContextMenu
					onOpenChange={(visible) => setVisible(visible)}
					items={(
						[
							'label',
							'thermal',
							// type === 'postal' && 'return-application',
							// type === 'postal' && 'software-receipt',
							// type === 'postal' && postal?.additional_services.is_cash_on === 1 && 'np-acceptance-receipt',
							// type === 'requistion' && postal?.is_juristic === 1 && 'receipt-upon-acceptance-for-legal-entities',
							type === 'requistion' && postal?.is_juristic === 1 && 'get-acceptance-for-legal-entities',
							postal?.is_juristic === 0 && postal.market_status > 0 && 'get-acceptance-for-individuals',
							type === 'postal' && (postal?.market_status === 96 ? 'refund-receipt' : 'get-issuance-for-individuals'),
						].filter((item) => item) as DocumentKeys[]
					).map((type) => ({
						text: documentTypes[type],
						onClick: () => store.print.setPrintPreview({ type, number: postal?.number || '', id }),
					}))}
				>
					<IconButton imageName="Sheet" />
				</ContextMenu>
			)}
			{type === 'payment' && (
				<>
					<IconButton
						imageName="Sheet"
						onClick={() =>
							store.print.setPrintPreview({
								type: 'get-group-issuing-cash-on-delivery',
								number: postal?.number || '',
								id,
							})
						}
					/>
				</>
			)}
			{type === 'order' && (
				<>
					<Tooltip title="Товарный чек">
						<IconButton
							imageName="Sheet"
							onClick={() =>
								store.print.setPrintPreview({ type: 'sales-receipt', number: String(order?.market_id), id })
							}
						/>
					</Tooltip>
					<Tooltip title="Коррекция/отмена">
						<IconButton imageName="Exclamation" onClick={() => onClick('correction')} />
					</Tooltip>
				</>
			)}
			{(type === 'postal' || (type === 'requistion' && incidentStatuses.includes(postal?.market_status))) && (
				<Tooltip title="Действие">
					<IconButton imageName="Exclamation" onClick={() => onClick('incident')} />
				</Tooltip>
			)}
			<Tooltip title="Удалить">
				<IconButton imageName="Close" onClick={() => onClick('delete')} />
			</Tooltip>
		</div>
	);
};

export default Actions;
