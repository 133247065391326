/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, maybeNull, union, literal, optional, array, string, number } = types;

const Product = model('product', {
	id: number,
	item_market_id: number,
	name: string,
	is_scanned: optional(union(literal(0), literal(1)), 0),
	barcode: maybeNull(number),
	barcode_second: maybeNull(number),
	date: string,
	order_market_id: maybeNull(number),
	reason: string,
	bags: array(string),
	isSurplus: false,
});

export default Product;

export interface IProduct extends SnapshotIn<typeof Product> {}
