import Icon from '@images/index';
import { FC } from 'react';

const NoCompensations: FC = () => (
	<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		<div style={{ display: 'inline-block' }}>
			<Icon imageName="NoTasks" fontSize={100} />
			<div style={{ textAlign: 'center' }}>Возмещений нет</div>
		</div>
	</div>
);

export default NoCompensations;
