import { api } from '@http/index';
import { AxiosResponse } from 'axios';

export interface Store {
	id: number;
	name: string;
	status: number;
	weight_limit: number | null;
}

const getStores = (): Promise<AxiosResponse<Store[]>> => api.get(`stores`);
const getQrCode = (): Promise<AxiosResponse<ArrayBuffer>> => api.get('/store/qr-code', { responseType: 'arraybuffer' });

const StoreService = { getStores, getQrCode };

export default StoreService;
