import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import People from '@pages/main/visits/components/visit/compoments/postal-info-modal/components/people';
import { FC } from 'react';

import styles from './index.module.scss';

const Info: FC = () => {
	const {
		shipment: { modalInfo },
	} = useStore();

	if (!modalInfo) return <NoData text="Нет данных" />;

	return (
		<div className={styles.body}>
			<ul className={styles.list}>
				<li className={styles.item}>
					<span>Состав</span>
					<span>{modalInfo.counts.total} шт.</span>
				</li>
				<li className={styles.item}>
					<span>Номер</span>
					<span>{modalInfo.id}</span>
				</li>
				<li className={styles.item}>
					<span>Дата создания</span>
					<span>{modalInfo.created_at}</span>
				</li>
				<li className={styles.item}>
					<span>Дата передачи</span>
					<span>{modalInfo.transferred_at}</span>
				</li>
				<li className={styles.item}>
					<span>Дата отгрузки</span>
					<span>{modalInfo.shipped_at}</span>
				</li>
			</ul>
			<People
				title="Водитель"
				fio={modalInfo.driver_fio || ''}
				phone={String(modalInfo.driver_phone || '')}
				number={modalInfo.car_plate || ''}
			/>
		</div>
	);
};

export default Info;
