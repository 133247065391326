import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import RoutingSpaces from '@pages/main/components/routing-spaces';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import Counters from '../info-modal/components/counters';
import styles from './index.module.scss';

const InfoByDriverModal: FC = () => {
	const {
		acceptance: { modalInfoByDriver, closeModalInfoByDriver, confirm },
	} = useStore();

	return (
		<Modal
			title={`Маршрутный лист ${modalInfoByDriver?.id.join(', ') || ''}`}
			footer={
				<div className={styles.footer}>
					<Button onClick={closeModalInfoByDriver}>Закрыть</Button>
					<Button type="primary" onClick={confirm}>
						Зафиксировать приезд
					</Button>
				</div>
			}
			open={!!modalInfoByDriver}
			width={880}
			onCancel={closeModalInfoByDriver}
			className={styles.body}
			classNameModal={styles.modal}
			centered
			destroyOnClose
		>
			<Counters isByDriver />
			<RoutingSpaces spaces={modalInfoByDriver?.routing_spaces || []} />
		</Modal>
	);
};

export default observer(InfoByDriverModal);
