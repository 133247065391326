import IconButton from '@components/buttons/icon_btn';
import NoData from '@components/no-data';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import { ConfigProvider, InputNumber } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Table: FC = () => {
	const {
		shipment: {
			emall: {
				consolidation: { modalGoodsTable },
			},
		},
	} = useStore();

	if (!modalGoodsTable) return <NoData text="Товаров нет" />;

	const { space, updateItem } = modalGoodsTable;

	return (
		<ConfigProvider
			theme={{
				token: {
					controlHeight: 32,
					borderRadius: 4,
					colorBgContainerDisabled: 'transparent',
					colorTextDisabled: colors.grey1,
				},
				components: { InputNumber: { hoverBorderColor: colors.black } },
			}}
		>
			{space && space.items.length > 0 ? (
				<table className={styles.table}>
					<thead className={styles.row}>
						<tr>
							<th className={styles.cell} style={{ width: 100 }}>
								<span>Код товара</span>
							</th>
							<th className={styles.cell}>
								<span>Наименование</span>
							</th>
							<th className={styles.cell} style={{ width: 200 }}>
								<span style={{ paddingLeft: 12 }}>Кол-во</span>
							</th>
							<th className={styles.cell} style={{ width: 28 }}></th>
						</tr>
					</thead>
					{space.items.map(({ id, item_market_id: itemId, name, is_scanned: isScan, isSurplus }) => (
						<tr
							className={cn(styles.row, {
								[styles.row__new]: isSurplus,
								[styles.row__check]: isScan,
							})}
							key={id}
						>
							<td className={styles.cell} style={{ width: 100 }}>
								<span>{itemId}</span>
							</td>
							<td className={styles.cell}>
								<span>{name}</span>
							</td>
							<td className={styles.cell} style={{ width: 200 }}>
								<InputNumber
									className={cn(styles.counter, { [styles.counter__disabled]: !isScan })}
									// value={count}
									defaultValue={1}
									min={1}
									max={100}
									formatter={(value) => `${value} шт.`}
									// parser={(value) => value?.replace(' шт.', '') as unknown as number}
									// onChange={setCount}
									disabled={!isScan}
								/>
							</td>
							<td className={styles.cell} style={{ width: 28, textAlign: 'right' }}>
								{isScan || isSurplus ? (
									<IconButton imageName="Close" fontSize={20} onClick={() => updateItem(id)} />
								) : (
									''
								)}
							</td>
						</tr>
					))}
				</table>
			) : (
				<NoData text="Товаров нет" />
			)}
		</ConfigProvider>
	);
};

export default observer(Table);
