import Select from '@components/select';
import colors from '@config/colors';
import { AddressService } from '@services/address';
import { AutoComplete, ConfigProvider } from 'antd';
import { SelectProps } from 'antd/es/select';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

type CitySearchFieldProps = Parameters<typeof Select>[0];

const CitySearchField: FC<
	CitySearchFieldProps & {
		staticCity?: {
			value: number;
			label: string;
		} | null;
	}
> = ({ className, placeholder, required, onSearch, staticCity, disabled, ...props }) => {
	const [options, setOptions] = useState<SelectProps['options']>([]);
	const [value, setValue] = useState('');
	const handleSearch = async (value: string): Promise<void> => {
		onSearch && onSearch(value);
		if (value.length) {
			const res = await AddressService.search(value);
			setOptions(res.data.cities.map(({ id, name_full }) => ({ value: id, label: name_full })));
		}
	};

	useEffect(() => {
		if (staticCity) {
			setOptions([staticCity]);
			setValue(staticCity.label);
		} else {
			setOptions([]);
			setValue('');
		}
	}, [staticCity]);

	return (
		<ConfigProvider
			theme={{
				token: { controlHeight: 38, borderRadius: 2 },
			}}
		>
			<div className={cn(className, styles.wrap, { [styles.wrap__disabled]: disabled })}>
				<AutoComplete
					placeholder=" "
					options={options}
					showSearch
					hideArrow
					{...props}
					onSearch={handleSearch}
					style={{ width: '100%' }}
					value={value}
					onChange={(val) => setValue(String(val))}
					onSelect={(val, option) => {
						setValue(option.label);
						props.onSelect && props.onSelect(val, option);
					}}
					dropdownStyle={{ padding: 0 }}
					notFoundContent={
						props.notFoundContent !== undefined ? (
							props.notFoundContent
						) : (
							<div style={{ textAlign: 'center', color: colors.grey1, padding: 16 }}>Ничего не найдено</div>
						)
					}
					disabled={disabled}
				/>
				{placeholder && (
					<span
						className={cn(styles.label, {
							[styles.label__active]: value,
							[styles.label__disabled]: disabled,
						})}
					>
						<span style={{ position: 'relative', zIndex: 1 }}>
							{placeholder} {required && <span style={{ color: colors.red }}>*</span>}
						</span>
					</span>
				)}
			</div>
		</ConfigProvider>
	);
};

export default CitySearchField;
