export const addDownloadLink = (file: ArrayBuffer, fileName: string): void => {
	const fileURL = URL.createObjectURL(new Blob([file]));
	const link = document.createElement('a');

	link.href = fileURL;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
