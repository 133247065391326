/* eslint-disable @typescript-eslint/no-empty-interface */
import { KassaService } from '@services/kassa';
import ShiftService from '@services/shift';
import dayjs from 'dayjs';
import { applySnapshot, flow, getRoot, getSnapshot, SnapshotIn, types } from 'mobx-state-tree';
import { priceFormat } from 'utils/priceFormat';
import { round } from 'utils/round';

import { RootStore } from '..';

const { model, number, array, string, boolean, optional, maybeNull, literal, union } = types;
let controller: AbortController;

export const Report = model('Report', {
	cash_register_number: number,
	date: string,
	id: number,
	operator_name: maybeNull(string),
	report_number: number,
});

export const ReportFull = model('ReportFull', {
	cash_register_number: maybeNull(number),
	isZReport: optional(boolean, false),
	date: maybeNull(string),
	id: maybeNull(number),
	card_canceled: maybeNull(number),
	card_order_paid: maybeNull(number),
	card_order_return: maybeNull(number),
	card_post_paid: maybeNull(number),
	card_post_return: maybeNull(number),
	cash_canceled: maybeNull(number),
	cash_order_paid: maybeNull(number),
	cash_order_return: maybeNull(number),
	cash_post_compensation: maybeNull(number),
	cash_post_money_back: maybeNull(number),
	cash_post_paid: maybeNull(number),
	cash_post_return: maybeNull(number),
	cash_register_added: maybeNull(number),
	certificate_canceled: maybeNull(number),
	certificate_order_paid: maybeNull(number),
	certificate_post_paid: maybeNull(number),
	collection: maybeNull(number),
	info: optional(string, ''),
	report_number: maybeNull(number),
	total_sum: maybeNull(number),
	store_id: maybeNull(number),
	readonly: optional(boolean, false),
	isCashRegisterNumberError: false,
	isDateError: false,
	isReportNumberError: false,
	isTotalSumError: false,
	isCashRegisterAddedError: false,
})
	.actions((self) => ({
		setCashRegisterNumber(value: number | null) {
			self.isCashRegisterNumberError = false;
			self.cash_register_number = value;
		},
		setReportNumber(value: number | null) {
			self.isReportNumberError = false;
			self.report_number = value;
		},
		setTotalSum(value: number | null) {
			self.isTotalSumError = false;
			self.total_sum = value;
		},
		setCashRegisterAdded(value: number | null) {
			self.isCashRegisterAddedError = false;
			self.cash_register_added = value;
		},
		setCashPostPaid(value: number | null) {
			self.cash_post_paid = value;
		},
		setCardPostPaid(value: number | null) {
			self.card_post_paid = value;
		},
		setCertificatePostPaid(value: number | null) {
			self.certificate_post_paid = value;
		},
		setCashPostMoneyBack(value: number | null) {
			self.cash_post_money_back = value;
		},
		setCashOrderPaid(value: number | null) {
			self.cash_order_paid = value;
		},
		setCardOrderPaid(value: number | null) {
			self.card_order_paid = value;
		},
		setCertificateOrderPaid(value: number | null) {
			self.certificate_order_paid = value;
		},
		setCashCanceled(value: number | null) {
			self.cash_canceled = value;
		},
		setCardCanceled(value: number | null) {
			self.card_canceled = value;
		},
		setCertificateCanceled(value: number | null) {
			self.certificate_canceled = value;
		},
		setCashPostReturn(value: number | null) {
			self.cash_post_return = value;
		},
		setCardPostReturn(value: number | null) {
			self.card_post_return = value;
		},
		setCashPostCompensation(value: number | null) {
			self.cash_post_compensation = value;
		},
		setCashOrderReturn(value: number | null) {
			self.cash_order_return = value;
		},
		setCardOrderReturn(value: number | null) {
			self.card_order_return = value;
		},
		setCollection(value: number | null) {
			self.collection = value;
		},
		setInfo(value: string) {
			self.info = value;
		},
		setDate(value: string) {
			self.isDateError = false;
			self.date = value;
		},
	}))
	.views((self) => ({
		get totalCashPostAndOrder() {
			return priceFormat((self.cash_post_paid || 0) + (self.cash_order_paid || 0), false).replace(',', '.');
		},
		get totalCardPostAndOrder() {
			return priceFormat((self.card_post_paid || 0) + (self.card_order_paid || 0), false).replace(',', '.');
		},
		get totalCashPostAndOrderReturn() {
			return priceFormat((self.cash_post_return || 0) + (self.cash_order_return || 0), false).replace(',', '.');
		},
		get totalCardPostAndOrderReturn() {
			return priceFormat((self.card_post_return || 0) + (self.card_order_return || 0), false).replace(',', '.');
		},
		get totalCertificatePostAndOrder() {
			return priceFormat((self.certificate_post_paid || 0) + (self.certificate_order_paid || 0), false).replace(
				',',
				'.'
			);
		},
		get ballance() {
			return priceFormat(
				(self.cash_register_added || 0) +
					(self.cash_post_paid || 0) +
					(self.cash_order_paid || 0) -
					(self.cash_post_money_back || 0) -
					(self.collection || 0) -
					(self.cash_post_return || 0) -
					(self.cash_order_return || 0) -
					(self.cash_post_compensation || 0),
				false
			).replace(',', '.');
		},
	}));

export const List = maybeNull(array(Report));

export const KassaModel = model('Compensations', {
	list: List,
	reportFull: optional(ReportFull, {}),
	reportModal: optional(boolean, false),
	requiredFieldError: 'Обязательное поле',
	printStatus: maybeNull(union(literal('process'), literal('error'), literal('critical'))),
	printData: maybeNull(model('PrintData', { message: '', isCritical: false })),
	isOpenQrModal: false,
})
	.actions((self) => {
		const rootStore = getRoot<typeof RootStore>(self);
		return {
			loadKassa: flow(function* () {
				rootStore.setLoading(true);
				if (controller) controller.abort();
				controller = new AbortController();

				try {
					const kassa = yield KassaService.getCashReport(controller.signal);
					self.list = kassa?.data || null;
					rootStore.setLoading(false);
				} catch (error: any) {
					error.code !== 'ERR_CANCELED' && rootStore.setLoading(false);
				}
			}),
			setOpenQrModal(value: boolean) {
				self.isOpenQrModal = value;
			},
			setZReport(payload: boolean) {
				self.reportFull.isZReport = payload;
			},
		};
	})
	.actions((self) => {
		const rootStore = getRoot<typeof RootStore>(self);

		return {
			unloadKassa() {
				self.list = null;
			},
			getReportFull: flow(function* (id: number) {
				rootStore.setLoading(true);
				try {
					const report = yield KassaService.getCashReportId(id);
					self.reportFull = { ...report.data, readonly: true };
					self.reportModal = true;
				} catch (err: any) {
					rootStore.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					rootStore.setLoading(false);
				}
			}),
			postReport: flow(function* () {
				if (!self.reportFull.cash_register_number) {
					self.reportFull.isCashRegisterNumberError = true;
					return;
				}
				if (!self.reportFull.date) {
					self.reportFull.isDateError = true;
					return;
				}
				if (!self.reportFull.report_number) {
					self.reportFull.isReportNumberError = true;
					return;
				}
				if (self.reportFull.total_sum === null) {
					self.reportFull.isTotalSumError = true;
					return;
				}
				if (self.reportFull.cash_register_added === null) {
					self.reportFull.isCashRegisterAddedError = true;
					return;
				}
				self.setZReport(true);
				rootStore.setLoading(true);

				try {
					yield KassaService.postCashReport({
						...getSnapshot(self.reportFull),
						date: self.reportFull.date.split('.').reverse().join('-'),
					});

					self.reportModal = false;
					applySnapshot(self.reportFull, {});
					self.list = null;
					ShiftService.getShift().catch(rootStore.cleanShift);
				} catch (err: any) {
					rootStore.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					rootStore.setLoading(false);
				}
			}),
			openReportModal(data?: any) {
				if (data && data?.extendedZReportIsError === false) {
					self.reportFull.cash_register_added = data.zReport.sumDepositPerShift;
					self.reportFull.cash_register_number = data.zReport.registerNumberSKO;
					self.reportFull.report_number = data.zReport.numberCheck;
					self.reportFull.date = dayjs(data.zReport.dateCloseShift).format('DD.MM.YYYY');
					self.reportFull.total_sum = data.zReport.sumSalePaymentsPerShift;
					self.reportFull.cash_post_paid = round(
						data.extendedZReport.evropochta.service.cash + data.extendedZReport.evropochta.cashOnDelivery.cash
					);
					self.reportFull.card_post_paid = round(
						data.extendedZReport.evropochta.service.cashLess + data.extendedZReport.evropochta.cashOnDelivery.cashLess
					);
					self.reportFull.cash_order_paid = data.extendedZReport.emall.cash;
					self.reportFull.card_order_paid = data.extendedZReport.emall.cashless;
					self.reportFull.certificate_order_paid = data.extendedZReport.emall.certificate;
					self.reportFull.cash_post_money_back = data.extendedZReport.evropochta.saleCashOnDelivery;
					self.reportFull.cash_canceled = round(
						data.extendedZReport.emall.rollback.cash +
							data.extendedZReport.evropochta.cashOnDelivery.rollback.cash +
							data.extendedZReport.evropochta.service.rollback.cash
					);
					self.reportFull.card_canceled = round(
						data.extendedZReport.emall.rollback.cashless +
							data.extendedZReport.evropochta.cashOnDelivery.rollback.cashless +
							data.extendedZReport.evropochta.service.rollback.cashless
					);
					self.reportFull.cash_post_return = round(
						data.extendedZReport.evropochta.refund.cash + data.extendedZReport.evropochta.compensate.refund
					);
					self.reportFull.cash_order_return = data.extendedZReport.emall.refund.cash;
					self.reportFull.card_post_return = data.extendedZReport.evropochta.refund.cashless;
					self.reportFull.card_order_return = data.extendedZReport.emall.refund.cashless;
					self.reportFull.cash_post_compensation = data.extendedZReport.evropochta.compensate.pay;
				}

				self.reportModal = true;
			},
			closeReportModal() {
				self.reportModal = false;
				applySnapshot(self.reportFull, {});
				ShiftService.getShift().catch(rootStore.cleanShift);
			},
			setPrintStatus: (value: 'process' | 'error' | 'critical' | null) => {
				self.printStatus = value;
			},
			setPrintData: ({
				type,
				message,
				isCritical,
				success_ids,
				error_ids,
			}: {
				type: 'pos' | 'postal' | 'order';
				message: string;
				isCritical: boolean;
				success_ids: number[];
				error_ids: number[];
			}) => {
				self.printStatus = isCritical ? 'critical' : 'error';
				self.printData = { message, isCritical };

				const path = window.location.pathname;

				if (type !== 'pos' && path.startsWith('/visits')) {
					const visit = rootStore.visits.visitMap.get(path[path.length - 1]);

					if (type === 'order') {
						visit?.setErrorOrders({ success_ids, error_ids });
					}

					if (type === 'postal') {
						visit?.setErrorPostals({ success_ids, error_ids });
					}
				}
			},
		};
	});

export interface Compensations extends SnapshotIn<typeof KassaModel> {}
export interface Report extends SnapshotIn<typeof Report> {}
export interface ReportFull extends SnapshotIn<typeof ReportFull> {}
