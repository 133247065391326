import { api } from '@http/index';
import { Payment } from '@store/visits/payment';
import { AxiosResponse } from 'axios';

const postLog = (data: {
	market_id: number | null;
	model_type: string;
	request: string;
	response: string;
}): Promise<AxiosResponse<Payment>> => api.put('operator/log', data);

const LogService = { postLog };

export default LogService;
