import InputNumber from '@components/inputNumber';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

const inputStyle: React.CSSProperties = { height: 38, width: '100%' };

const PostAndOrders: FC = () => {
	const { kassa } = useStore();
	return (
		<div>
			<div style={{ display: 'flex', gap: 16, paddingBottom: 8, fontWeight: 500 }}>
				<div style={{ flex: 1 }}></div>
				<div style={{ flex: 1 }}>Почта</div>
				<div style={{ flex: 1 }}>Заказы</div>
				<div style={{ flex: 1 }}>Итого</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 16, color: colors.grey1 }}>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Наличными</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={inputStyle}
							value={kassa.reportFull?.cash_post_paid || 0}
							setValue={kassa.reportFull?.setCashPostPaid}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={inputStyle}
							value={kassa.reportFull?.cash_order_paid || 0}
							setValue={kassa.reportFull?.setCashOrderPaid}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber style={inputStyle} value={kassa.reportFull?.totalCashPostAndOrder || 0} disabled />
					</div>
				</div>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Картой</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={inputStyle}
							value={kassa.reportFull?.card_post_paid || 0}
							setValue={kassa.reportFull?.setCardPostPaid}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={inputStyle}
							value={kassa.reportFull?.card_order_paid || 0}
							setValue={kassa.reportFull?.setCardOrderPaid}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber style={inputStyle} value={kassa.reportFull?.totalCardPostAndOrder || 0} disabled />
					</div>
				</div>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Сертификатом</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={inputStyle}
							value={kassa.reportFull?.certificate_post_paid || 0}
							setValue={kassa.reportFull?.setCertificatePostPaid}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={inputStyle}
							value={kassa.reportFull?.certificate_order_paid || 0}
							setValue={kassa.reportFull?.setCertificateOrderPaid}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber style={inputStyle} value={kassa.reportFull?.totalCertificatePostAndOrder || 0} disabled />
					</div>
				</div>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Выплата НП</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={inputStyle}
							value={kassa.reportFull?.cash_post_money_back || 0}
							setValue={kassa.reportFull?.setCashPostMoneyBack}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}></div>
					<div style={{ flex: 1 }}></div>
				</div>
				{/* <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Выплата ДП</div>
					<div style={{ flex: 1 }}>
						<Input style={inputStyle} disabled={kassa.reportFull?.readonly} />
					</div>
					<div style={{ flex: 1 }}></div>
					<div style={{ flex: 1 }}></div>
				</div> */}
			</div>
		</div>
	);
};

export default observer(PostAndOrders);
