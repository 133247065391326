import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Counters: FC = () => {
	const {
		shipment: {
			modalInfo,
			routeSheet: { setDriverModal },
		},
	} = useStore();

	if (!modalInfo) return null;

	return (
		<div className={styles.counters}>
			<div className={styles.counters__wrap}>
				{modalInfo.driver_fio ? (
					<div className={styles.driver}>
						<span>Водитель</span>
						<b>{modalInfo.driver_fio || ''}</b>
						<IconButton
							className={styles.icon}
							imageName="Close"
							color={colors.grey2}
							onClick={() => setDriverModal('del')}
						/>
						<span className={cn(styles.tag, { [styles.tag__red]: !!modalInfo.is_mercenary })}>
							{modalInfo.is_mercenary ? 'Отсканируйте грузоместа' : 'Сканирует водитель'}
						</span>
					</div>
				) : (
					<Button className={styles.button} type="text" onClick={() => setDriverModal('add')}>
						Добавить водителя
					</Button>
				)}
				<ul className={styles.list}>
					<li className={styles.item}>
						<span className={styles.item__name}>Мешок</span>
						<span className={styles.counter}>
							<span className={styles.counter__current}>{modalInfo.counts.bags_scanned}</span>
							<span>/{modalInfo.counts.bags}</span>
						</span>
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Посылка</span>
						<span className={styles.counter}>
							<span className={styles.counter__current}>{modalInfo.counts.postals_scanned}</span>
							<span>/{modalInfo.counts.postals}</span>
						</span>
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Ящик</span>
						<span className={styles.counter}>
							<span className={styles.counter__current}>{modalInfo.counts.boxes_scanned}</span>
							<span>/{modalInfo.counts.boxes}</span>
						</span>
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Товар</span>
						<span className={styles.counter}>
							<span className={styles.counter__current}>{modalInfo.counts.goods_scanned}</span>
							<span>/{modalInfo.counts.goods}</span>
						</span>
					</li>
					<li className={styles.item}>
						<span className={styles.item__name}>Денежный мешок</span>
						<span className={styles.counter}>
							<span className={styles.counter__current}>{modalInfo.counts.money_bags_scanned}</span>
							<span>/{modalInfo.counts.money_bags}</span>
						</span>
					</li>
				</ul>
			</div>
			<span className={cn(styles.counter, styles.counter__total)}>
				<span className={styles.counter__current}>{modalInfo.counts.total_scanned}</span>
				<span>/{modalInfo.counts.total}</span>
			</span>
		</div>
	);
};

export default observer(Counters);
