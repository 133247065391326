/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, array, string, maybeNull } = types;

const EmallTask = model('order', {
	id: number,
	market_id: number,
	expired_days: number,
	can_extended_for: number,
	receiver_fio: string,
	receiver_phone_number: string,
	bags: array(model('bag', { id: number, market_id: number, name: string })),
});

const MailTask = model('postal-item', {
	id: number,
	number: string,
	task_status_name: maybeNull(string),
	is_juristic: number,
	is_processed: number,
	receiver_fio: string,
	receiver_phone_number: string,
	sender_fio: string,
	sender_phone_number: maybeNull(string),
	weight: number,
	width: number,
	height: number,
	length: number,
	type: number,
	additional_services: model('additional_services', {
		is_fragile: number,
		is_inventory: number,
		is_completeness: number,
		is_oversize: number,
		is_declared: number,
		is_cash_on: number,
	}),
});

export const TasksModel = model('Tasks', {
	postal_quantity: number,
	order_quantity: number,
	postal_items: array(MailTask),
	orders: array(EmallTask),
	search: maybeNull(string),
})
	.actions((self) => ({
		setSearch(value: string) {
			self.search = value;
		},
	}))
	.views((self) => ({
		get tasksAmount() {
			return self.postal_items.length + self.orders.length;
		},

		get orderAmount() {
			return self.orders.length;
		},

		get postalAmount() {
			return self.postal_items.length;
		},

		get filteredOrders() {
			return self.orders.filter(
				({ market_id, receiver_fio }) =>
					String(market_id)
						.toLowerCase()
						.includes(self.search?.toLowerCase() || '') ||
					receiver_fio.toLocaleLowerCase().includes(self.search?.toLocaleLowerCase() || '')
			);
		},

		get filteredPostals() {
			return self.postal_items.filter(
				({ number, receiver_fio, sender_fio }) =>
					number.toLowerCase().includes(self.search?.toLowerCase() || '') ||
					receiver_fio.toLocaleLowerCase().includes(self.search?.toLocaleLowerCase() || '') ||
					sender_fio.toLocaleLowerCase().includes(self.search?.toLocaleLowerCase() || '')
			);
		},
	}));

export interface Task extends SnapshotIn<typeof TasksModel> {}

export interface EmallTask extends SnapshotIn<typeof EmallTask> {}

export interface MailTask extends SnapshotIn<typeof MailTask> {}
