import Echo from 'laravel-echo';
import io from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_API_URL;

declare global {
	interface Window {
		io: typeof io;
		Echo: Echo;
	}
}

window.io = io;

export const createSocketConnection = (): void => {
	window.Echo = new Echo({
		client: io,
		broadcaster: 'socket.io',
		host: URL,
		transports: ['polling', 'websocket'],
		auth: {
			headers: {
				OperatorOpsToken: localStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
		},
	});
};

export const listenChannel = (callBack: (payload: any) => void, event: string, id?: number): void => {
	if (!id) return console.log('listenChannel - нет id', event);
	window.Echo.private(`web.operator.${id}`).listen(event, (payload: any) => callBack(payload));
};

export const leaveEvent = (event: string, id?: number): void => {
	if (!id) return console.log('leaveEvent - нет id', event);
	window.Echo.private(`web.operator.${id}`).stopListening(event);
};
