import Badge from '@components/badge';
import Button from '@components/buttons/button';
import TextSelectionName from '@components/text-selection-name';
import TextSelectionNumber from '@components/text-selection-number';
import { useStore } from '@hooks/useStore';
// import TextSelectionPhone from '@components/text-selection-phone';
import Icon from '@images/index';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { checkPostalIcon } from 'utils/checkPostalIcon';

import styles from './index.module.scss';

export interface IOption {
	type: 0 | 1 | 2 | 3;
	number: string;
	senderPhone?: string;
	senderName?: string;
	receiverPhone: string;
	receiverName?: string;
	selectString: string;
	externalId?: string | null;
	agreementTypeId?: number;
	id: number;
	status: string;
	marketStatus?: number;
	hasButton?: boolean;
}

const Option: FC<IOption> = ({
	type,
	number,
	senderName,
	receiverName,
	selectString,
	externalId,
	agreementTypeId,
	id,
	status,
	marketStatus,
	hasButton,
}) => {
	const { extendedSearch } = useStore();

	const handleOptionClick = (): void => extendedSearch.setSelectedItem({ type: type === 3 ? 'order' : 'postal', id });

	return (
		<div className={styles.option} onClick={handleOptionClick}>
			<div className={styles.icon}>
				{((type === 1 || type === 2) && (
					<Icon imageName={type === 1 ? checkPostalIcon(marketStatus, agreementTypeId) : 'Payments'} />
				)) ||
					(type === 0 && (
						<Badge dot color="blue" offset={[-1, 3]}>
							<Icon imageName="Mail" />
						</Badge>
					)) ||
					(type === 3 && <Icon imageName="ShoppingBag" />)}
			</div>
			<div className={styles.number}>
				<div>
					<b>
						<TextSelectionNumber text={String(number)} selection={selectString} />
					</b>
				</div>
				{externalId && (
					<Tooltip title={externalId} className={styles.id}>
						<span>
							<TextSelectionNumber text={externalId} selection={selectString} />
						</span>
					</Tooltip>
				)}
			</div>
			{/* <div className={styles.phone}>
				<span>{!!senderPhone && <TextSelectionPhone text={senderPhone} selection={selectString} />}</span>
				<span>
					<TextSelectionPhone text={receiverPhone} selection={selectString} />
				</span>
			</div> */}
			<div className={styles.name}>
				<span>{!!senderName && <TextSelectionName text={senderName} selection={selectString} />}</span>
				<span>{!!receiverName && <TextSelectionName text={receiverName} selection={selectString} />}</span>
			</div>
			<span className={styles.status}>{status || ''}</span>
			{hasButton && (
				<Button
					className={styles.button}
					type="primary"
					onClick={(evt) => {
						evt.stopPropagation();
						extendedSearch.setIdOpsChange(id);
						extendedSearch.setNumberOpsChange(number);
					}}
				>
					Изменить ОПС
				</Button>
			)}
		</div>
	);
};

export default observer(Option);
