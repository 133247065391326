import { VisitModel } from '@store/visits/visit';
import { useParams } from 'react-router';

import { useStore } from './useStore';

export const useCurrentVisit = (): VisitModel | null | undefined => {
	const { visit } = useParams();
	const store = useStore();
	if (visit) return store.visits.visitMap.get(visit);
	return null;
};
