import Modal from '@components/modal';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { Steps } from 'antd';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import PackSelection from '../common/pack-selection';
import PostalProcessForm from '../common/postal-process-form';
import PostalProcessButtons from '../common/postal-process-form/components/buttons';
import Footer from './components/footer';
import Ordering from './components/ordering';
import SenderInfo from './components/sender-info';
import styles from './index.module.scss';

const CreateOrder: FC = observer(() => {
	const visit = useCurrentVisit();
	const [addPack, setAddPack] = useState(false);
	const model = visit?.createOrderModel;
	const [messageApi, contextHolder] = message.useMessage({
		top: 64,
		duration: 2,
		prefixCls: 'messages',
	});

	useEffect(() => {
		let timerId: number;

		if (visit?.createOrderModel?.errorMessage) {
			messageApi.open({ type: 'error', content: visit?.createOrderModel?.errorMessage, className: 'message' });
			timerId = window.setTimeout(() => visit.createOrderModel?.setError(''), 2000);
		}

		return () => {
			clearTimeout(timerId);
		};
		//eslint-disable-next-line
	}, [visit?.createOrderModel?.errorMessage]);

	return (
		<Modal
			centered
			title="Создание заявки"
			footer={!addPack && ((model?.step === 2 && <PostalProcessButtons />) || <Footer />)}
			open={!!model}
			width={model?.step === 2 ? 976 : 720}
			destroyOnClose
			onCancel={visit?.cancelOrder}
			maskClosable={false}
			className={styles.modal}
		>
			<div>
				<div style={{ width: 672, margin: '40px auto 32px' }}>
					<Steps
						progressDot
						current={model?.step}
						items={[{ title: 'Данные отправителя' }, { title: 'Оформление заявки' }, { title: 'Приёмка ПО' }]}
					/>
				</div>
				{model?.step === 2 ? (
					<div style={{ height: addPack ? 530 + 60 : 530 }}>
						{addPack ? (
							<PackSelection onBackClick={() => setAddPack(false)} postal={visit?.processedPostal} />
						) : (
							<PostalProcessForm addPackClick={() => setAddPack(true)} />
						)}
					</div>
				) : (
					<div style={{ margin: '0 24px 48px' }}>
						{model?.step === 0 && <SenderInfo />}
						{model?.step === 1 && <Ordering />}
					</div>
				)}
			</div>
			{contextHolder}
		</Modal>
	);
});

export default CreateOrder;
