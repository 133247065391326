import Button from '@components/buttons/button';
import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const Header: FC = () => {
	const {
		acceptance: {
			emall: { modalItems },
		},
	} = useStore();

	const [barcodeModal, setBarcodeModal] = useState(false);
	const [barcode, setBarcode] = useState<number | null>(null);
	const [barcodeError, setBarcodeError] = useState('');

	const closeBarcodeModal = (): void => {
		setBarcodeModal(false);
		setBarcode(null);
		setBarcodeError('');
	};

	const handleBarcodeChange = (value: number | null): void => {
		setBarcode(value);
		setBarcodeError('');
	};

	return (
		<>
			<header className={styles.header}>
				<Button className={styles.header__button} type="text" onClick={() => setBarcodeModal(true)}>
					Ввести штрихкод вручную
				</Button>
				<span className={styles.counter}>
					<span className={styles.counter__current}>
						{modalItems?.scanned_count || 0}
						{modalItems?.errorCount ? <span>+{modalItems.errorCount}</span> : null}
					</span>
					<span>/{modalItems?.count || 0}</span>
				</span>
			</header>
			<Modal
				classNameModal={styles.modal}
				className={styles.modal__body}
				title={null}
				okText="Добавить"
				okButtonProps={{ disabled: !!barcodeError || !barcode }}
				cancelButtonProps={{ style: { display: 'none' } }}
				open={barcodeModal}
				onCancel={closeBarcodeModal}
				onOk={() => {
					if (!barcode) return;
					modalItems
						?.scanBarcode(`${barcode}`)
						.then(closeBarcodeModal)
						.catch((err) => setBarcodeError(err));
				}}
				hasLines={false}
				centered
				width={416}
				closable={false}
				destroyOnClose
			>
				<h3 className={styles.modal__title}>Введите штрихкод товара</h3>
				<InputNumber className={styles.input} value={barcode} setValue={handleBarcodeChange} />
				{barcodeError ? <p className={styles.error}>{barcodeError}</p> : null}
			</Modal>
		</>
	);
};

export default observer(Header);
