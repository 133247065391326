import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import Item from './components/item';
import styles from './index.module.scss';

const CompensationsList: FC = () => {
	const { compensations } = useStore();
	const items = compensations.tableItems;

	if (!items) return null;

	return (
		<ul className={styles.table}>
			{items.map(({ id, number, fio, price }) => (
				<Item key={id} id={id} number={number} fio={fio} price={price} />
			))}
		</ul>
	);
};

export default observer(CompensationsList);
