import { api } from '@http/index';
import { Report, ReportFull } from '@store/kassa';
import { OrderItems } from '@store/visits/order-items';
import { AxiosResponse, GenericAbortSignal } from 'axios';

const getCashReport = (signal?: GenericAbortSignal): Promise<AxiosResponse<Report>> => {
	return api.get(`cash-report`, { signal });
};

const getCashReportId = (id: number): Promise<AxiosResponse<ReportFull>> => {
	return api.get(`cash-report/${id}`);
};

const postCashReport = (report: ReportFull): Promise<AxiosResponse<void>> => {
	return api.post(`cash-report/create`, report);
};

const getQr = (): Promise<AxiosResponse<any>> => {
	return api.get(`cash-register/qr-code`);
};

const sendPrintRepeat = (): Promise<AxiosResponse<any>> => {
	return api.post(`cash-register/receipt/print/repeat`);
};

const sendReceiptPostal = async (data: {
	postal_items: number[];
	cash_payment: number;
	cashless_payment: number;
}): Promise<AxiosResponse<any>> => {
	await delay(1000);
	return api.post(`cash-register/receipt/postal/send`, data);
};

const sendReceiptOrder = async (data: {
	orders: number[];
	cash_payment: number;
	cashless_payment: number;
	union: 0 | 1;
}): Promise<AxiosResponse<OrderItems>> => {
	await delay(1000);
	return api.post(`/cash-register/receipt/order/send`, data);
};

const delay = (ms: number): Promise<void> => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const KassaService = {
	getCashReport,
	getCashReportId,
	postCashReport,
	getQr,
	sendPrintRepeat,
	sendReceiptPostal,
	sendReceiptOrder,
};
