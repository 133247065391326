import InputNumber from '@components/inputNumber';
import Tabs from '@components/tabs';
import { useStore } from '@hooks/useStore';
import { Button, Checkbox } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

const AcceptanceHeader: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const path = location.pathname.split('/')[2];

	const {
		acceptance: { emall, routeSheet, headerInfo, loadInfoByDriver, loadSpaces },
	} = useStore();

	const [driverId, setDriverId] = useState<number | null>(null);

	const handleDriverKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
		if (evt.key === 'Enter') {
			driverId && loadInfoByDriver(driverId);
			setDriverId(null);
		}
	};

	return (
		<>
			<h4 className={styles.title}>Приемка</h4>
			<div className={styles.content}>
				<Tabs
					items={[
						{
							key: 'route-sheet',
							label: <BadgedTab name="Маршрутный лист" amount={headerInfo.count_routing_lists || 0} />,
						},
						// { key: 'postal', label: <span className={cn({ [styles.tab__new]: headerInfo.has_postal })}>Почта</span> },
						{ key: 'emall', label: <span className={cn({ [styles.tab__new]: headerInfo.has_emall })}>Emall</span> },
						// { key: 'money-bag', label: <span className={cn({ [styles.tab__new]: headerInfo.has_money })}>Денежный мешок</span> },
					]}
					activeKey={path}
					onChange={(key) => navigate(key)}
					className={styles.tabs}
				/>
				{path === 'emall' && (
					<Button type="primary" onClick={emall.loadItems}>
						Начать приёмку
					</Button>
				)}
			</div>
			{path === 'route-sheet' && (
				<div className={styles.actions}>
					<InputNumber
						className={styles.input}
						value={driverId}
						setValue={setDriverId}
						placeholder="Введите ID водителя"
						onKeyUp={handleDriverKeyUp}
					/>
					<Checkbox
						className={styles.checkbox}
						checked={!!routeSheet.showOld}
						onChange={(evt) => {
							routeSheet.setShowOld(evt.target.checked);
							routeSheet.loadList();
						}}
					>
						Показать старые
					</Checkbox>
					{routeSheet.hasEntered && (
						<Button
							type="primary"
							onClick={() => {
								routeSheet.setIsModalInfoCargoSpace(true);
								loadSpaces();
							}}
						>
							Начать приёмку
						</Button>
					)}
				</div>
			)}
		</>
	);
};

export default observer(AcceptanceHeader);

const BadgedTab: FC<{ name: string; amount: number }> = ({ name, amount }) => {
	return (
		<div className={styles.tab}>
			<span>{name}</span>
			<span className={styles.tab__badge}>{amount}</span>
		</div>
	);
};
