import colors from '@config/colors';
import { ConfigProvider, InputNumber as AntInputNumber, InputNumberProps as AntInputNumberProps } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

type InputNumberProps = Omit<
	AntInputNumberProps,
	'addonAfter' | 'addonBefore' | 'bordered' | 'prefix' | 'size' | 'controls'
> & {
	size?: 'large';
};

const InputNumber: FC<InputNumberProps> = ({ className, size = 'large', ...props }) => {
	return (
		<ConfigProvider theme={{ token: { controlHeightLG: 48, borderRadius: 2, colorText: colors.blue, fontSizeLG: 24 } }}>
			<AntInputNumber
				{...props}
				className={cn(className, styles.input, { [styles.input__large]: size === 'large' })}
				size={size}
				controls={false}
				parser={(value) => `${value}`.replace(',', '.')}
			/>
		</ConfigProvider>
	);
};

export default InputNumber;
