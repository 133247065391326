import TextSelection from '@components/text-selection';
import { FC } from 'react';

interface TextSelectionNumberProps {
	text: string;
	selection: string;
}

const TextSelectionNumber: FC<TextSelectionNumberProps> = ({ text, selection }) => {
	const value = text.toLocaleLowerCase();
	const highlight = selection.toLocaleLowerCase();

	if (value.includes(highlight)) {
		const position = value.indexOf(highlight);
		const length = highlight.length;

		return <TextSelection text={text} position={position} length={length} />;
	}

	return <span>{text}</span>;
};

export default TextSelectionNumber;
