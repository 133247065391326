/* eslint-disable @typescript-eslint/no-empty-interface */
import { ClientService } from '@services/client';
import { NotificationsService } from '@services/notifications';
import { flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '..';

const { maybeNull, number, string, model, optional, union, literal } = types;

const Client = model('Client', {
	id: number,
	name: maybeNull(string),
	patronymic_name: maybeNull(string),
	surname: maybeNull(string),
	is_verified: maybeNull(number),
	phone_number: string,
	address_name: maybeNull(string),
	vc_attempts_remaining: number,
});

const IndentificationModel = model('Indentification', {
	user: maybeNull(Client),
	isOpenModal: false,
	isOpenModalSuccess: false,
	isCheckedDocuments: false,
	step: optional(union(literal(0), literal(1)), 0),
	valueCode: '',
	searchValue: optional(string, ''),
	smsCodeError: '',
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		searchUser: flow(function* () {
			root.setLoading(true);
			self.user = null;
			const phone = self.searchValue;
			const number = phone.indexOf('+') !== -1 ? phone.trim().slice(1, phone.length) : phone;
			try {
				const { data } = yield ClientService.getClient(number);
				self.user = Client.create(data);
				self.searchValue = '';
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setIsOpenModal(value: boolean) {
			if (value === false) {
				self.isOpenModal = value;
				self.step = 0;
				self.isCheckedDocuments = false;
				self.valueCode = '';
			} else {
				self.isOpenModal = value;
			}
		},
		setIsOpenModalSuccess(value: boolean) {
			self.isOpenModalSuccess = value;
		},
		setValueCode(value: string) {
			self.smsCodeError = '';
			self.valueCode = value;
		},
		confirm: flow(function* () {
			//@ts-ignore
			if (self.step === 0) return self.setStep();
			if (!self.user || !self.valueCode) return;

			root.setLoading(true);
			try {
				yield NotificationsService.checkSms(self.user.id, self.valueCode);
				const { data } = yield ClientService.getClient(self.user.phone_number);
				self.user = data;
				//@ts-ignore
				self.setStep();
			} catch (err: any) {
				self.smsCodeError = err?.error || err?.message || 'Неверный код';
			} finally {
				root.setLoading(false);
			}
		}),
		setCheckedDoc() {
			self.isCheckedDocuments = !self.isCheckedDocuments;
		},
		setStep() {
			if (self.step === 1) {
				this.setIsOpenModal(false);
				self.isOpenModalSuccess = true;
			} else {
				if (self.isCheckedDocuments) {
					self.step = 1;
				}
			}
		},
		setSearchValue(value: string) {
			self.searchValue = value.replace(/[^0-9]/g, '');
		},
		sendCode: flow(function* () {
			if (!self.user) return;
			//@ts-ignore
			self.setValueCode('');
			root.setLoading(true);
			try {
				const { data } = yield NotificationsService.sendSmsCode(self.user.id);
				self.user.vc_attempts_remaining = data.attempts_remaining;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		sendClientCode: flow(function* () {
			if (!self.user) return;

			try {
				const {
					data: { message },
				} = yield NotificationsService.sendSmsClientCode(self.user.id);
				root.notice.setNotice({ message, type: 'success', hasButton: false });
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			}
		}),
	};
});

export default IndentificationModel;
export interface Client extends SnapshotIn<typeof Client> {}
