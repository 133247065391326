import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import IdentificationHeader from './components';
import ClientInfo from './components/client-info';
import styles from './index.module.scss';

const Identification: FC = () => {
	const store = useStore();
	return (
		<Layout>
			<IdentificationHeader />
			<Content className={styles.content}>{store.indentification.user ? <ClientInfo /> : <NoData />}</Content>
		</Layout>
	);
};

export default observer(Identification);
