import { INotice } from '@store/notice';
import { Button, message } from 'antd';

export const useMessage: (props?: { duration?: number }) => any = (props) => {
	const [messageApi, contextHolder] = message.useMessage({
		top: 64,
		duration: props?.duration || 7,
		prefixCls: 'messages',
	});

	const open = ({ message, hasButton, type }: INotice): ReturnType<typeof messageApi.open> =>
		messageApi.open({
			type,
			content: (
				<>
					<div>{message || ''}</div>
					{hasButton && (
						<Button type="primary" onClick={() => navigator.clipboard.writeText(message || '')}>
							Копировать
						</Button>
					)}
				</>
			),
			className: 'message',
		});

	return [open, contextHolder];
};
