import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import Modal from '@components/modal';
import colors from '@config/colors';
import { RefusalReasonCategory } from '@constants/index';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import { OrderItem } from '@store/visits/order-items';
import { ConfigProvider, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';

import RefusalReceivingModal from '../refusal-receiving-modal';
import ReasonSelector from './components/reason-selector';
import styles from './index.module.scss';

interface DataType {
	key: number;
	item: OrderItem;
}

const OrderCorrectionModal: FC = () => {
	const [confirm, setConfirm] = useState<'order' | 'correction' | null>(null);
	const visit = useCurrentVisit();
	const id = visit?.modalCorrectionId;
	const refusalReasons = visit?.refusalReasons;

	const reasonOptions = useMemo(() => {
		return refusalReasons?.reasons?.map(({ id, reason_text }) => ({
			label: reason_text,
			value: id,
		}));
	}, [refusalReasons?.reasons]);

	useEffect(() => {
		if (id) {
			visit?.loadOrderItems({ orderId: id }, 'getOrderItems');
			visit?.mountRefusalReasons(RefusalReasonCategory.Order, id);
		}
		return () => {
			visit?.unmountRefusalReasons();
		};
	}, [id, visit]);

	const handleSaveCorrectionClick = async (): Promise<void> => {
		try {
			if (id && refusalReasons) {
				await refusalReasons.postCorrectionReasons();
				visit?.setModalCorrectionId(null);
				await visit?.loadOrderItems({ orderId: id }, 'getOrderItems');
			}
		} catch {
			return;
		}
	};

	const columns: ColumnsType<DataType> = [
		{
			title: 'Наименование товара',
			className: cn(styles.cell, styles.cell__name),
			dataIndex: 'name',
			render: (_, { item }, i) => (
				<div className={styles.container}>
					<span>{item.name}</span>
					{item.received_quantity !== null &&
					visit?.initialReceivedQuantity[i] === item.quantity &&
					visit?.initialReceivedQuantity[i] > item.received_quantity ? (
						<ReasonSelector
							options={reasonOptions}
							onChange={(value) => {
								refusalReasons?.setCorrectionReason(item.id, Number(value));
							}}
						/>
					) : null}
					{item.reason_id && visit?.initialReceivedQuantity[i] !== item.quantity ? (
						<span>{reasonOptions?.find((el) => el.value === item.reason_id)?.label}</span>
					) : null}
				</div>
			),
		},
		{
			title: 'Кол-во старое',
			className: styles.cell,
			dataIndex: 'quantity',
			align: 'right',
			render: (_, { item: { quantity } }) => <span>{quantity}</span>,
		},
		{
			title: 'Цена',
			className: styles.cell,
			dataIndex: 'price',
			render: (_, { item: { price } }) => <span>{price}</span>,
		},
		{
			title: 'Кол-во новое',
			className: styles.cell,
			dataIndex: 'received_quantity',
			render: (_, { item: { received_quantity, quantity } }) => (
				<span>{received_quantity === 0 ? 0 : received_quantity || quantity}</span>
			),
		},
		{
			title: 'Сумма',
			className: styles.cell,
			dataIndex: 'final_price',
			render: (_, { item: { final_price } }) => <span>{final_price}</span>,
		},
		{
			title: 'Коррекция',
			className: cn(styles.cell),
			render: (_, { item: { received_quantity, id: itemId, quantity } }) => {
				const isZero = received_quantity === 0;
				const plusDisabled = isZero ? false : !received_quantity || received_quantity === quantity;

				return (
					<div className={styles.correction_wrapper}>
						<IconButton
							imageName="Minus"
							stroke={isZero ? colors.grey2 : colors.black}
							fontSize={20}
							onClick={() =>
								id &&
								visit?.loadOrderItems(
									{ orderId: id, itemId, count: +(received_quantity || quantity) - 1 },
									'changeOrderItem'
								)
							}
							disabled={isZero}
							btnStyle={{ padding: '2px 0' }}
						/>
						<IconButton
							imageName="Plus"
							stroke={plusDisabled ? colors.grey2 : colors.black}
							fontSize={20}
							onClick={() =>
								id &&
								visit?.loadOrderItems(
									{ orderId: id, itemId, count: received_quantity || isZero ? +received_quantity + 1 : +quantity + 1 },
									'changeOrderItem'
								)
							}
							disabled={plusDisabled}
							btnStyle={{ padding: '2px 0' }}
						/>
					</div>
				);
			},
		},
	];

	return (
		<Modal
			title={
				<>
					<Icon imageName={'ShoppingBag'} /> {visit?.getOrder(id as number)?.market_id}
				</>
			}
			footer={
				<footer className={styles.footer}>
					<Button
						onClick={() => {
							id && visit?.loadOrderItems({ orderId: id }, 'cancelCorrection');
							visit?.setModalCorrectionId(null);
						}}
					>
						Закрыть без изменений
					</Button>
					<Button onClick={refusalReasons?.openModal}>Отменить заказ</Button>
					<Button
						onClick={handleSaveCorrectionClick}
						type="primary"
						disabled={
							refusalReasons?.isInitialState ||
							refusalReasons?.hasRemoveOrAddItemCorrection ||
							visit?.orderItems?.allZero
						}
					>
						Сохранить изменения
					</Button>
				</footer>
			}
			open={!!id}
			width={1085}
			centered
			onCancel={() => setConfirm('correction')}
		>
			<ConfigProvider
				theme={{
					components: { Table: { borderRadius: 0 } },
				}}
			>
				<Table
					className={styles.table}
					columns={columns}
					dataSource={visit?.orderItems?.items.map((item) => ({ key: item.id, item })) || []}
					caption={<p className={styles.table__caption}>{visit?.orderItems?.receiver_fio || ''}</p>}
					footer={() => (
						<>
							{visit?.orderItems?.allZero && (
								<p className={styles.error}>
									Коррекция всех товаров в заказе невозможна. Проведите отмену заказа при отказе клиента от всех товаров
								</p>
							)}
							<p className={styles.total}>
								<span className={styles.total__name}>Сумма заказа</span>
								<span className={styles.total__sum}>{visit?.orderItems?.total_price}</span>
							</p>
							{(visit?.orderItems?.total_price_after_correction ||
								visit?.orderItems?.total_price_after_correction === 0) && (
								<p className={cn(styles.total, styles.total__correction)}>
									<span className={styles.total__name}>После коррекции</span>
									<span className={styles.total__sum}>{visit?.orderItems?.total_price_after_correction}</span>
								</p>
							)}
						</>
					)}
					pagination={false}
					onRow={({ item: { is_warranty_card } }) => ({
						className: cn(styles.row, { [styles.row__warranty]: is_warranty_card }),
					})}
					onHeaderRow={() => ({ className: styles.row__head })}
					rowSelection={{ selectedRowKeys: visit?.orderItems?.selectedKeys, columnWidth: 0 }}
					locale={{ emptyText: 'Нет данных' }}
				/>
				<Modal
					className={styles.confirm}
					title="Вы уверены, что хотите закрыть окно коррекции?"
					okText="Да, закрыть"
					cancelText="Отмена"
					open={!!confirm}
					onCancel={() => setConfirm(null)}
					onOk={() => {
						id && visit?.loadOrderItems({ orderId: id }, 'cancelCorrection');
						setConfirm(null);
						visit?.setModalCorrectionId(null);
					}}
					hasLines={false}
					centered
					width={416}
				>
					{confirm === 'correction' && 'Внесенные изменения не сохранятся'}
				</Modal>
			</ConfigProvider>
			<RefusalReceivingModal />
		</Modal>
	);
};

export default observer(OrderCorrectionModal);
