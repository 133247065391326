import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ConfirmModal: FC = () => {
	const {
		shipment: {
			modalInfo,
			routeSheet: { setDriverModal },
		},
	} = useStore();

	if (!modalInfo) return null;

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.modal__body}
			title={null}
			okText="Досканировать"
			cancelText="Частичная отгрузка"
			open={!!modalInfo.confirmOpened}
			onCancel={() => {
				setDriverModal('confirm');
				modalInfo.setConfirmOpened(false);
			}}
			onOk={() => modalInfo.setConfirmOpened(false)}
			hasLines={false}
			centered
			width={416}
			closable={false}
			maskClosable={false}
		>
			<h3 className={styles.modal__title}>Отсканированы не все места из списка</h3>
			<p className={styles.modal__description}>Досканируйте или подтвердите частичную отгрузку</p>
		</Modal>
	);
};

export default observer(ConfirmModal);
