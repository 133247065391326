import colors from '@config/colors';
import Icon from '@images/index';
import { ConfigProvider, Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import { FC, useState } from 'react';

import Service, { ServiceType } from './components/service';
import styles from './index.module.scss';

export type ItemsType = Omit<ServiceType, 'setItems' | 'confirmed'>;

type ModalProps = Omit<AntModalProps, 'title' | 'closeIcon' | 'okText' | 'cancelText' | 'width' | 'className'> & {
	items: ItemsType[];
	onConfirm?: (items: ItemsType[]) => void;
};

const ConfirmModal: FC<ModalProps> = ({ items, onConfirm, ...props }) => {
	const [serviceItems, setServiceItems] = useState(items.map((item) => ({ ...item, confirmed: false })));

	const setItems = (number: string, active: boolean): void => {
		setServiceItems((items) => {
			const serviceIndex = items.findIndex((item) => item.number === number);

			return items.map((item, i) => {
				if (i === serviceIndex) {
					return { ...item, active, confirmed: true };
				} else {
					return item;
				}
			});
		});
	};

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: colors.blue,
					colorPrimaryBorder: colors.blue,
					colorPrimaryHover: colors.blue2,
					colorPrimaryBorderHover: colors.blue2,
					colorPrimaryActive: colors.blue3,
					borderRadius: 2,
				},
			}}
		>
			<AntModal
				{...props}
				title={<h2 className={styles.title}>Подтвердите использование услуги</h2>}
				closeIcon={<Icon imageName="Close" fontSize={16} />}
				className={styles.modal}
				okText="Продолжить"
				cancelText="Отмена"
				width={480}
				okButtonProps={{ disabled: serviceItems.filter(({ confirmed }) => !confirmed).length > 0 }}
				onOk={() => onConfirm && onConfirm(serviceItems)}
				centered
			>
				<ul className={styles.list}>
					{serviceItems.map((item, i) => (
						<li key={i}>
							<Service {...item} setItems={setItems} />
						</li>
					))}
				</ul>
			</AntModal>
		</ConfigProvider>
	);
};

export default ConfirmModal;
