import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Report } from '@store/kassa';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC } from 'react';

import styles from './styles.module.scss';

const KassaTable: FC = () => {
	const store = useStore();
	const { kassa } = store;
	const columns: ColumnsType<Report> = [
		{
			dataIndex: 'operator_name',
			width: 340,
			render: (operator_name) => (
				<div style={{ display: 'flex', gap: 8 }}>
					<Icon imageName="ReceiptLong" />
					<div>
						<span>{operator_name}</span>
					</div>
				</div>
			),
		},
		{
			dataIndex: 'cash_register_number',
			width: 116,
			render: (cash_register_number) => (
				<>
					<div style={{ fontSize: 12, color: colors.grey1 }}>Номер кассы</div>
					<div>
						<span>{cash_register_number}</span>
					</div>
				</>
			),
		},
		{
			dataIndex: 'report_number',
			width: 116,
			render: (report_number) => (
				<>
					<div style={{ fontSize: 12, color: colors.grey1 }}>Номер отчета</div>
					<div>
						<span>{report_number}</span>
					</div>
				</>
			),
		},
		{
			dataIndex: 'date',
			render: (date) => (
				<>
					<div style={{ fontSize: 12, color: colors.grey1 }}>Дата</div>
					<div>
						<span>{date}</span>
					</div>
				</>
			),
		},
	];
	return (
		<Table
			columns={columns}
			showHeader={false}
			pagination={false}
			dataSource={kassa?.list || []}
			rowClassName={styles.row}
			onRow={({ id }) => ({
				onClick: () => kassa.getReportFull(id),
			})}
		/>
	);
};

export default KassaTable;
