/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model } = types;

const Message = model('Message', {
	title: '',
	description: '',
	html: '',
});

export default Message;

export interface IMessage extends SnapshotIn<typeof Message> {}
