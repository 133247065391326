import { getSnapshot, types } from 'mobx-state-tree';

import Visit from './visit';

const allNums = ['1', '2', '3', '4'];

const { model, map } = types;

const Visits = model('Visits', { visitMap: map(Visit), titleConfirmModal: '', closingVisitNum: '' })
	.actions((self) => ({
		addVisit() {
			const freeNums = allNums.filter((x) => !Array.from(self.visitMap.keys()).includes(x));
			self.visitMap.set(String(freeNums[0]), {});
			return freeNums[0];
		},
		closeVisit(visitNum: string) {
			if (self.visitMap.get(visitNum)?.postals.find(({ market_status: status }) => status === 10 || status === 15)) {
				self.closingVisitNum = visitNum;
				self.titleConfirmModal = 'Есть обработанные, но неоплаченные отправления. Закрыть визит?';
			} else {
				this.deleteVisit(visitNum);
			}
		},
		deleteVisit(visitNum: string) {
			const shiftedVisits = Array.from(self.visitMap.keys());
			shiftedVisits.forEach((item) => {
				const removing = self.visitMap.get(item);
				self.visitMap.delete(item);
				if (item !== visitNum) {
					self.visitMap.set(item, removing ? getSnapshot(removing) : {});
				}
			});
		},
		setConfirmModal(title: string) {
			self.titleConfirmModal = title;
			self.closingVisitNum = '';
		},
	}))
	.views((self) => ({
		get minVisitId() {
			return Math.min(...Array.from(self.visitMap.keys()).map((v) => Number(v)));
		},
	}));

export default Visits;
