import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { KassaService } from '@services/kassa';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { QRCodeSVG } from 'qrcode.react';
import { FC, useEffect, useRef, useState } from 'react';

import NoData from '../../../components/no-data';
import KassaTable from './components/table';
import styles from './index.module.scss';

const Kassa: FC = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [noShadow, setNoShadow] = useState(true);
	const [qr, setQr] = useState<string | null>(null);
	const store = useStore();
	const { kassa } = store;

	useEffect(() => {
		kassa.loadKassa();
		return () => kassa.unloadKassa();
	}, []);

	return (
		<>
			<Layout>
				<Header className={cn(styles.header, { [styles.header__no_shadow]: noShadow })}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div style={{ fontSize: 20, fontWeight: 500 }}>Касса</div>
						<Button
							disabled={!store.shift}
							className={styles.qr__button}
							icon={<Icon fontSize={16} imageName="QrIcon" />}
							onClick={() => {
								kassa.setOpenQrModal(true);
								store.setLoading(true);

								KassaService.getQr()
									.then(({ data }) => setQr(JSON.stringify(data)))
									.catch((err) => console.log('KassaService.getQr: ', err))
									.finally(() => store.setLoading(false));
							}}
						/>
						<Button
							className={styles.button}
							icon={<Icon imageName="Plus" fontSize={16} />}
							onClick={() => kassa.openReportModal()}
						>
							Добавить кассовый отчет
						</Button>
					</div>
				</Header>
				<Content>
					<OverlayScrollbarsComponent
						className={styles.scroll}
						options={{ scrollbars: { autoHide: 'move' } }}
						events={{
							scroll: () => {
								setNoShadow(!ref.current?.parentElement?.scrollTop);
							},
						}}
					>
						<div className={styles.scroll_wrap} ref={ref}>
							{kassa.list && kassa.list.length > 0 ? <KassaTable /> : <NoData />}
						</div>
					</OverlayScrollbarsComponent>
				</Content>
			</Layout>
			<Modal
				className={styles.qr__modal}
				title="QR-код для запуска программной кассы"
				open={kassa.isOpenQrModal}
				centered
				width={480}
				onCancel={() => kassa.setOpenQrModal(false)}
				okButtonProps={{ style: { display: 'none' } }}
				cancelText="Закрыть"
				destroyOnClose
			>
				<div>{qr && <QRCodeSVG value={qr} size={204} />}</div>
			</Modal>
		</>
	);
};

export default observer(Kassa);
