import NoData from '@components/no-data';
import Select from '@components/select';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { Button, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

type OrderItemsOptionType = {
	label: string;
	value: number;
};

const OrderItems: FC = () => {
	const [orderItemsOptions, setOrderItemsOptions] = useState<OrderItemsOptionType[] | []>([]);
	const visit = useCurrentVisit();
	const refusalReasons = visit?.refusalReasons;
	const reasons = refusalReasons?.reasons;
	const availableItemsToRefuse = refusalReasons?.availableItemsToRefuse;

	useEffect(() => {
		if (availableItemsToRefuse) {
			setOrderItemsOptions(
				availableItemsToRefuse.map(({ id, name }) => ({
					label: name,
					value: id,
				}))
			);
		}
	}, [availableItemsToRefuse]);

	return (
		<div className={styles.container}>
			{refusalReasons?.itemsToRefuse ? (
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<ul className={styles.list}>
						{refusalReasons.itemsToRefuse.map((item, index) => (
							<li key={item.id}>
								<span className={styles.number}>{index + 1}</span>
								<div className={styles.fields}>
									<Form.Item
										style={{ marginBottom: 0 }}
										name={`order-item-${item.id}`}
										rules={[{ required: true, message: <span className={styles.error}>Выберите товар</span> }]}
									>
										<Select
											placeholder="Выберите товар"
											options={orderItemsOptions}
											isActive={Boolean(item.orderItemId)}
											value={item.orderItemId}
											onChange={(value) => refusalReasons.selectItemToRefuseId(item.id, Number(value))}
										/>
									</Form.Item>
									<Form.Item
										style={{ marginBottom: 0 }}
										name={`reason-${item.id}`}
										rules={[{ required: true, message: <span className={styles.error}>Выберите причину</span> }]}
									>
										<Select
											placeholder="Выберите причину"
											options={reasons?.map(({ id, reason_text }) => ({
												label: `${id} - ${reason_text}`,
												value: id,
											}))}
											isActive={Boolean(item.reasonId)}
											value={item.reasonId}
											onChange={(value) => refusalReasons.selectItemToRefuseReason(item.id, Number(value))}
										/>
									</Form.Item>
								</div>
								<Button
									className={styles.button}
									onClick={() => {
										refusalReasons.removeItemToRefuse(item.id);
									}}
								>
									Удалить
								</Button>
							</li>
						))}
					</ul>
				</OverlayScrollbarsComponent>
			) : (
				<NoData />
			)}

			<Button onClick={refusalReasons?.addItemToRefuse} type="link" className={styles.add_button}>
				Добавить еще товар
			</Button>
		</div>
	);
};

export default observer(OrderItems);
