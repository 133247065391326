import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import TextSelectionName from '@components/text-selection-name';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import PackingServise from '@services/packing';
import { Postal } from '@store/visits/postal';
import { Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useState } from 'react';

import PackTags from '../../postal-modal/components/pack-tags';
import PackItem from './components/pack-item';
import styles from './index.module.scss';

interface PackSelectionProps {
	onBackClick?: () => void;
	postal?: Postal | null;
}

const PackSelection: FC<PackSelectionProps> = ({ onBackClick, postal }) => {
	const [packs, setPacks] = useState<Awaited<ReturnType<typeof PackingServise.getPacks>>['data']>([]);
	const [search, setSearch] = useState('');
	const hasSelected = postal?.packaging.length;
	const store = useStore();

	useEffect(() => {
		(async () => {
			try {
				store.setLoading(true);
				const packs = await PackingServise.getPacks();
				setPacks(packs.data);
			} finally {
				store.setLoading(false);
			}
		})();
	}, []);

	const filteredPacks = packs.filter(
		(pack) =>
			pack.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
			String(pack.legal_entity_price).startsWith(search) ||
			String(pack.individual_price).startsWith(search)
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden', paddingLeft: 24 }}>
			<div style={{ display: 'flex', height: 72, alignItems: 'center', gap: 17, marginRight: 24 }}>
				<IconButton imageName="Arrow" onClick={onBackClick} />
				<h4 style={{ flex: 1 }}>Упаковка</h4>
				<Input style={{ width: 240 }} value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Поиск" />
			</div>
			<OverlayScrollbarsComponent options={{ scrollbars: { autoHide: 'move' } }} className={styles.scroll}>
				<PackTags
					orientation="horizontal"
					addOption={false}
					options={postal?.packaging}
					onDelete={(id) => postal?.setPackagingQuantity(id, 0)}
					style={{ marginBottom: hasSelected ? 16 : 0 }}
				/>
				{(filteredPacks.length && (
					<div>
						{filteredPacks.map((pack) => {
							const quantity = Number(postal?.getPackaging(pack.id)?.quantity) || 0;
							const price = postal?.is_juristic ? pack.legal_entity_price : pack.individual_price;
							const step = pack.is_measured ? pack.default_value : 1;
							return (
								<PackItem
									key={pack.id}
									title={<TextSelectionName selection={search} text={pack.name} />}
									price={String(price)}
									quantity={quantity}
									onAdd={() =>
										quantity === 0
											? postal?.setPackaging({
													packing_id: pack.id,
													name: pack.name,
													quantity: quantity + 1,
													sum: price,
											  })
											: postal?.setPackagingQuantity(pack.id, quantity + step)
									}
									onRemove={() => postal?.setPackagingQuantity(pack.id, quantity - step)}
									image={pack.image}
								/>
							);
						})}
					</div>
				)) || (
					<div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<span style={{ color: colors.grey1 }}>Ничего не найдено</span>
					</div>
				)}
			</OverlayScrollbarsComponent>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					background: colors.grey5,
					padding: hasSelected ? '16px 24px' : 0,
					margin: hasSelected ? '16px 24px 24px 0' : '0 24px 0 0',
					maxHeight: hasSelected ? 65 : 0,
					overflow: 'hidden',
					transition: 'height 0.3s, padding 0.3s, margin 0.3s',
				}}
			>
				<div style={{ flex: 1, display: 'flex', gap: 16, alignItems: 'center' }}>
					<span style={{ color: colors.grey1 }}>Стоимость упаковки</span>
					<h3>{postal?.packagingSum}</h3>
				</div>
				<Button
					type="primary"
					onClick={() => {
						onBackClick && onBackClick();
						postal?.setCalculate();
					}}
				>
					Добавить
				</Button>
			</div>
		</div>
	);
};

export default observer(PackSelection);
