import { ThemeConfig } from 'antd';

import colors from './colors';

const antTheme: ThemeConfig = {
	token: {
		fontFamily: 'Roboto, sans-serif',
		fontSize: 15,
		fontSizeHeading1: 38,
		fontSizeHeading2: 30,
		fontSizeHeading3: 24,
		fontSizeHeading4: 20,
		fontWeightStrong: 500,
		colorText: colors.black,
		colorTextHeading: colors.black,
		borderRadius: 2,
	},
	components: {
		Select: {
			fontSizeIcon: 10,
			controlItemBgHover: colors.blue5,
			controlItemBgActive: colors.grey4,
		},
		Input: {
			colorBgContainerDisabled: colors.white,
		},
		Steps: {
			fontSizeLG: 15,
		},
		Modal: {
			colorBgMask: 'rgba(0, 0, 0, 0.2)',
			boxShadow:
				'0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
		},
		Table: {
			controlItemBgHover: '#fff',
		},
	},
};

export default antTheme;
