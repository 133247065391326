import { api } from '@http/index';
import { Payment } from '@store/visits/payment';
import { AxiosResponse, GenericAbortSignal } from 'axios';

const getPayment = (
	orders: number[],
	postal_items: number[],
	signal?: GenericAbortSignal
): Promise<AxiosResponse<Payment>> => api.post('payment', { orders, postal_items }, { signal });

const PaymentService = { getPayment };

export default PaymentService;
