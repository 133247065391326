import TextSelection from '@components/text-selection';
import { FC } from 'react';
import { formatPhoneNumber } from 'utils/formatPhone';

interface TextSelectionPhoneProps {
	text: string;
	selection: string;
}

const TextSelectionPhone: FC<TextSelectionPhoneProps> = ({ text, selection }) => {
	const value = text.toLocaleLowerCase();
	const highlight = selection.toLocaleLowerCase();

	if (value.includes(highlight)) {
		const position = value.indexOf(highlight);
		const length = highlight.length;
		let newPosition = position + 2;
		let newLength = length;

		const formatPhone = formatPhoneNumber(text);

		switch (position) {
			case 0:
			case 1:
			case 2:
				if (length > 3 - position) newLength += 1;
				if (length > 5 - position) newLength += 1;
				if (length > 8 - position) newLength += 1;
				if (length > 10 - position) newLength += 1;
				break;
			case 3:
			case 4:
				newPosition++;
				if (length > 5 - position) newLength += 1;
				if (length > 8 - position) newLength += 1;
				if (length > 10 - position) newLength += 1;
				break;
			case 5:
			case 6:
			case 7:
				newPosition += 2;
				if (length > 8 - position) newLength += 1;
				if (length > 10 - position) newLength += 1;
				break;
			case 8:
			case 9:
				newPosition += 3;
				if (length > 10 - position) newLength += 1;
				break;
			default:
				newPosition += 4;
		}

		return <TextSelection text={formatPhone} position={newPosition} length={newLength} />;
	}

	return <span>{formatPhoneNumber(text)}</span>;
};

export default TextSelectionPhone;
