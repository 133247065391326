import { AxiosError } from 'axios';
import { FC } from 'react';
import { useRouteError } from 'react-router-dom';

export const ErrorBoundary: FC = () => {
	const error = useRouteError() as AxiosError;
	return (
		<>
			<div>Ошибка загрузки данных</div>
			<div>{error.status}</div>
			<div>{error.message}</div>
		</>
	);
};
