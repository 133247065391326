/* eslint-disable @typescript-eslint/no-empty-interface */
import { ShipmentService } from '@services/shipment';
import RoutingHeader from '@store/routing-header';
import RoutingInfo, { PutSpaceDataType } from '@store/routing-info';
import { flow, getRoot, types } from 'mobx-state-tree';

import { RootStore } from '..';
import Emall from './emall';
import RouteSheet from './route-sheet';

const { model, maybeNull } = types;

export const ShipmentModel = model('Shipment', {
	headerInfo: RoutingHeader,
	routeSheet: RouteSheet,
	emall: Emall,
	modalInfo: maybeNull(RoutingInfo),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);
	let modalInfoTimerId: any;

	return {
		setSpace: flow(function* (id: number, data: PutSpaceDataType) {
			root.setLoading(true);
			try {
				yield ShipmentService.putSpaces(id, data);
				self.modalInfo?.id && root.shipment.loadRoutingInfo(self.modalInfo.id);
			} catch (err: any) {
				root.setMessage({ title: err?.message || '', description: '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadHeaderInfo: flow(function* () {
			try {
				const res = yield ShipmentService.getInfo();
				self.headerInfo = res.data;
			} catch (err: any) {
				console.log('loadHeaderInfo: ', err?.error || err?.message || '');
			}
		}),
		loadRoutingInfo: flow(function* (id: number) {
			root.setLoading(true);
			try {
				const res = yield ShipmentService.getList(id);
				self.modalInfo = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalInfo() {
			clearTimeout(modalInfoTimerId);

			modalInfoTimerId = setTimeout(() => {
				root.shipment.clearModalInfo();
				self.routeSheet.loadList();
			}, 300);
		},
		confirmModalInfo: flow(function* (id: number, driver: number) {
			root.setLoading(true);
			try {
				yield ShipmentService.confirmList(id, { driver_market_id: driver });
				self.routeSheet.driverLabel && root.print.printTask({ type: 'routing-lists', id });
				root.shipment.closeModalInfo();
				self.routeSheet.setDriverModal('');
				self.routeSheet.loadList();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		clearModalInfo() {
			self.modalInfo = null;
		},
	};
});
