import { api } from '@http/index';
import { IItem, IItemsModal } from '@store/acceptance/emall';
import { Sheet } from '@store/acceptance/route-sheet';
import { IRoutingInfo, PutSpaceDataType } from '@store/routing-info';
import { IRoutingInfoByDriver } from '@store/routing-info-by-driver';
import { AxiosResponse } from 'axios';

const getLists = (showOld: 0 | 1): Promise<AxiosResponse<Sheet[]>> =>
	api.get(`/routing/acceptance/lists?show_old=${showOld}`);

const getList = (id: number): Promise<AxiosResponse<Sheet[]>> => api.get(`/routing/acceptance/lists/${id}`);

const getSpaces = (): Promise<AxiosResponse<IRoutingInfo>> => api.get(`/routing/acceptance/spaces`);

const getDriverLists = (id: number): Promise<AxiosResponse<IRoutingInfoByDriver>> =>
	api.get(`/routing/acceptance/driver/${id}/lists`);

const getInfo = (): Promise<AxiosResponse<IRoutingInfo>> => api.get(`/routing/acceptance/info`);

const putSpaces = (id: number, data: PutSpaceDataType): Promise<AxiosResponse<void>> =>
	api.put(`/routing/acceptance/spaces/${id}`, data);

const postConfirm = (id: number): Promise<AxiosResponse<void>> =>
	api.post(`/routing/acceptance/confirm`, {
		driver_market_id: id,
	});

const postListsClose = (data: { routing_list_id: number[] }): Promise<AxiosResponse<void>> =>
	api.post(`/routing/acceptance/lists/close`, data);

const getEmallItems = (): Promise<AxiosResponse<IItemsModal>> => api.get(`/routing/acceptance/emall/items`);

const getEmallOrders = (): Promise<AxiosResponse<IItemsModal>> => api.get(`/routing/acceptance/emall/orders`);

const getEmallItemName = (name: string): Promise<AxiosResponse<IItem>> =>
	api.get(`/routing/acceptance/emall/items?name=${name}`);

const getSpace = (number: string): Promise<AxiosResponse<Sheet>> =>
	api.get(`/routing/acceptance/spaces?number=${number}`);

export const AcceptanceService = {
	getLists,
	getInfo,
	getList,
	getDriverLists,
	getSpaces,
	putSpaces,
	postConfirm,
	postListsClose,
	getEmallItems,
	getEmallItemName,
	getEmallOrders,
	getSpace,
};
