const colors = {
	black: '#252525',
	header: '#001529',
	red: '#FF4D4F',
	dustRed1: '#FFF1F0',
	dustRed2: '#FFCCC7',
	blue: '#1890FF',
	blue2: '#40A9FF',
	blue3: '#096DD9',
	blue4: '#40A9FF',
	blue5: '#E6F7FF',
	green: '#52C41A',
	grey1: '#6B6B6B',
	grey2: '#A3A3A3',
	grey3: '#D9D9D9',
	grey4: '#F2F2F2',
	grey5: '#F8F8F8',
	background: '#F0F2F5',
	white: '#FFFFFF',
};

export default colors;

export type Color = keyof typeof colors;
