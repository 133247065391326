import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const AddGoodsModal: FC = () => {
	const {
		shipment: {
			emall: { consolidation },
		},
	} = useStore();

	const closeModal = (): void => {
		consolidation.showModalBarcodeGoods(false);
		consolidation.modalCreateSpace.setBarcode(null);
	};

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			title={null}
			okText="Добавить"
			cancelText="Отмена"
			open={consolidation.modalBarcodeGoods}
			onCancel={closeModal}
			onOk={() => {
				if (!consolidation.modalCreateSpace.barcode) return;
				consolidation.modalCreateSpace.addGoods(consolidation.modalCreateSpace.barcode);
				closeModal();
			}}
			okButtonProps={{ disabled: !consolidation.modalCreateSpace.barcode }}
			hasLines={false}
			centered
			width={400}
			closable={false}
			destroyOnClose
		>
			<h3 className={styles.title}>Введите штрихкод товара</h3>
			<InputNumber
				className={styles.input}
				value={consolidation.modalCreateSpace.barcode}
				setValue={consolidation.modalCreateSpace.setBarcode}
			/>
		</Modal>
	);
};

export default observer(AddGoodsModal);
