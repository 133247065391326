import Select from '@components/select';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

const PrinterSelect: FC<{
	value: string | null;
	onChange: (value: string) => void;
	status?: '' | 'error';
}> = ({ value, status, onChange }) => {
	const store = useStore();
	return (
		<Select
			options={store.print.availablePrinters.map((value) => ({ label: value, value }))}
			placeholder="Выберите принтер"
			onChange={(value) => onChange(String(value))}
			value={value}
			status={status}
		/>
	);
};

export default observer(PrinterSelect);
