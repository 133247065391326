import Button from '@components/buttons/button';
import DatePicker from '@components/datePicker';
import Input from '@components/input';
import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { addZero } from 'utils/addZero';

import CanceledCheck from './components/canceled';
import OtherPayments from './components/other-payments';
import PostAndOrders from './components/post-and-orders';
import styles from './index.module.scss';

const exclusionStores = [
	{ exclusion: '70130410', store: 70140410 },
	{ exclusion: '70130420', store: 70140420 },
	{ exclusion: '70130110', store: 70140110 },
	{ exclusion: '70131060', store: 70141060 },
	{ exclusion: '70132050', store: 70142050 },
	{ exclusion: '70132410', store: 70142410 },
];

const fixStore = (id: string | null): number | string => {
	const exclusionStore = exclusionStores.find(({ exclusion }) => exclusion === id);
	return exclusionStore ? exclusionStore.store : id || '';
};

const AddCashboxReportModal: FC = () => {
	const { kassa, operator, print } = useStore();

	const handleCancelClick = (): void => {
		kassa.closeReportModal();
		operator?.is_pos_enabled && print.printReports();
	};

	const handleReportClick = async (): Promise<void> => {
		try {
			await kassa.postReport();
			!operator?.is_pos_enabled && (await kassa.loadKassa());
			!kassa.reportModal && kassa.closeReportModal();
			operator?.is_pos_enabled && print.printReports();
		} catch {
			return;
		}
	};

	return (
		<Modal
			title="Кассовый отчет"
			destroyOnClose
			open={kassa.reportModal}
			onCancel={kassa.closeReportModal}
			closable={kassa.reportFull?.readonly}
			centered
			maskClosable={false}
			width={680}
			footer={
				kassa.reportFull?.readonly ? null : (
					<div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 6 }}>
						<Button onClick={handleCancelClick}>Отмена</Button>
						<Button type="primary" onClick={handleReportClick}>
							Отправить
						</Button>
					</div>
				)
			}
		>
			<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap', alignItems: 'flex-start' }}>
				<Input
					placeholder="Склад"
					style={{ width: 200 }}
					value={kassa.reportFull?.store_id || fixStore(localStorage.getItem('postOffice'))}
					disabled
				/>
				<InputNumber
					placeholder="Номер кассы"
					required
					style={{ width: 200 }}
					value={kassa.reportFull?.cash_register_number}
					setValue={kassa.reportFull?.setCashRegisterNumber}
					disabled={kassa.reportFull?.readonly}
					errorMessage={kassa.reportFull.isCashRegisterNumberError ? kassa.requiredFieldError : null}
				/>
				<DatePicker
					value={kassa.reportFull?.date ? dayjs(kassa.reportFull?.date, 'DD.MM.YYYY') : null}
					format={['DD.MM.YYYY']}
					style={{ width: 200 }}
					onChange={(date) => {
						date
							? kassa.reportFull?.setDate(
									`${addZero(date.date())}.${addZero(date.month() + 1)}.${addZero(date.year())}`
							  )
							: null;
					}}
					disabled={kassa.reportFull?.readonly}
					placeholder="Дата Z-отчета"
					required
					errorMessage={kassa.reportFull.isDateError ? kassa.requiredFieldError : null}
				/>
				<InputNumber
					placeholder="Номер Z-отчета"
					required
					style={{ width: 200 }}
					value={kassa.reportFull?.report_number}
					setValue={kassa.reportFull?.setReportNumber}
					disabled={kassa.reportFull?.readonly}
					errorMessage={kassa.reportFull.isReportNumberError ? kassa.requiredFieldError : null}
				/>
				<InputNumber
					placeholder="Сменный итог Z-отчета"
					required
					style={{ width: 200 }}
					value={kassa.reportFull?.total_sum}
					setValue={kassa.reportFull?.setTotalSum}
					disabled={kassa.reportFull?.readonly}
					errorMessage={kassa.reportFull.isTotalSumError ? kassa.requiredFieldError : null}
				/>
				<InputNumber
					placeholder="Внесено в кассу"
					required
					style={{ width: 200 }}
					value={kassa.reportFull?.cash_register_added}
					setValue={kassa.reportFull?.setCashRegisterAdded}
					disabled={kassa.reportFull?.readonly}
					errorMessage={kassa.reportFull.isCashRegisterAddedError ? kassa.requiredFieldError : null}
				/>
			</div>
			<div>
				<Tabs
					items={[
						{ key: '1', label: 'Почта/Заказы', children: <PostAndOrders /> },
						{ key: '2', label: 'Аннулированные чеки', children: <CanceledCheck /> },
						{ key: '3', label: 'Прочие выплаты', children: <OtherPayments /> },
					]}
					className={styles.tabs}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 16, paddingTop: 24 }}>
				<InputNumber
					style={{ width: '100%' }}
					placeholder="Сумма инкассации экспедитору"
					value={kassa.reportFull?.collection}
					setValue={kassa.reportFull?.setCollection}
					disabled={kassa.reportFull?.readonly}
				/>
				<Input
					placeholder="Комментарий"
					value={kassa.reportFull?.info}
					onChange={(evt) => kassa.reportFull?.setInfo(evt.target.value)}
					disabled={kassa.reportFull?.readonly}
				/>
				<InputNumber
					style={{ width: '100%' }}
					value={kassa.reportFull?.ballance}
					placeholder="Остаток на конец дня"
					disabled
				/>
			</div>
		</Modal>
	);
};

export default observer(AddCashboxReportModal);
