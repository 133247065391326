import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import styles from './index.module.scss';

const ModalSuccess: FC = () => {
	const { indentification } = useStore();

	useEffect(() => {
		const timer = setTimeout(() => indentification.setIsOpenModalSuccess(false), 2000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Modal
			className={styles.modal}
			title={null}
			cancelText="Закрыть"
			open={indentification.isOpenModalSuccess}
			onCancel={() => indentification.setIsOpenModalSuccess(false)}
			centered
			width={416}
			hasLines={false}
			footer={
				<Button onClick={() => indentification.setIsOpenModalSuccess(false)} className={styles.button}>
					Закрыть
				</Button>
			}
		>
			<div className={styles.icon}>
				<Icon imageName="CheckCircle" fontSize={72} color="#00C1A2" />
			</div>
			<p className={styles.text}>
				Клиент
				{indentification.user
					? ` ${indentification.user.surname || ''} ${indentification.user.name || ''} ${
							indentification.user.patronymic_name || ''
					  } `
					: ' '}
				успешно идентифицирован
			</p>
		</Modal>
	);
};

export default observer(ModalSuccess);
