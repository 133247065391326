import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Packaging } from '@store/visits/postal';
import { Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import PackTag from './components/pack-tag';
import styles from './index.module.scss';

interface PacksProps {
	orientation?: 'horizontal' | 'vertical';
	addOption?: boolean;
	onAddClick?: () => void;
	options?: Packaging[];
	onDelete?: (id: number) => void;
	style?: React.CSSProperties;
}

const PackTags: FC<PacksProps> = ({
	orientation = 'vertical',
	addOption = true,
	onAddClick,
	options = [],
	onDelete,
	style,
}) => {
	const ref = React.createRef<HTMLDivElement>();
	const [height, setHeight] = useState<number>();
	const store = useStore();

	useEffect(() => setHeight(ref.current?.clientHeight), [options.length]);

	return (
		<div style={style}>
			<div style={{ height: height, transition: 'height 0.3s', overflow: 'hidden' }}>
				<div
					ref={ref}
					style={{
						display: 'flex',
						flexDirection: orientation === 'vertical' ? 'column' : 'row',
						flexWrap: 'wrap',
						gap: 8,
					}}
				>
					{options.map((item) => (
						<PackTag key={item.packing_id} packaging={item} onClose={() => onDelete && onDelete(item.packing_id)} />
					))}
				</div>
			</div>
			{addOption && (
				<Typography.Link className={styles.link} onClick={onAddClick} disabled={store.loading}>
					<Icon imageName="Plus" fontSize={16} stroke={store.loading ? colors.grey1 : colors.blue} /> Добавить упаковку
				</Typography.Link>
			)}
		</div>
	);
};

export default PackTags;
