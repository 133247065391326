/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, boolean, maybeNull, optional } = types;

const Shift = model('Shift', {
	shift_id: number,
	status: number,
	break: maybeNull(model('Break', { id: number, opened_at: '', type: 1 | 2, duration: maybeNull(number) })),
	is_closed_by_kassa: optional(boolean, false),
}).actions((self) => ({
	setIsClosedByKassa(value: boolean) {
		self.is_closed_by_kassa = value;
	},
}));

export default Shift;

export interface Shift extends SnapshotIn<typeof Shift> {}
