/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, types } from 'mobx-state-tree';

const { model, number, string, maybeNull, optional } = types;

const Payment = model('Payment', {
	order: model({
		payment_sum: number,
		certificate_sum: number,
		final_sum: number,
	}),
	postal: model({
		payment_sum: number,
		issue_sum: number,
		final_sum: number,
	}),
	0: maybeNull(number),
	error: maybeNull(
		model({
			error: optional(string, ''),
		})
	),
});

export default Payment;

export interface Payment extends Instance<typeof Payment> {}
