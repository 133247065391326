import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

const Footer: FC = () => {
	const visit = useCurrentVisit();

	return (
		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<Button onClick={visit?.cancelOrder}>Отмена</Button>
			<Button
				type="primary"
				onClick={visit?.createOrderModel?.onNextStep}
				disabled={!visit?.createOrderModel?.isFieldsFill}
			>
				{visit?.createOrderModel?.step === 0 ? 'К оформлению' : 'К приемке'}
			</Button>
		</div>
	);
};

export default observer(Footer);
