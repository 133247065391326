/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import RoutingHeader from '@store/routing-header';
import RoutingInfo, { PutSpaceDataType } from '@store/routing-info';
import RoutingInfoByDriver from '@store/routing-info-by-driver';
import { flow, getRoot, types } from 'mobx-state-tree';

import { RootStore } from '..';
import Emall from './emall';
import RouteSheet from './route-sheet';

const { model, maybeNull, number } = types;

export const AcceptancetModel = model('Acceptance', {
	headerInfo: RoutingHeader,
	routeSheet: RouteSheet,
	emall: Emall,
	modalInfo: maybeNull(RoutingInfo),
	modalInfoByDriver: maybeNull(RoutingInfoByDriver),
	driverId: maybeNull(number),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		setSpace: flow(function* (id: number, data: PutSpaceDataType) {
			root.setLoading(true);
			try {
				yield AcceptanceService.putSpaces(id, data);

				if (self.modalInfo?.id) {
					root.acceptance.loadRoutingInfo(self.modalInfo.id);
					return;
				}

				root.acceptance.routeSheet.isModalInfoCargoSpace && root.acceptance.loadSpaces();
			} catch (err: any) {
				root.setMessage({ title: err?.message || '', description: '' });
			} finally {
				root.setLoading(false);
			}
		}),
		addSpace: flow(function* (number: string) {
			if (!self.modalInfo?.routing_spaces) {
				root.notice.setNotice({ message: 'Нет пакетов' });
				return;
			}

			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getSpace(number);
				self.modalInfo.routing_spaces.unshift({ ...res.data, [res.data.is_surplus ? 'isError' : 'isSurplus']: true });
			} catch (err: any) {
				root.setMessage({ title: err?.message || '', description: '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadHeaderInfo: flow(function* () {
			try {
				const res = yield AcceptanceService.getInfo();
				self.headerInfo = res.data;
			} catch (err: any) {
				console.log('loadHeaderInfo: ', err?.error || err?.message || '');
			}
		}),
		loadRoutingInfo: flow(function* (id: number) {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getList(id);
				self.modalInfo = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadSpaces: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getSpaces();
				const addItems = self.modalInfo?.routing_spaces.filter(({ isSurplus, isError }) => isSurplus || isError) || [];
				self.modalInfo = res.data ? { ...res.data, routing_spaces: [...addItems, ...res.data.routing_spaces] } : null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadInfoByDriver: flow(function* (id: number) {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getDriverLists(id);
				self.modalInfoByDriver = res.data || null;
				self.driverId = id;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalInfo() {
			self.modalInfo = null;
			self.routeSheet.setIsModalInfoView(false);
			self.routeSheet.setIsModalInfoCargoSpace(false);
		},
		closeModalInfoByDriver() {
			self.modalInfoByDriver = null;
			self.driverId = null;
		},
		confirm: flow(function* () {
			if (!self.driverId) return;

			root.setLoading(true);

			try {
				yield AcceptanceService.postConfirm(self.driverId);
				root.acceptance.routeSheet.loadList();
				root.acceptance.closeModalInfoByDriver();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalInfoLists: flow(function* () {
			if (!self.modalInfo?.routing_list_id) return;
			root.setLoading(true);

			try {
				yield AcceptanceService.postListsClose({ routing_list_id: self.modalInfo.routing_list_id });
				root.acceptance.routeSheet.loadList();
				root.acceptance.closeModalInfo();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		confirmModalInfo() {
			if (!self.modalInfo?.routing_spaces) return;

			if (self.modalInfo.routing_spaces.find(({ is_scanned }) => !is_scanned)) {
				self.modalInfo.setConfirmOpened(true);
			} else {
				root.acceptance.closeModalInfoLists();
			}
		},
	};
});
