import { types } from 'mobx-state-tree';

import ConsolidationModel from './consolidation';
import GoodsModel from './goods';

const { model, union, literal, optional } = types;

const Emall = model('emall', {
	tab: optional(union(literal('consolidation'), literal('goods')), 'consolidation'),
	goods: GoodsModel,
	consolidation: ConsolidationModel,
}).actions((self) => {
	return {
		setTab(tab: 'consolidation' | 'goods') {
			self.tab = tab;
		},
	};
});

export default Emall;
