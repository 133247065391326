import IconButton from '@components/buttons/icon_btn';
import NoData from '@components/no-data';
import Icon, { ImageName } from '@images/index';
import { PutSpaceDataType } from '@store/routing-info';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC } from 'react';

import styles from './index.module.scss';

const types = [
	{ MailGray: 'Мешок' },
	{ MailGray: 'Посылка' },
	{ ShoppingBagBlack: 'Ящик' },
	{ ShoppingBagBlack: 'Товар' },
	{ PaymentsBlack: 'Денежный мешок' },
];

interface ISpace {
	spaces: {
		id: number;
		type: number;
		is_scanned: 0 | 1;
		number: string;
		routing_items_count: number;
		type_name?: string | null;
		status?: number | null;
		isSurplus?: boolean;
		isError?: boolean;
	}[];
	isEdit?: boolean;
	onDelete?: (number: string, data: PutSpaceDataType, opt?: { hasSurplus?: boolean; isDelete?: boolean }) => void;
}

const RoutingSpaces: FC<ISpace> = ({ spaces, isEdit, onDelete }) => {
	if (!(spaces.length > 0)) return <NoData text="Нет данных" />;

	return (
		<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
			<table className={cn(styles.table, { [styles.table__inner]: isEdit })}>
				{spaces.map(({ id, number, type, routing_items_count: count, is_scanned: isScanned, isError, isSurplus }) => (
					<>
						<tr
							key={id}
							className={cn(styles.row, {
								[styles.row__scan]: isScanned,
								[styles.row__new]: isSurplus,
								[styles.row__error]: isError,
							})}
						>
							<td className={styles.cell} style={{ width: 'calc(158px + 16px)' }}>
								<div className={styles.cell__wrap}>
									<Icon imageName={Object.keys(types[type - 1])[0] as ImageName} />
									<b>{Object.values(types[type - 1])[0]}</b>
								</div>
							</td>
							<td className={styles.cell} style={{ width: 'calc(120px + 32px)' }}>
								<span>{number}</span>
							</td>
							<td className={styles.cell}>
								{type === 1 || type === 3 ? (
									<span>
										{type === 1 ? 'Посылок' : 'Товаров'}: {count}
									</span>
								) : null}
							</td>
							{isEdit && spaces.length > 1 && (
								<td className={styles.cell} style={{ width: 'calc(28px + 16px)' }}>
									<IconButton
										imageName="Close"
										onClick={() =>
											onDelete &&
											onDelete(number, isScanned ? { is_scanned: 0 } : { routing_list_id: null }, { isDelete: true })
										}
									/>
								</td>
							)}
						</tr>
						{isError && (
							<tr className={cn(styles.row, styles.row__expand, styles.row__error)}>
								<td colSpan={4}>Отсканированное грузоместо поступило ошибочно. Передайте на склад</td>
							</tr>
						)}
					</>
				))}
			</table>
		</OverlayScrollbarsComponent>
	);
};

export default observer(RoutingSpaces);
