import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { ConfigProvider, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const SearchInVisit: FC = () => {
	const [value, setValue] = useState('');
	const [error, setError] = useState<string | null>(null);
	const visit = useCurrentVisit();

	const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		const value = evt.target.value;

		setError('');
		setValue(value);

		if (value.length > 3) {
			!visit?.highlightPostal(value) && setError('Ничего не найдено');
		}
	};

	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					boxShadowSecondary:
						'0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
				},
			}}
		>
			<div>
				<Input value={value} onChange={handleSearchChange} className={styles.search} placeholder="Поиск в визите" />
				{error && <p className={styles.error}>{error}</p>}
			</div>
		</ConfigProvider>
	);
};

export default observer(SearchInVisit);
