import Icon from 'images';
import React, { FC } from 'react';

export type IconButtonProps = Parameters<typeof Icon>[0] & {
	disabled?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	btnStyle?: React.CSSProperties;
};

const IconButton: FC<IconButtonProps> = ({ onClick, btnStyle, disabled, ...props }) => {
	return (
		<button
			onClick={onClick}
			style={{ cursor: 'pointer', border: 'none', background: 'none', padding: 0, ...btnStyle }}
			disabled={disabled}
		>
			<Icon {...props} disabled={disabled} />
		</button>
	);
};

export default IconButton;
