import { api } from '@http/index';
import { AxiosResponse } from 'axios';

const search = (
	query: string
): Promise<
	AxiosResponse<{
		cities: [
			{
				id: number;
				name_full: string;
			}
		];
	}>
> => api.get(`/address/search?search=${query}`);

const streets = (
	cityId: number
): Promise<
	AxiosResponse<{
		streets: [
			{
				id: number;
				market_id: number;
				name: string;
			}
		];
	}>
> => api.get(`/address/city/${cityId}/streets`);

const create = (data: {
	street_id: number;
	house_number: string;
	housing_name: string;
	flat_number: string;
}): Promise<AxiosResponse<{ flat_market_id: number }>> => api.post('/address/create', data);

export const AddressService = { search, streets, create };
