import { api } from '@http/index';
import { AxiosResponse } from 'axios';

interface DeliveryType {
	id: number;
	name: string;
}

const getDeliveryType = (): Promise<AxiosResponse<DeliveryType[]>> => {
	return api.get('delivery/types');
};

const DeliveryTypeService = { getDeliveryType };

export default DeliveryTypeService;
