import IconButton from '@components/buttons/icon_btn';
import colors from '@config/colors';
import { Packaging } from '@store/visits/postal';
import { FC, useEffect, useState } from 'react';

export interface PackTagProps {
	packaging: Packaging;
	onClose?: () => void;
}

const PackTag: FC<PackTagProps> = ({ packaging, onClose }) => {
	const [opacity, setOpacity] = useState(0);
	useEffect(() => {
		setOpacity(1);
		return () => setOpacity(0);
	}, []);
	return (
		<div style={{ opacity: opacity, transition: 'opacity 0.5s' }}>
			<div
				style={{
					display: 'inline-flex',
					padding: '5px 10px',
					background: colors.grey4,
					alignItems: 'center',
					gap: 10,
					borderRadius: 2,
				}}
			>
				<div>
					{packaging.name} <b>{packaging.sum}</b> x{packaging.quantity}
				</div>
				<IconButton imageName="Close" style={{ lineHeight: 1 }} color={colors.grey1} onClick={onClose} />
			</div>
		</div>
	);
};

export default PackTag;
