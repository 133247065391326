import { useEffect, useState } from 'react';

export const useWindowSize = (): { width: number; height: number } => {
	const { clientHeight, clientWidth } = document.documentElement;
	const [windowSize, setWindowSize] = useState({ width: clientWidth, height: clientHeight });

	const onResize = (): void =>
		setWindowSize({ width: document.documentElement.clientWidth, height: document.documentElement.clientHeight });

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);

	return windowSize;
};
