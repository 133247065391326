import Button from '@components/buttons/button';
import colors from '@config/colors';
import { Switch } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

export type ServiceType = {
	id: number;
	number: string;
	name: string;
	confirmed: boolean;
	active: boolean;
	setItems: (number: string, active: boolean) => void;
};

const Service: FC<ServiceType> = ({ number, name, confirmed, active, setItems }) => {
	return (
		<div className={styles.wrap}>
			<p className={styles.number}>{number}</p>
			<span className={styles.name}>{name}</span>
			<Button
				className={cn(styles.button, { [styles.button__yes]: active && confirmed })}
				onClick={() => setItems(number, true)}
				type="primary"
				customColorPrimaryHover={colors.grey3}
			>
				Да
			</Button>
			<Button
				className={cn(styles.button, { [styles.button__no]: !active && confirmed })}
				onClick={() => setItems(number, false)}
				type="primary"
				customColorPrimaryHover={colors.grey3}
			>
				Нет
			</Button>
			<Switch className={styles.switch} checked={active} />
		</div>
	);
};

export default Service;
