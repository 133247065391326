import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import Select from './components/select';
import styles from './index.module.scss';

const SelectPrinterModal: FC = () => {
	const store = useStore();

	const savePrinters = (): void => {
		store.print.savePrinters();
	};

	return (
		<Modal
			title="Выберите принтер для печати"
			okText="Сохранить"
			cancelText="Отмена"
			onOk={savePrinters}
			onCancel={store.print.closePrinterSelect}
			open={store.print.openPrinterSelect}
			okButtonProps={{ disabled: store.print.printersError }}
			centered
			width={540}
			destroyOnClose
		>
			<div>
				<div className={styles.item}>
					<div>Термоярлык</div>
					<Select
						value={store.print.termo}
						onChange={(value) => store.print.setTermo(value)}
						status={store.print.errorThermal ? 'error' : ''}
					/>
				</div>
				<div className={styles.item}>
					<div>Остальное</div>
					<Select
						value={store.print.others}
						onChange={(value) => store.print.setOthers(value)}
						status={store.print.errorOthers ? 'error' : ''}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default observer(SelectPrinterModal);
