import './App.scss';

import antTheme from '@config/antTheme';
import routes from '@config/routes';
import { ConfigProvider } from 'antd';
import ru from 'antd/locale/ru_RU';
import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import StoreProvider from 'store';

const router = createBrowserRouter(routes);

const App: FC = () => {
	return (
		<StoreProvider>
			<ConfigProvider theme={antTheme} locale={ru}>
				<RouterProvider router={router} />
			</ConfigProvider>
		</StoreProvider>
	);
};

export default App;
