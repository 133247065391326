import 'overlayscrollbars/overlayscrollbars.css';

import colors from '@config/colors';
import { ConfigProvider, Popover, PopoverProps } from 'antd';
import { FC } from 'react';

import Content from './components/content';
import styles from './index.module.scss';

type TooltipProps = Omit<PopoverProps, 'title' | 'content' | 'placement'> & { text: string; fullText?: string };

const Tooltip: FC<TooltipProps> = ({ children, text, fullText, ...props }) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					colorBgElevated: 'rgba(37, 37, 37, 0.85)',
					colorText: colors.white,
					boxShadowSecondary: '0px 2px 8px rgba(0, 0, 0, 0.15)',
					motionDurationMid: '.1s',
				},
			}}
		>
			<Popover
				{...props}
				rootClassName={styles.tooltip}
				content={<Content text={text} fullText={fullText} />}
				placement="rightTop"
				destroyTooltipOnHide
				trigger="click"
			>
				<div className={styles.wrap}>{children}</div>
			</Popover>
		</ConfigProvider>
	);
};

export default Tooltip;
