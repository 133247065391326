import Icon from '@images/index';
import { FC } from 'react';

import styles from './index.module.scss';

const NoData: FC<{ text?: string }> = ({ text = 'Пусто' }) => (
	<div className={styles.wrap}>
		<Icon imageName="NoTasks" fontSize={100} />
		<p className={styles.text}>{text}</p>
	</div>
);

export default NoData;
