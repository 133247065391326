import Badge from '@components/badge';
import TextSelectionName from '@components/text-selection-name';
import TextSelectionNumber from '@components/text-selection-number';
import TextSelectionPhone from '@components/text-selection-phone';
import Icon from '@images/index';
import { Tooltip } from 'antd';
import { FC } from 'react';
import { checkPostalIcon } from 'utils/checkPostalIcon';

import styles from './index.module.scss';

interface OptionProps {
	type: 0 | 1 | 2 | 3;
	number: string | number;
	senderPhone?: string;
	senderName?: string;
	receiverPhone: string;
	receiverName?: string;
	selectString: string;
	externalId?: string | null;
	agreementTypeId?: number;
	marketStatus?: number;
}

const Option: FC<OptionProps> = ({
	type,
	number,
	senderPhone,
	senderName,
	receiverPhone,
	receiverName,
	selectString,
	externalId,
	agreementTypeId,
	marketStatus,
}) => {
	return (
		<div className={styles.option}>
			<div className={styles.icon}>
				{((type === 1 || type === 2) && (
					<Icon imageName={type === 1 ? checkPostalIcon(marketStatus, agreementTypeId) : 'Payments'} />
				)) ||
					(type === 0 && (
						<Badge dot color="blue" offset={[-1, 3]}>
							<Icon imageName="Mail" />
						</Badge>
					)) ||
					(type === 3 && <Icon imageName="ShoppingBag" />)}
			</div>
			<div className={styles.number}>
				<div>
					<b>
						<TextSelectionNumber text={String(number)} selection={selectString} />
					</b>
				</div>
				{externalId && (
					<Tooltip title={externalId}>
						<span className={styles.id}>{externalId}</span>
					</Tooltip>
				)}
			</div>
			<div className={styles.phone}>
				<span>{!!senderPhone && <TextSelectionPhone text={senderPhone} selection={selectString} />}</span>
				<span>
					<TextSelectionPhone text={receiverPhone} selection={selectString} />
				</span>
			</div>
			<div className={styles.name}>
				<span>{!!senderName && <TextSelectionName text={senderName} selection={selectString} />}</span>
				<span>{!!receiverName && <TextSelectionName text={receiverName} selection={selectString} />}</span>
			</div>
		</div>
	);
};

export default Option;
