import { Header } from 'antd/es/layout/layout';
import { FC } from 'react';

import ClientSearch from './components/search';
import styles from './index.module.scss';

const IdentificationHeader: FC = () => {
	return (
		<Header className={styles.header}>
			<h1 className={styles.title}>Идентификация</h1>
			<ClientSearch />
		</Header>
	);
};

export default IdentificationHeader;
