import Button from '@components/buttons/button';
import RadioButton from '@components/radio-button';
import Tabs from '@components/tabs';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Checkbox, RadioChangeEvent } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

const ShipmentHeader: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const path = location.pathname.split('/')[2];
	const [isHover, setIsHover] = useState(false);

	const {
		shipment: { emall, routeSheet, headerInfo },
	} = useStore();

	const onRadioChange = (evt: RadioChangeEvent): void => {
		emall.setTab(evt.target.value);
	};

	return (
		<>
			<h4 className={styles.title}>Отгрузка</h4>
			<div className={styles.content}>
				<Tabs
					items={[
						{
							key: 'route-sheet',
							label: <BadgedTab name="Маршрутный лист" amount={headerInfo.count_routing_lists || 0} />,
						},
						// { key: 'postal', label: <span className={cn({ [styles.tab__new]: headerInfo.has_postal })}>Почта</span> },
						{ key: 'emall', label: <span className={cn({ [styles.tab__new]: headerInfo.has_emall })}>Emall</span> },
						// { key: 'money-bag', label: <span className={cn({ [styles.tab__new]: headerInfo.has_money })}>Денежный мешок</span> },
					]}
					activeKey={path}
					onChange={(key) => navigate(key)}
					className={styles.tabs}
				/>
				{path === 'route-sheet' && (
					<Checkbox
						className={styles.checkbox}
						checked={!!routeSheet.showOld}
						onChange={(evt) => {
							routeSheet.setShowOld(evt.target.checked);
							routeSheet.loadList();
						}}
					>
						Показать старые
					</Checkbox>
				)}
			</div>
			{path === 'emall' && (
				<div className={styles.actions}>
					<RadioButton
						options={[
							{ label: 'Консолидация', value: 'consolidation' },
							{ label: 'Товары', value: 'goods' },
						]}
						defaultValue={emall.tab}
						onChange={onRadioChange}
					/>
					{emall.tab === 'goods' && (
						<Button
							onClick={() => emall.goods.showModalAdd(true)}
							icon={<Icon imageName="Plus" stroke={isHover ? colors.blue2 : colors.black} fontSize={16} />}
							onMouseOver={() => setIsHover(true)}
							onMouseOut={() => setIsHover(false)}
							className={styles.button}
						>
							Добавить излишек
						</Button>
					)}
				</div>
			)}
		</>
	);
};

export default observer(ShipmentHeader);

const BadgedTab: FC<{ name: string; amount: number }> = ({ name, amount }) => {
	return (
		<div className={styles.tab}>
			<span>{name}</span>
			<span className={styles.tab__badge}>{amount}</span>
		</div>
	);
};
