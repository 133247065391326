import 'overlayscrollbars/overlayscrollbars.css';

import Button from '@components/buttons/button';
import { Collapse } from 'antd';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import cn from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useState } from 'react';

import styles from './index.module.scss';

type ContentProps = {
	text: string;
	fullText?: string;
};

const Content: FC<ContentProps> = ({ text, fullText }) => {
	const [collapsed, setCollapsed] = useState<boolean>(false);

	return (
		<div className={styles.content}>
			<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
				<p className={styles.text}>{text}</p>
				<Collapse bordered={false} expandIcon={() => null} onChange={() => setCollapsed(true)}>
					<CollapsePanel
						className={styles.panel}
						header={
							<>
								<span style={{ display: 'inline-block', height: 32, marginLeft: -1 }}></span>
								<Button
									type="text"
									className={cn(styles.button, { [styles.button__hide]: collapsed })}
									tabIndex={collapsed ? -1 : 0}
								>
									Подробнее
								</Button>
							</>
						}
						key="1"
					>
						<p className={styles.text} dangerouslySetInnerHTML={{ __html: fullText || '' }} />
					</CollapsePanel>
				</Collapse>
			</OverlayScrollbarsComponent>
		</div>
	);
};

export default Content;
