import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useState } from 'react';

import ConfirmModal from './components/confirm-modal';
import Header from './components/header';
import ItemsTable from './components/table';
import styles from './index.module.scss';

const ItemsModal: FC = () => {
	const [showConfirm, setShowConfirm] = useState(false);
	const {
		operator,
		acceptance: { emall },
	} = useStore();

	const close = (): void => {
		leave();
		// consolidation.loadSpace(null);
		emall.closeModalItems();
	};
	const submit = (): void => {
		setShowConfirm(true);
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (window.Echo && operator?.id && emall.modalItems && !showConfirm) {
			listenChannel(
				({ number }) => {
					emall.modalItems &&
						emall.modalItems?.scanBarcode(number).catch((err) => console.log('emall scanBarcode err:', err));
				},
				'.routing.number',
				operator.id
			);
		}

		return leave;
	}, [!!emall.modalItems, showConfirm]);

	return (
		<>
			<Modal
				title="Приемка пакетов Emall"
				okText="Завершить приемку"
				cancelText="Закрыть"
				open={!!emall.modalItems}
				width={720}
				onCancel={close}
				onOk={submit}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
			>
				<Header />
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<ItemsTable />
				</OverlayScrollbarsComponent>
			</Modal>
			<ConfirmModal isShow={showConfirm} setIsShow={setShowConfirm} />
		</>
	);
};

export default observer(ItemsModal);
