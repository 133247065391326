// import NoData from '@components/no-data';
import IconButton from '@components/buttons/icon_btn';
import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { IOrder } from '@store/acceptance/emall';
import { Tag, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const EmallTable: FC = () => {
	const {
		print,
		acceptance: { emall },
	} = useStore();

	const columns: ColumnsType<IOrder> = [
		{
			title: 'Номер заказа',
			dataIndex: 'number',
			render: (_, { order_market_id: id }) => <span>{id || ''}</span>,
			width: 116,
		},
		{
			title: 'ФИО получателя',
			dataIndex: 'name',
			render: (_, { receiver_fio: name }) => <span>{name}</span>,
			width: 332,
		},
		{
			title: 'Пакеты',
			render: (_, { bags }) => (
				<div>
					{bags
						? bags.map(({ name, is_scanned: isScan }) => (
								<Tooltip key={name} title={isScan ? 'Пакет принят' : 'Пакет не принят'}>
									<Tag className={cn(styles.tag, { [styles.tag__scan]: isScan })}>{name}</Tag>
								</Tooltip>
						  ))
						: null}
				</div>
			),
		},
		{
			title: '',
			width: 72,
			render: (_, order) => (
				<div className={styles.actions}>
					<Tooltip title="Товарный чек">
						<IconButton
							imageName="Sheet"
							onClick={() =>
								print.setPrintPreview({ type: 'sales-receipt', number: String(order.order_market_id), id: order.id })
							}
						/>
					</Tooltip>
					<Tooltip title="Инцидент">
						<IconButton imageName="Exclamation" onClick={() => emall.hideOrder(order)} />
					</Tooltip>
				</div>
			),
		},
	];

	if (!emall.orders || !emall.orders.length) return <NoData text="Нет заказов Emall" />;

	return (
		<Table
			pagination={false}
			columns={columns}
			dataSource={emall.orders ? [...emall.orders] : []}
			onRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
			onHeaderRow={() => ({ className: cn(styles.row, styles.row__head) })}
		/>
	);
};

export default observer(EmallTable);
