import Select, { SelectProps } from '@components/select';
import { PostalService } from '@services/postal';
import { FC, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

type Types = Awaited<ReturnType<typeof PostalService.getPostalComplaintType>>['data'];

const ComplainTypeSelection: FC<SelectProps & { isNew?: boolean }> = ({ isNew = false, ...props }) => {
	const [types, setTypes] = useState<Types>([]);
	const allTypes = useRef<Types>([]);

	useEffect(() => {
		(async () => {
			const types = await PostalService.getPostalComplaintType();
			allTypes.current = types.data;
			setTypes(types.data);
		})();
	}, []);

	return (
		<Select
			{...props}
			options={types
				.map(({ postal_complaint_type_id: id, postal_complaint_type_description: label }) => ({
					value: id,
					label,
				}))
				.filter(({ value }) => (value > 3 && value < 11) || (isNew && value === 14))}
			className={styles.select}
		/>
	);
};

export default ComplainTypeSelection;
