import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { ShipmentService } from '@services/shipment';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

interface IConfirmModal {
	isShow: boolean;
	setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmModal: FC<IConfirmModal> = ({ isShow, setIsShow }) => {
	const [sign, setSign] = useState('');
	const {
		print,
		operator,
		notice,
		shipment: {
			emall: { consolidation },
		},
	} = useStore();

	const closeConfirm = (): void => {
		leave();
		setIsShow(false);
		setSign('');
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (window.Echo && operator?.id && isShow) {
			listenChannel(({ number }) => setSign(number), '.routing.number', operator.id);
		}

		return leave;
	}, [isShow]);

	return (
		<Modal
			classNameModal={styles.confirm}
			className={styles.confirm__body}
			title={null}
			okText="Продолжить"
			cancelText="Отмена"
			open={isShow}
			onCancel={closeConfirm}
			onOk={() => {
				const id = consolidation.modalGoodsTable?.space?.id;
				if (!id) return;

				ShipmentService.putSpaces(id, { number: sign })
					.then(() => {
						const isPrint = consolidation.modalGoodsTable?.space?.hasSurplusScanned || false;
						consolidation.loadSpaces();
						consolidation.loadSpace(null);
						closeConfirm();
						isPrint && print.printTask({ type: 'routing-surplus', id });
					})
					.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }));
			}}
			okButtonProps={{ disabled: !sign }}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			<h3 className={styles.confirm__title}>Отсканируйте пломбу, которой закроете</h3>
			<Input value={sign} onChange={(evt) => setSign(evt.target.value)} />
		</Modal>
	);
};

export default observer(ConfirmModal);
