import { api } from '@http/index';
import { Order, Postal } from '@models/index';
import { AxiosResponse, GenericAbortSignal } from 'axios';

type PostalSearchItem = {
	id: number;
	number: string;
	type: 0 | 1 | 2;
	receiver_fio: string;
	receiver_phone: string;
	sender_fio: string;
	sender_phone: string;
	weight: string;
	external_id: string | null;
	agreement_type_id: number;
	market_status_name: string;
	market_status_value: number;
};

type OrderSearchItem = {
	id: number;
	market_id: number;
	receiver_fio: string;
	receiver_phone: string;
	status_pvz_name: string;
	status_pvz_value: number;
};
export interface SearchResp {
	postalItems: PostalSearchItem[];
	orders: OrderSearchItem[];
}

interface FindAdditionalResp {
	postalItems: Postal[];
	orders: Order[];
}

const findPostalExtended = (search: string, signal?: GenericAbortSignal): Promise<AxiosResponse<PostalSearchItem[]>> =>
	api.get<PostalSearchItem[]>(`search/postal-items/extended?search=${search}`, { signal });

const findOrderExtended = (search: string, signal?: GenericAbortSignal): Promise<AxiosResponse<OrderSearchItem[]>> =>
	api.get<OrderSearchItem[]>(`search/orders/extended?search=${search}`, { signal });

const findPostal = (search: string, signal?: GenericAbortSignal): Promise<AxiosResponse<SearchResp>> =>
	api.get<SearchResp>(`search/common?search=${search}`, { signal });

const findAdditional = (id: number, type: string): Promise<AxiosResponse<FindAdditionalResp>> =>
	api.get(`search/additional/${id}`, { params: { type } });

const findAdditionalByCode = (code: number): Promise<AxiosResponse<FindAdditionalResp>> =>
	api.get(`search/verification-code/${code}`);

const SearchService = { findPostal, findPostalExtended, findAdditional, findAdditionalByCode, findOrderExtended };

export default SearchService;
