import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { Modal as AntModal } from 'antd';
import Lottie from 'lottie-react';
import { FC, useState } from 'react';

import errorAnimation from '../../../../../../../assets/json/error.json';
import styles from './index.module.scss';

const ModalAdultConfirm: FC = () => {
	const visit = useCurrentVisit();
	const [isOpened, setIsOpened] = useState(visit?.hasAdultOrders);

	const handleCancelClick = (): void => {
		visit?.confirmAdult(false);
		setIsOpened(false);
	};

	const handleConfirmClick = (): void => {
		visit?.confirmAdult(true);
		setIsOpened(false);
	};

	return (
		<AntModal
			open={isOpened}
			title={<h2 className={styles.title}>Запросите документ, подтверждающий возраст клиента</h2>}
			width={416}
			className={styles.modal}
			closable={false}
			footer={
				<footer className={styles.footer}>
					<Button onClick={handleCancelClick}>Нет 18 лет</Button>
					<Button onClick={handleConfirmClick} type="primary">
						Возраст подтвержден
					</Button>
				</footer>
			}
			destroyOnClose
			centered
		>
			<Lottie animationData={errorAnimation} className={styles.icon} />
			<p>В заказе присутствует товар, предназначенный для лиц старше 18 лет</p>
		</AntModal>
	);
};

export default ModalAdultConfirm;
