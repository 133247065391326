import { api } from '@http/index';
import { Task } from '@store/tasks';
import { AxiosResponse } from 'axios';

const getTasks = (): Promise<AxiosResponse<Task>> => api.get('/task');

const processPostal = (id: number): Promise<AxiosResponse<{ message: string }>> =>
	api.post(`/task/postal/${id}/processing`);

export const TaskService = { getTasks, processPostal };
