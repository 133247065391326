import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import StoreService from '@services/store';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';

import styles from './index.module.scss';

const QrContent: FC = () => {
	const store = useStore();
	const [qr, setQr] = useState<ArrayBuffer | null>(null);

	useEffect(() => {
		StoreService.getQrCode().then((value) => setQr(value.data));
	}, []);

	return qr ? (
		<section className={styles.section}>
			<h3 className={styles.title}>Для экспедитора</h3>
			<Document className={styles.document} file={qr}>
				<Page pageNumber={1} width={204} height={204} scale={1} loading="Загрузка страницы" />
			</Document>
			<Button className={styles.button} onClick={() => store.print.printTask({ type: 'qr' })}>
				Печать QR-кода
			</Button>
		</section>
	) : null;
};

export default observer(QrContent);
