import Button from '@components/buttons/button';
import Modal from '@components/modal';
import Tabs from '@components/tabs';
import { useStore } from '@hooks/useStore';
import RoutingSpaces from '@pages/main/components/routing-spaces';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import ConfirmModal from './components/confirm-modal';
import Counters from './components/counters';
import DriverModal from './components/driver-modal';
import Info from './components/info';
import styles from './index.module.scss';

const InfoModal: FC = () => {
	const {
		operator,
		shipment: { modalInfo, closeModalInfo, routeSheet },
	} = useStore();
	const [tab, setTab] = useState<'info' | 'spaces'>('spaces');

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	const close = (): void => {
		leave();
		closeModalInfo();
		setTab('spaces');
	};

	const handleConfirmClick = (): void => {
		if (!modalInfo?.id) return;

		if (modalInfo.hasUnscanned) {
			modalInfo.setConfirmOpened(true);
		} else {
			routeSheet.setDriverModal('confirm');
		}
	};

	const tabs = [
		{ key: 'spaces', label: 'Грузоместа' },
		{ key: 'info', label: 'Доп. инфо' },
	];

	useEffect(() => {
		if (!modalInfo) return;

		if (!modalInfo.isView) {
			window.Echo &&
				operator?.id &&
				listenChannel(({ number }) => modalInfo.findSpace(number, { is_scanned: 1 }), '.routing.number', operator.id);
		}

		return leave;
	}, [modalInfo]);

	return (
		<>
			<Modal
				title={`Маршрутный лист ${modalInfo?.id || ''}`}
				footer={
					<div className={styles.footer}>
						<Button onClick={close}>Закрыть</Button>
						{!modalInfo?.isView && (
							<Button type="primary" onClick={handleConfirmClick} disabled={modalInfo?.submitDisabled}>
								Отгрузить
							</Button>
						)}
					</div>
				}
				open={!!modalInfo}
				width={880}
				onCancel={close}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
			>
				{modalInfo?.isView ? (
					<Tabs className={styles.tabs} items={tabs} activeKey={tab} onChange={(key) => setTab(key as typeof tab)} />
				) : (
					<Counters />
				)}
				{tab === 'spaces' ? (
					<RoutingSpaces
						spaces={modalInfo?.routing_spaces || []}
						isEdit={!modalInfo?.isView}
						onDelete={modalInfo?.findSpace}
					/>
				) : (
					<Info />
				)}
			</Modal>
			<DriverModal />
			<ConfirmModal />
		</>
	);
};

export default observer(InfoModal);
