import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL + '/api/ops';

const api = axios.create({ baseURL: API_BASE_URL });

api.interceptors.request.use((config) => {
	config.headers.OperatorOpsToken = localStorage.getItem('token');
	return config;
});

api.interceptors.response.use(
	(resp) => resp,
	(error) => {
		error.name !== 'CanceledError' && console.log('error', error);
		if (error.name === 'CanceledError') return Promise.reject(error);
		if (error.response?.status === 401) {
			localStorage.removeItem('token');
			window.location.href = '/';
		}
		if (error.config.url === 'payment') return Promise.reject(error);
		return Promise.reject(error.response?.data);
	}
);

export { api };
