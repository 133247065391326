import Button from '@components/buttons/button';
import Select from '@components/select';
import { useStore } from '@hooks/useStore';
import { Checkbox, Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

type ModalProps = Omit<AntModalProps, 'children'>;

const ModalBreakConfirm: FC<ModalProps> = ({ className, ...props }) => {
	const store = useStore();
	const [time, setTime] = useState(0);
	const [checked, setChecked] = useState(true);

	const onClose = (): void => {
		setChecked(true);
		setTime(0);
		store.showBreakConfirm(false);
	};

	const handleConfirmClick = async (): Promise<void> => {
		try {
			await store.openBreak({ type: 1, duration: time });
			checked && store.shift?.break && store.print.printTask({ type: 'break', id: store.shift.break.id });
			onClose();
		} catch (err: any) {
			store.notice.setNotice(err?.message || err?.err || 'Ошибка получения информации о перерыве');
		}
	};

	return (
		<AntModal
			{...props}
			open={store.breakConfirm}
			title={<h2 className={styles.title}>Перерыв</h2>}
			className={cn(className, styles.modal)}
			width={416}
			footer={
				<footer className={styles.footer}>
					<Button onClick={handleConfirmClick} disabled={!time}>
						Начать
					</Button>
					<Button onClick={onClose}>Отмена</Button>
				</footer>
			}
			onCancel={onClose}
			destroyOnClose
			centered
		>
			<div className={styles.body}>
				<p className={styles.description}>Выберите продолжительность технического перерыва:</p>
				<Select
					className={styles.select}
					options={[
						{ value: 5, label: '5 минут' },
						{ value: 10, label: '10 минут' },
						{ value: 15, label: '15 минут' },
					]}
					onChange={(value) => setTime(+String(value))}
				/>
				<Checkbox checked={checked} onChange={({ target }) => setChecked(target.checked)}>
					Печатать лист перерыва
				</Checkbox>
			</div>
		</AntModal>
	);
};

export default observer(ModalBreakConfirm);
