import { api } from '@http/index';
import { AxiosResponse } from 'axios';

interface Pack {
	id: number;
	name: string;
	default_value: number;
	is_measured: number;
	legal_entity_price: number;
	individual_price: number;
	image: string;
}

const getPacks = (): Promise<AxiosResponse<Pack[]>> => {
	return api.get('packing');
};

const PackingServise = { getPacks };
export default PackingServise;
