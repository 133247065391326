/* eslint-disable @typescript-eslint/no-empty-interface */
import { types } from 'mobx-state-tree';

const { model, maybeNull, union, literal, number } = types;

const RoutingHeader = model('routing-header', {
	count_routing_lists: maybeNull(number),
	has_postal: union(literal(0), literal(1)),
	has_emall: union(literal(0), literal(1)),
	has_money: union(literal(0), literal(1)),
});

export default RoutingHeader;
