import { api } from '@http/index';
import { Compensation } from '@store/compensations';
import { AxiosResponse } from 'axios';

const getCompensations = (): Promise<AxiosResponse<(typeof Compensation)[]>> => {
	return api.get(`compensate`);
};

const compensationPay = (id: number): Promise<AxiosResponse<void>> => api.post(`compensate/pay`, { id });

export const CompensationService = { getCompensations, compensationPay };
