import { Item } from '@components/popover/components/content';
import { FC } from 'react';

import styles from './index.module.scss';

type ContentProps = {
	items: Item[];
};

const Content: FC<ContentProps> = ({ items }) => {
	return (
		<ul className={styles.list}>
			{items.map(({ text, onClick }, i) => (
				<li className={styles.item} key={i}>
					<a className={styles.link} onClick={onClick}>
						<span>{text}</span>
					</a>
				</li>
			))}
		</ul>
	);
};

export default Content;
