import Input from '@components/input';
import { useStore } from '@hooks/useStore';
import { Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

// export interface IInspectsDataClientProps {}

const InspectsDataClient: FC = () => {
	const { indentification } = useStore();
	if (!indentification.user) return null;
	return (
		<>
			<div className={styles.wrapper}>
				<Input
					disabled
					value={indentification.user.phone_number}
					isPhone
					className={styles.input}
					placeholder="Телефон"
				/>
				<Input disabled value={indentification.user.surname || ''} className={styles.input} placeholder="Фамилия" />
				<Input disabled value={indentification.user.name || ''} className={styles.input} placeholder="Имя" />
				<Input
					disabled
					value={indentification.user.patronymic_name || ''}
					className={styles.input}
					placeholder="Отчество"
				/>
			</div>
			<Checkbox checked={indentification.isCheckedDocuments} onClick={indentification.setCheckedDoc}>
				Предъявленные документы проверены
			</Checkbox>
		</>
	);
};

export default observer(InspectsDataClient);
