/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, string } = types;

const User = model('User', { name: string });

export default User;

export interface User extends SnapshotIn<typeof User> {}
