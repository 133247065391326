import InputNumber from '@components/inputNumber';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import style from './index.module.scss';

const CanceledCheck: FC = () => {
	const { kassa } = useStore();
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
			<div className={style.row}>
				<div>Наличные</div>
				<div>
					<InputNumber
						style={{ height: 38, width: '100%' }}
						value={kassa.reportFull?.cash_canceled || 0}
						setValue={kassa.reportFull?.setCashCanceled}
						disabled={kassa.reportFull?.readonly}
					/>
				</div>
			</div>
			<div className={style.row}>
				<div>Карта</div>
				<div>
					<InputNumber
						style={{ height: 38, width: '100%' }}
						value={kassa.reportFull?.card_canceled || 0}
						setValue={kassa.reportFull?.setCardCanceled}
						disabled={kassa.reportFull?.readonly}
					/>
				</div>
			</div>
			<div className={style.row}>
				<div>Сертификат</div>
				<div>
					<InputNumber
						style={{ height: 38, width: '100%' }}
						value={kassa.reportFull?.certificate_canceled || 0}
						setValue={kassa.reportFull?.setCertificateCanceled}
						disabled={kassa.reportFull?.readonly}
					/>
				</div>
			</div>
		</div>
	);
};

export default observer(CanceledCheck);
