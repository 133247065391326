import colors from '@config/colors';
import { Button as AntButton, ButtonProps as AntButtonProps, ConfigProvider } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

type UsedProps = {
	type?: 'default' | 'primary' | 'text';
	customColorPrimaryHover?: string;
};

type ButtonProps = Omit<AntButtonProps, 'type' | 'ghost' | 'loading'> & UsedProps;

const Button: FC<ButtonProps> = ({ className, children, type, danger, customColorPrimaryHover, ...props }) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					fontSizeLG: 16,
					fontSizeSM: 14,
					colorPrimary: colors.blue,
					colorPrimaryBorder: colors.blue,
					colorPrimaryHover: customColorPrimaryHover || colors.blue2,
					colorPrimaryBorderHover: customColorPrimaryHover || colors.blue2,
					colorPrimaryActive: colors.blue3,
					colorBgTextHover: 'transparent',
					colorBgTextActive: 'transparent',
					borderRadius: 2,
					fontSizeIcon: 16,
				},
			}}
		>
			<AntButton
				className={cn(className, styles.button, {
					[styles.text]: type === 'text',
					[styles.danger]: danger,
					[styles.danger__text]: danger && type === 'text',
				})}
				type={type}
				danger={danger}
				{...props}
			>
				{children}
			</AntButton>
		</ConfigProvider>
	);
};

export default Button;
