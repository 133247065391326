import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import AddModal from './components/add-modal';
import GoodsTable from './components/table';

const Goods: FC = () => {
	const {
		shipment: {
			emall: { goods },
		},
	} = useStore();

	return (
		<>
			{goods.items && goods.items.length > 0 ? <GoodsTable /> : <NoData text="Товаров нет" />}
			<AddModal />
		</>
	);
};

export default observer(Goods);
