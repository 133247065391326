import CitySearchField from '@components/city-search';
import Input from '@components/input';
import Select from '@components/select';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';

const SenderInfo: FC = () => {
	const fieldStyle: CSSProperties = {
		width: 328,
	};
	const visit = useCurrentVisit();

	return (
		<>
			<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
				<Input
					placeholder="Телефон"
					style={fieldStyle}
					isPhone
					value={String(visit?.createOrderModel?.sender.phone_number)}
					onChange={(e) => visit?.createOrderModel?.sender.setPhone(e.target.value)}
					onBlur={(e) => visit?.createOrderModel?.getInfoByPhone(e.target.value)}
				/>
				<Input
					placeholder="Фамилия"
					required
					style={fieldStyle}
					value={String(visit?.createOrderModel?.sender.surname)}
					onChange={(e) => visit?.createOrderModel?.sender.setSurname(e.target.value)}
					disabled={visit?.createOrderModel?.isDisabledSenderFilds}
				/>
				<Input
					placeholder="Имя"
					required
					style={fieldStyle}
					value={String(visit?.createOrderModel?.sender.name)}
					onChange={(e) => visit?.createOrderModel?.sender.setName(e.target.value)}
					disabled={visit?.createOrderModel?.isDisabledSenderFilds}
				/>
				<Input
					placeholder="Отчество"
					required
					style={fieldStyle}
					value={String(visit?.createOrderModel?.sender.patronymic_name)}
					onChange={(e) => visit?.createOrderModel?.sender.setPatronymic(e.target.value)}
					disabled={visit?.createOrderModel?.isDisabledSenderFilds}
				/>
			</div>
			<div style={{ margin: '16px 0' }}>
				<CitySearchField
					required
					onSearch={() => {
						visit?.createOrderModel?.senderAddress.setStreet(null);
						visit?.createOrderModel?.senderAddress.setCity(null);
					}}
					onSelect={(val) => {
						visit?.createOrderModel?.senderAddress.setStreet(null);
						visit?.createOrderModel?.senderAddress.setCity(Number(val));
					}}
					placeholder="Населенный пункт"
					disabled={visit?.createOrderModel?.isDisabledSenderFilds}
					staticCity={visit?.createOrderModel?.isDisabledSenderFilds ? visit.createOrderModel.sender.staticCity : null}
				/>
			</div>

			<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
				<Select
					placeholder="Улица"
					required
					style={fieldStyle}
					hideArrow
					value={visit?.createOrderModel?.senderAddress.street}
					onChange={(val) => visit?.createOrderModel?.senderAddress.setStreet(Number(val))}
					options={visit?.createOrderModel?.senderAddress.streetList.map(({ id, name }) => ({
						value: id,
						label: name,
					}))}
					filterOption={(input, option) => String(option?.label).toLowerCase().includes(input.toLowerCase())}
					showSearch
					disabled={!visit?.createOrderModel?.senderAddress.city || visit?.createOrderModel?.isDisabledSenderFilds}
				/>
				<Input
					placeholder="Дом"
					required
					style={fieldStyle}
					value={visit?.createOrderModel?.senderAddress.house}
					onChange={(e) => visit?.createOrderModel?.senderAddress.setHouse(e.target.value)}
					disabled={visit?.createOrderModel?.isDisabledSenderFilds}
				/>
				<Input
					placeholder="Корпус"
					style={fieldStyle}
					value={visit?.createOrderModel?.senderAddress.building}
					onChange={(e) => visit?.createOrderModel?.senderAddress.setBuilding(e.target.value)}
					disabled={visit?.createOrderModel?.isDisabledSenderFilds}
				/>
				<Input
					placeholder="Квартира"
					style={fieldStyle}
					value={visit?.createOrderModel?.senderAddress.flat}
					onChange={(e) => visit?.createOrderModel?.senderAddress.setFlat(e.target.value)}
					disabled={visit?.createOrderModel?.isDisabledSenderFilds}
				/>
			</div>
		</>
	);
};

export default observer(SenderInfo);
