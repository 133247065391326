import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';
import { FC } from 'react';

export const AuthContext = React.createContext(null);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const token = localStorage.getItem('token');
	const user = localStorage.getItem('user');
	const store = useStore();

	if (token && user) {
		if (!store.user) {
			try {
				store.setUser(JSON.parse(user));
			} catch (e) {
				redirectToADFS();
				return null;
			}
		}
		return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
	} else {
		redirectToADFS();
		return null;
	}
};

export default observer(AuthProvider);

const redirectToADFS = (): void => {
	window.location.href = 'https://adfs.eurotorg.by/adfs/ls/IdpInitiatedSignOn.aspx';
};
