import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { priceFormat } from 'utils/priceFormat';

import styles from './index.module.scss';

export type ItemType = {
	id: number;
	number: string;
	fio: string;
	price: number;
};

const Item: FC<ItemType> = ({ id, number, fio, price }) => {
	const { compensations } = useStore();
	const handleItemClick = (): void => compensations.setActiveItem(id);

	return (
		//eslint-disable-next-line
		<li
			className={cn(styles.item, { [styles.item__active]: id === compensations.activeItem?.postal_complaint_id })}
			onClick={handleItemClick}
		>
			<span>
				<Icon className={styles.icon} imageName="Mail" />
				{number}
			</span>
			<span className={styles.fio}>{fio}</span>
			<span>{priceFormat(price)}</span>
		</li>
	);
};

export default observer(Item);
