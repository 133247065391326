import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { ConfigProvider, InputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

const SearchByCode: FC = () => {
	const [value, setValue] = useState<number | null>(null);
	const [error, setError] = useState<string | null>(null);
	const store = useStore();

	const find = (code: number): void => {
		const path = window.location.pathname;

		if (path.startsWith('/visits')) {
			const visit = store.visits.visitMap.get(path[path.length - 1]);
			visit
				?.setStartLoadData({ code }, false)
				.then(() => {
					setError('');
					setValue(null);
				})
				.catch((err: any) => {
					setValue(code);
					setError(err?.message || err?.err || 'Ничего не найдено');
				});
		}
	};

	const handleSearchChange = (value: number | null): void => {
		setError('');
		setValue(value);
	};

	const handleSearchKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
		if (evt.key === 'Enter') {
			value && find(+value);
		}
	};

	useEffect(() => {
		window.Echo &&
			store.operator?.id &&
			listenChannel(
				(data) => {
					console.log('.client.verification_code', data);
					data?.code && find(+data.code);
				},
				'.client.verification_code',
				store.operator?.id
			);

		return () => {
			window.Echo && store.operator?.id && leaveEvent('.client.verification_code', store.operator?.id);
		};
	}, [store.operator?.id]);

	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					boxShadowSecondary:
						'0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
				},
			}}
		>
			<div>
				<InputNumber
					maxLength={20}
					value={value}
					onChange={handleSearchChange}
					className={styles.search}
					placeholder="Поиск по коду"
					onKeyUp={handleSearchKeyUp}
				/>
				{error && <p className={styles.error}>{error}</p>}
			</div>
		</ConfigProvider>
	);
};

export default observer(SearchByCode);
