import colors, { Color } from '@config/colors';
import { Badge, Tooltip } from 'antd';
import Icon, { ImageName } from 'images';
import { FC } from 'react';

export interface PostalBriefInfoProps {
	image: Extract<
		ImageName,
		| 'Mail'
		| 'Payments'
		| 'ShoppingBag'
		| 'Evez'
		| 'Replay'
		| 'ReplayEmall'
		| 'MailWhite'
		| 'PaymentsWhite'
		| 'ShoppingBagWhite'
		| 'EvezWhite'
		| 'ReplayWhite'
		| 'ReplayEmallWhite'
	>;
	number: string | number;
	status: string;
	id: number;
	statusColor?: Extract<Color, 'blue' | 'green' | 'red'>;
	dot?: boolean;
	externalId?: string | null;
	isHighlight: boolean;
}

const PostalBriefInfo: FC<PostalBriefInfoProps> = ({
	image,
	number,
	status,
	statusColor = 'blue',
	dot,
	externalId,
	isHighlight,
}) => {
	const imageName = image + (isHighlight ? 'White' : '');

	return (
		<div style={{ display: 'flex', gap: 10, width: 150 }} id={`${number}`}>
			<div style={{ paddingTop: 5 }}>
				{(dot && (
					<Badge dot color="blue" offset={[-1, 3]}>
						<Icon imageName={imageName as typeof image} />
					</Badge>
				)) || <Icon imageName={imageName as typeof image} />}
			</div>
			<div>
				<div>
					<b>{number}</b>
				</div>
				{externalId && (
					<Tooltip title={externalId}>
						<span
							style={{
								display: 'block',
								maxWidth: 120,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							{externalId}
						</span>
					</Tooltip>
				)}
				<div style={{ color: isHighlight ? colors.white : colors[statusColor] }}>{status}</div>
			</div>
		</div>
	);
};

export default PostalBriefInfo;
