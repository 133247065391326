/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, maybeNull, union, literal } = types;

const SelectedItem = model('selected-item', {
	id: number,
	type: union(literal('postal'), literal('order')),
});

const ExtendedSearch = model('extended-search', {
	selectedItem: maybeNull(SelectedItem),
	idOpsChange: maybeNull(number),
	numberOpsChange: '',
}).actions((self) => ({
	setSelectedItem(item: ISelectedItem | null) {
		self.selectedItem = item;
	},
	setIdOpsChange(value: number | null) {
		self.idOpsChange = value;
	},
	setNumberOpsChange(value: string) {
		self.numberOpsChange = value;
	},
}));

export default ExtendedSearch;

export interface ISelectedItem extends SnapshotIn<typeof SelectedItem> {}
