import Select, { SelectProps } from '@components/select';
import DeliveryTypeService from '@services/deliveryType';
import { FC, useEffect, useState } from 'react';

const DeliveryTypeSelect: FC<SelectProps> = (props) => {
	const [types, setTypes] = useState<Awaited<ReturnType<typeof DeliveryTypeService.getDeliveryType>>['data']>([]);

	useEffect(() => {
		const id = setTimeout(() => {
			DeliveryTypeService.getDeliveryType()
				.then(({ data }) => setTypes(data))
				.catch((err) => console.log('DeliveryTypeService.getDeliveryType', err));
		}, 100);

		return () => clearTimeout(id);
	}, []);

	return (
		<Select
			placeholder="Тип доставки"
			options={types.map((type) => ({ value: type.id, label: type.name }))}
			{...props}
		/>
	);
};

export default DeliveryTypeSelect;
