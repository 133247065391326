import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import { ShipmentService } from '@services/shipment';
import { leaveEvent, listenChannel } from '@services/socket';
import { ConfigProvider, InputNumber as AntInputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

const AddModal: FC = () => {
	const {
		operator,
		shipment: {
			emall: { goods },
		},
	} = useStore();

	const [barcode, setBarcode] = useState<number | null>(null);
	const [count, setCount] = useState(1);
	const [bagNumber, setBagNumber] = useState<number | null>(null);
	const [orderNumber, setOrderNumber] = useState<number | null>(null);
	const [error, setError] = useState('');
	const [emptyBarcode, setEmptyBarcode] = useState(false);

	const closeModal = (): void => {
		leave();
		goods.showModalAdd(false);
		setBarcode(null);
		setBagNumber(null);
		setOrderNumber(null);
		setCount(1);
	};

	const handleBarcodeChange = (value: number | null): void => {
		setError('');
		setEmptyBarcode(false);
		setBarcode(value);
	};
	const handleBagNumberChange = (value: number | null): void => {
		setError('');
		setBagNumber(value);
	};

	const handleOrderNumberChange = (value: number | null): void => {
		setError('');
		setOrderNumber(value);
	};

	const handleCountChange = (value: number | null): void => {
		if (!value) return;
		setCount(value);
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	const handleSubmitClick = (): void => {
		if (!barcode) return setEmptyBarcode(true);
		if (String(barcode).length < 13) return setError('Проверьте правильность штрихкода');

		ShipmentService.postEmallItems({ barcode, count, bag_name: bagNumber, order_market_id: orderNumber })
			.then(() => {
				goods.loadItems();
				closeModal();
			})
			.catch((err) => setError(err?.message || err?.error || ''));
	};

	useEffect(() => {
		if (goods.modalAdd) {
			window.Echo &&
				operator?.id &&
				listenChannel(({ number }) => handleBarcodeChange(+number), '.routing.number', operator?.id);
		}

		return leave;
	}, [goods.modalAdd]);

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.modal__body}
			title={null}
			okText="Добавить"
			cancelText="Отмена"
			open={goods.modalAdd}
			onCancel={closeModal}
			onOk={handleSubmitClick}
			hasLines={false}
			centered
			width={400}
			closable={false}
			destroyOnClose
		>
			<ConfigProvider
				theme={{
					token: {
						controlHeight: 38,
						borderRadius: 4,
						colorBgContainerDisabled: '#fff',
						colorTextDisabled: colors.grey1,
					},
				}}
			>
				<h3 className={styles.modal__title}>Добавление товара - излишка</h3>
				<div className={styles.inputs}>
					<InputNumber
						className={styles.input}
						value={barcode}
						setValue={handleBarcodeChange}
						maxLength={50}
						placeholder="Штрихкод"
						required
						errorMessage={emptyBarcode ? 'Обязательное поле' : null}
					/>
					{String(barcode).length >= 13 ? (
						<AntInputNumber
							value={count}
							defaultValue={1}
							min={1}
							max={100}
							formatter={(value) => `${value} шт.`}
							parser={(value) => value?.replace(' шт.', '') as unknown as number}
							onChange={handleCountChange}
						/>
					) : null}
					<InputNumber
						className={styles.input}
						value={bagNumber}
						setValue={handleBagNumberChange}
						placeholder="Номер пакета"
					/>
					<InputNumber
						className={styles.input}
						value={orderNumber}
						setValue={handleOrderNumberChange}
						placeholder="Номер заказа"
					/>
				</div>
			</ConfigProvider>
			{error ? <p className={styles.error}>{error}</p> : null}
		</Modal>
	);
};

export default observer(AddModal);
