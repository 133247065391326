import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

type ModalProps = Omit<AntModalProps, 'children'>;

const ModalMessage: FC<ModalProps> = ({ className, ...props }) => {
	const store = useStore();

	const onClose = (): void => {
		store.setMessage(null);
	};

	return (
		<AntModal
			{...props}
			open={!!store.message}
			title={<h2 className={styles.title}>{store.message?.title || 'Ошибка'}</h2>}
			className={cn(className, styles.modal)}
			width={416}
			footer={
				<footer className={styles.footer}>
					<Button onClick={onClose}>Закрыть</Button>
				</footer>
			}
			closable={false}
			centered
		>
			{store.message?.html ? (
				<div className={styles.description} dangerouslySetInnerHTML={{ __html: store.message?.html }} />
			) : store.message?.description ? (
				<p className={styles.description}>{store.message?.description}</p>
			) : null}
		</AntModal>
	);
};

export default observer(ModalMessage);
