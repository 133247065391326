import Badge from '@components/badge';
import Button from '@components/buttons/button';
import Loading from '@components/loading';
import Modal from '@components/modal';
import colors from '@config/colors';
import { useMessage } from '@hooks/useMessage';
import { useStore } from '@hooks/useStore';
// import OperatorService from '@services/operator';
// import { KassaService } from '@services/kassa';
import { createSocketConnection, listenChannel } from '@services/socket';
import { ConfigProvider, Layout, Menu, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import Lottie from 'lottie-react';
import { Observer, observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import criticalAnimation from '../../assets/json/critical.json';
import errorAnimation from '../../assets/json/error.json';
import progressAnimation from '../../assets/json/progress.json';
import Icon from '../../images';
import MainHeader from './components/header';
import ModalMessage from './components/modal-message';
import OpenShift from './components/open-shift';
import SelectPrinterModal from './components/select-printer-modal';
import styles from './index.module.scss';

type TriggerProps = {
	collapsed: boolean;
};

const Trigger: FC<TriggerProps> = ({ collapsed }) => (
	<ConfigProvider
		theme={{
			token: {
				colorIcon: '#000',
			},
		}}
	>
		<div className={styles.trigger}>
			<Icon
				imageName="LeftArrow"
				className={styles.trigger_icon}
				color={colors.grey1}
				rotate={collapsed ? 180 : undefined}
			/>
		</div>
	</ConfigProvider>
);

let timerIdForPrintModal: any;

const Main: FC = () => {
	const [collapsed, setCollapsed] = useState(false);
	const [open, contextHolder] = useMessage({ duration: 7 });
	const location = useLocation();
	const navigate = useNavigate();
	const store = useStore();

	const selectMenu: MenuProps['onSelect'] = ({ key }): void => {
		navigate(key);
	};

	const closePrintModal = (): void => {
		store.kassa.setPrintStatus(null);
	};

	const handleMessageSocket = (data: { title: string; description: string }): void => {
		store.setMessage(data);
	};

	useEffect(() => {
		if (location.pathname === '/') {
			navigate('/visits');
		}
	}, []);

	// useEffect(() => {
	// 	clearInterval(intervalId);
	// 	if (!store.shift || location.pathname.startsWith('/tasks')) return;

	// 	store.loadTask(true);
	// 	intervalId = setInterval(() => store.loadTask(true), 5 * 60 * 1000);
	// 	return () => clearInterval(intervalId);
	// }, [store.shift, location]);

	useEffect(() => {
		store.notice.message && open(store.notice);
		//eslint-disable-next-line
	}, [store.notice.count]);

	useEffect(() => {
		if (store.shift) {
			if (store.operator?.id) {
				store.setOperator();
			} else {
				store
					.setOperator()
					.then((data) => {
						const id = data.id;
						createSocketConnection();
						listenChannel(
							({ data }) => {
								store.operator?.setPos(data.is_pos_enabled);
								store.kassa.setOpenQrModal(false);
								store.notice.setNotice({ message: 'Касса успешно подключена!', type: 'success', hasButton: false });
							},
							'.pos.enabled',
							id
						);
						listenChannel(() => clearTimeout(timerIdForPrintModal), '.pos.accepted', id);
						listenChannel(
							() => {
								store.kassa.setPrintStatus(null);

								if (!store.kassa.reportFull.isZReport) {
									const path = window.location.pathname;

									if (path.startsWith('/visits')) {
										const visit = store.visits.visitMap.get(path[path.length - 1]);
										visit?.clearVisit();
									}
								}
							},
							'.receipt.save.success',
							id
						);
						listenChannel(
							({ data }) => {
								navigate('/kassa');
								store.shift?.setIsClosedByKassa(true);
								store.kassa.openReportModal(data);
								store.kassa.setZReport(false);
								closePrintModal();
							},
							'.receipt',
							id
						);
						listenChannel((data) => store.kassa.setPrintData(data), '.receipt.error', id);
						listenChannel(handleMessageSocket, '.operator.notification', id);
						window.Echo.channel(`web.operator.public`).listen('.operator.notification', handleMessageSocket);
						listenChannel(
							(data) => {
								const path = window.location.pathname.split('/');

								if (path[1] === 'visits') {
									const visit = store.visits.visitMap.get(path[2]);
									visit?.findPostal(data.number);
								}
							},
							'.postal.number',
							id
						);
					})
					.catch((err) => console.log('operator/info error: ', err));
			}
		}

		if (store.shift?.status === 0) {
			let time = 0;

			const activityHandler = (): void => {
				time = 0;
			};

			addEventListener('mousemove', activityHandler);
			addEventListener('click', activityHandler);
			addEventListener('keydown', activityHandler);

			const interval = setInterval(() => {
				time += 1;
				if (time >= 10) store.openBreak({ type: 2 });
			}, 60 * 1000);

			return () => {
				removeEventListener('mousemove', activityHandler);
				removeEventListener('click', activityHandler);
				removeEventListener('keydown', activityHandler);
				clearInterval(interval);
			};
		} else if (!store.shift) {
			store.checkShift();
		}
	}, [store.shift]);

	const leftMenuItems = [
		{
			icon: <Icon className={styles.icon} imageName="People" />,
			label: 'Визиты',
			key: 'visits',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="PlaylistAddCheck" />,
			label: 'Задания',
			key: 'tasks',
			disabled: !store.shift,
			count: store.tasks?.tasksAmount,
		},
		// {
		// 	icon: <Icon className={styles.icon} imageName="ArrowUp" rotate={180} />,
		// 	label: 'Приемка',
		// 	key: 'acceptance',
		// 	disabled: !store.shift,
		// },
		// {
		// 	icon: <Icon className={styles.icon} imageName="ArrowUp" />,
		// 	label: 'Отгрузка',
		// 	key: 'shipment',
		// 	disabled: !store.shift,
		// },
		{
			icon: <Icon className={styles.icon} imageName="ReceiptLong" />,
			label: 'Касса',
			key: 'kassa',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="Compensations" />,
			label: 'Возмещения',
			key: 'compensations',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="FileCopy" />,
			label: 'Отчеты',
			key: 'reports',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="IdentificationIconNav" />,
			label: 'Идентификация',
			key: 'identification',
			disabled: !store.shift,
		},
		{
			icon: <Icon className={styles.icon} imageName="SearchPlus" />,
			label: 'Расширенный поиск',
			key: 'extended-search',
			disabled: !store.shift,
		},
		// {
		// 	icon: <Icon className={styles.icon} imageName="LocalShipping" />,
		// 	label: 'Логистика',
		// 	key: 'logistics',
		// 	disabled: !store.shift,
		// },
		// { icon: <Icon className={styles.icon} imageName="FileCopy" />, label: 'Отчеты', key: 'reports' },
		// { icon: <Icon className={styles.icon} imageName="LibraryBooks" />, label: 'Инвентаризация', key: 'inventory' },
	].map((item) => ({
		...item,
		className: styles.menu_item,
		label:
			'count' in item ? (
				<Observer>
					{() => (
						<Badge count={item.count} size="small" offset={[6, 0]} color="red">
							{item.label}
						</Badge>
					)}
				</Observer>
			) : (
				item.label
			),
	}));

	if (!store.dataLoaded) return null;

	// return <div className={styles.message}>Технические работы</div>;

	return (
		<>
			{/* <div className={cn(styles.message__clearable)}>Технические работы</div> */}
			<Layout className={styles.main}>
				{/* <button onClick={() => OperatorService.broadcastEvent('4810703132863')} style={{ zIndex: 9999 }}>
					Отправить websocket
				</button> */}
				<MainHeader />
				<Layout>
					<Sider
						className={styles.sider}
						theme="light"
						collapsible
						width={207}
						collapsedWidth={53}
						trigger={<Trigger collapsed={collapsed} />}
						onCollapse={(collapsed) => setCollapsed(collapsed)}
					>
						<ConfigProvider
							theme={{
								token: { borderRadius: 0 },
								components: {
									Menu: { itemSelectedColor: colors.blue },
								},
							}}
						>
							<Menu
								items={leftMenuItems}
								mode="inline"
								selectedKeys={[location.pathname.split('/')[1]]}
								onSelect={selectMenu}
								className={styles.menu}
							/>
						</ConfigProvider>
					</Sider>
					{!store?.shift ? <OpenShift /> : <Outlet />}
				</Layout>
			</Layout>
			<SelectPrinterModal />
			<Loading show={store.loadingForce || store.loading} />
			<Modal
				className={styles.modal__kassa}
				title={null}
				open={store.kassa.printStatus === 'process'}
				hasLines={false}
				centered
				width={416}
				closable={false}
				footer={null}
				afterOpenChange={(open) => {
					if (open) {
						timerIdForPrintModal = setTimeout(() => {
							store.notice.setNotice({
								message: 'Платежный терминал не отвечает, проверьте работоспособность терминала',
							});
							closePrintModal();
						}, 30000);
					}
				}}
			>
				<Lottie animationData={progressAnimation} className={styles.lottie__progress} />
				<h3 className={styles.kassa__title}>Идет печать чека...</h3>
			</Modal>
			<Modal
				className={styles.modal__kassa}
				title={null}
				open={store.kassa.printStatus === 'error' || store.kassa.printStatus === 'critical'}
				hasLines={false}
				centered
				width={416}
				closable={false}
				footer={
					<footer className={styles.modal__kassa_footer}>
						<Button onClick={closePrintModal}>Закрыть</Button>
						{/* {store.kassa.printStatus === 'error' ? (
							<Button
								onClick={() => {
									store.setLoading(true);
									KassaService.sendPrintRepeat()
										.then(() => store.kassa.setPrintStatus('process'))
										.catch((err) => console.log('sendPrintRepeat: ', err))
										.finally(() => store.setLoading(false));
								}}
								type="primary"
							>
								Повторить печать чека
							</Button>
						) : null} */}
					</footer>
				}
			>
				<Lottie
					animationData={store.kassa.printStatus === 'error' ? errorAnimation : criticalAnimation}
					className={store.kassa.printStatus === 'error' ? styles.lottie__error : styles.lottie__critical}
				/>
				<h3 className={styles.modal__kassa_title}>{store.kassa.printData?.message || ''}</h3>
			</Modal>
			<ModalMessage />
			{contextHolder}
		</>
	);
};

export default observer(Main);
