import colors from '@config/colors';
import Icon from '@images/index';
import { ConfigProvider, Modal as AntModal, ModalProps } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

const Modal: FC<ModalProps & { hasLines?: boolean; classNameModal?: string }> = ({
	children,
	className,
	title = 'Модальное окно',
	hasLines = true,
	classNameModal,
	...props
}) => {
	return (
		<ConfigProvider theme={{ token: { colorPrimary: colors.blue } }}>
			<AntModal
				{...props}
				title={<h2 className={styles.title}>{title}</h2>}
				closeIcon={<Icon imageName="WinClose" fontSize={16} />}
				className={cn(styles.modal, classNameModal, { [styles.modal__no_lines]: !hasLines })}
				destroyOnClose
			>
				{children && <div className={cn(className, styles.body)}>{children}</div>}
			</AntModal>
		</ConfigProvider>
	);
};

export default Modal;
