import NoData from '@components/no-data';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import MailTasksTable from './components/table';

const MailTasks: FC = () => {
	const store = useStore();

	useEffect(() => {
		if (store.tasks?.search?.length) store.tasks.setSearch('');
	}, []);

	return store.tasks?.postalAmount ? <MailTasksTable /> : <NoData text="Заданий нет" />;
};

export default observer(MailTasks);
