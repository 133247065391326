import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { Layout, message } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { priceFormat } from 'utils/priceFormat';

import Compensation from './components/compensation';
import CompensationsList from './components/list';
import NoCompensations from './components/no-compensations';
import styles from './index.module.scss';

const Compensations: FC = () => {
	const { compensations } = useStore();
	const [messageApi, contextHolder] = message.useMessage({
		top: 64,
		duration: 2,
		prefixCls: 'messages',
	});

	useEffect(() => {
		compensations.loadCompensations();
	}, []);

	useEffect(() => {
		if (compensations.errorMessage) {
			messageApi.open({
				type: 'error',
				content: compensations.errorMessage,
				className: 'message',
				onClose: () => compensations.setErrorMessage(''),
			});
		}
		//eslint-disable-next-line
	}, [compensations.errorMessage]);
	return (
		<Layout>
			<Header className={styles.header} style={{ height: 72 }}>
				<h4>Возмещения</h4>
			</Header>
			<Content className={styles.content}>
				{compensations.list ? (
					<>
						<CompensationsList />
						<Compensation />
					</>
				) : (
					<NoCompensations />
				)}
			</Content>
			{contextHolder}
			<Modal
				title={
					<span className={styles.modal__title}>
						Подтвердите выдачу средств - {priceFormat(compensations.activeItem?.final_sum)}
					</span>
				}
				open={compensations.confirmModal}
				hasLines={false}
				centered
				width={460}
				footer={
					<footer className={styles.modal__footer}>
						<Button onClick={compensations.closeConfirmModal}>Отмена</Button>
						<Button type="primary" onClick={compensations.pay}>
							Подтверждаю
						</Button>
					</footer>
				}
				closable={false}
			/>
		</Layout>
	);
};

export default observer(Compensations);
