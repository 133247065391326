import Button from '@components/buttons/button';
import Icon from '@images/index';
import { Modal as AntModal, ModalProps } from 'antd';
import cn from 'classnames';
import { FC } from 'react';

import styles from './index.module.scss';

const ModalExit: FC<ModalProps & { onConfirm: () => void; onCancel: () => void }> = ({
	className,
	onConfirm,
	onCancel,
	...props
}) => {
	return (
		<AntModal
			{...props}
			title={<h2 className={styles.title}>Вы точно хотите выйти?</h2>}
			closeIcon={<Icon imageName="Close" fontSize={16} />}
			className={cn(className, styles.modal)}
			width={460}
			footer={
				<footer className={styles.footer}>
					<Button onClick={onCancel}>Отмена</Button>
					<Button type="primary" onClick={onConfirm}>
						Выйти
					</Button>
				</footer>
			}
			closable={false}
			centered
		/>
	);
};

export default ModalExit;
