import { Order } from '@models/index';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { priceFormat } from 'utils/priceFormat';

import People from '../people';
import styles from './index.module.scss';

interface OrderInfoProps {
	order: Order | null;
	isExtended?: boolean;
}

const OrderInfo: FC<OrderInfoProps> = ({ order, isExtended }) => {
	return order ? (
		<div className={styles.body}>
			<ul className={styles.list}>
				<li>
					{order?.bags?.map(({ name, id }) => (
						<Tag key={id}>{name}</Tag>
					))}
				</li>
				<li className={styles.item}>
					<span>Сумма заказа</span>
					<span>{priceFormat(order.sum)}</span>
				</li>
				{!!order.certificate_sum && (
					<li className={styles.item}>
						<span>Сумма сертификата</span>
						<span>{priceFormat(order.certificate_sum)}</span>
					</li>
				)}
				<li className={styles.item}>
					<span>
						{order.payment_type === 1
							? 'Оплата при получений'
							: order.payment_type === 7
							? 'Оплачено в кредит'
							: 'Оплата онлайн'}
					</span>
				</li>
				{isExtended && order.arrival_at && (
					<li className={styles.item}>
						<span>Дата доставки</span>
						<span>{dayjs(order.arrival_at).format('DD.MM.YYYY HH:mm')}</span>
					</li>
				)}
				{isExtended && order.issued_at && (
					<li className={styles.item}>
						<span>Дата выдачи</span>
						<span>{dayjs(order.issued_at).format('DD.MM.YYYY HH:mm')}</span>
					</li>
				)}
			</ul>
			<People
				title="Получатель"
				fio={order.receiver_fio}
				phone={(!isExtended && order.receiver_phone) || ''}
				address={order.store_name}
			/>
		</div>
	) : null;
};

export default OrderInfo;
