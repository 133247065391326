import colors from '@config/colors';
import Icon, { ImageName } from '@images/index';
import { ConfigProvider, Divider, Typography } from 'antd';
import { FC } from 'react';
import { priceFormat } from 'utils/priceFormat';

import styles from './index.module.scss';

type Props = { compensation: string | null; refund: string | null; final: number | null };

const Summary: FC<Props> = ({ compensation, refund, final }) => {
	return (
		<ConfigProvider theme={{ components: { Divider: { colorSplit: colors.grey3 } } }}>
			<div className={styles.summary}>
				<SummaryItem name="Компенсация, руб." value={priceFormat(compensation, false)} />
				<div className={styles.divider}>
					<Divider type="vertical" />
				</div>
				<SummaryItem name="Возврат, руб" value={priceFormat(refund, false)} />
				<div className={styles.divider}>
					<Divider type="vertical" />
				</div>
				<SummaryItem icon="ArrowCircleDown" name="Общий итог, руб." value={priceFormat(final, false)} text="Выдать" />
			</div>
		</ConfigProvider>
	);
};

const SummaryItem: FC<{ name: string; value: string; text?: string; icon?: ImageName }> = ({
	name,
	value,
	text,
	icon,
}) => (
	<div className={styles.item__wrap}>
		<Typography.Text className={styles.name}>{name}</Typography.Text>
		<div className={styles.value}>
			{icon && <Icon imageName={icon} fontSize={32} rotate={180} color={colors.grey1} />}

			<h3>
				{text && `${text} `}
				{value}
			</h3>
		</div>
	</div>
);

export default Summary;
