import { Postal } from '@store/visits/postal';
import { FC } from 'react';

import PostalItems from '../items';
import People from '../people';
import styles from './index.module.scss';

interface PostalInfoProps {
	postal: Postal | null;
	isExtended?: boolean;
}

const PostalInfo: FC<PostalInfoProps> = ({ postal, isExtended }) => {
	return postal ? (
		<div className={styles.body}>
			<PostalItems {...postal} isExtended={isExtended} />
			{!(isExtended && postal.type === 2) && (
				<People
					title="Отправитель"
					fio={`${postal.sender.surname} ${postal.sender.name} ${postal.sender.patronymic_name}`}
					phone={(!isExtended && postal.sender.phone_number) || ''}
					address={postal.stores?.start?.name}
				/>
			)}
			<People
				title="Получатель"
				fio={`${postal.receiver.surname} ${postal.receiver.name} ${postal.receiver.patronymic_name}`}
				phone={(!isExtended && postal.receiver.phone_number) || ''}
				address={
					(postal.delivery_type === 1 && postal.stores?.finish?.name) ||
					(postal.delivery_type === 2 && !!postal.pickup_address && postal.pickup_address[0]?.AddressNameFull)
				}
			/>
		</div>
	) : null;
};

export default PostalInfo;
