/* eslint-disable @typescript-eslint/no-empty-interface */
import { PostalService } from '@services/postal';
import { flow, getParent, getRoot, Instance, types } from 'mobx-state-tree';

import { RootStore } from '..';
import Visit from './visit';

const { model, optional, number, string, maybeNull } = types;

const CreateIncident = model('createIncident', {
	actType: maybeNull(number),
	reason: maybeNull(number),
	weight: maybeNull(number),
	comment: maybeNull(string),
	number: maybeNull(string),
	store: maybeNull(number),
	complaintId: maybeNull(number),
	errorMessage: optional(string, ''),
	isActOnOpen: false,
	isActOnVisual: false,
	isActClaim: false,
	isNew: false,
	isEdit: false,
	status: maybeNull(number),
})
	.actions((self) => ({
		setActType(type: number | null) {
			self.actType = type;
		},
		setReason(reason: number | null) {
			self.reason = reason;
		},
		setWeight(weight: number | null) {
			self.weight = weight;
		},
		setComment(comment: string | null) {
			self.comment = comment;
		},
		setNumber(number: string | null) {
			self.number = number;
		},
		setStore(store: number | null) {
			self.store = store;
		},
		setError(message: string) {
			self.errorMessage = message;
		},
		toggleActOnOpen() {
			self.isActOnOpen = !self.isActOnOpen;
		},
		toggleActOnVisual() {
			self.isActOnVisual = !self.isActOnVisual;
		},
		toggleActClaim() {
			self.isActClaim = !self.isActClaim;
		},
		save: flow(function* () {
			const root = getRoot<typeof RootStore>(self);
			root.setLoading(true);
			try {
				let complaint: { data: { id: number } };

				if (self.isEdit) {
					if (!self.complaintId) return false;
					complaint = yield PostalService.postalComplaintEdit(self.complaintId, {
						info: self.comment || '',
						postal_complaint_id: self.complaintId,
						postal_weight: self.weight,
					});
				} else {
					complaint = yield PostalService.postalComplaintSave({
						store_id: self.store,
						info: self.comment || '',
						postal_complaint_type_id: self.reason,
						postal_item_number: self.number || '',
						direction: self.actType ? self.actType - 1 : null,
						postal_weight: self.weight,
					});
				}

				const actTypes = [
					self.isActClaim && 'claim-act',
					self.isActOnOpen && 'autopsy-act',
					self.isActOnVisual && 'defective-condition-act',
				];
				const id = complaint.data.id;
				const isNew = !self.isEdit;
				let filesCount = 0;

				actTypes.forEach((type) => {
					if (!type) return;
					filesCount++;
					setTimeout(
						() => root.print.printTask({ type, id, copies: isNew && type !== 'defective-condition-act' ? '2' : '1' }),
						filesCount * 100
					);
				});

				const visit = getParent<typeof Visit>(self);
				!self.isNew && visit.icidents.id && visit.icidents.loadIncidents(visit.icidents.id);
				visit.cancelIncident();
			} catch (err: any) {
				self.errorMessage = err?.message || err?.error;
			} finally {
				root.setLoading(false);
			}
		}),
	}))
	.views((self) => ({
		get isFieldsFill() {
			return !!((self.actType || self.actType === 0) && self.reason);
		},
	}));

export default CreateIncident;

export type CreateIncidentModel = Instance<typeof CreateIncident>;
