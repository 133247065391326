import { api } from '@http/index';
import { AxiosResponse } from 'axios';

const getReportOrdersPdf = (date: string): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`cash-register-report/pdf`, {
		params: { date_from: date, date_to: date },
		responseType: 'arraybuffer',
	});

const getReportOrdersXlsx = (date: string): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`cash-register-report/xlsx`, {
		params: { date_from: date, date_to: date },
		responseType: 'arraybuffer',
		headers: { 'Content-Type': 'blob' },
	});

const getReportPostalsPdf = (date: string, type: number): Promise<AxiosResponse<ArrayBuffer>> => {
	return api.get(`cash-register-report/employees/pdf`, {
		params: { date_from: date, date_to: date, type },
		responseType: 'arraybuffer',
	});
};

const getReportPostalsXlsx = (date: string, type: number): Promise<AxiosResponse<ArrayBuffer>> =>
	api.get(`cash-register-report/employees/xlsx`, {
		params: { date_from: date, date_to: date, type },
		responseType: 'arraybuffer',
		headers: { 'Content-Type': 'blob' },
	});

export const ReportsService = { getReportOrdersPdf, getReportOrdersXlsx, getReportPostalsPdf, getReportPostalsXlsx };
