/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import colors from '@config/colors';
import Icon from '@images/index';
import { Popover } from 'antd';
import { FC, ReactElement, useState } from 'react';

import Counter from '../counter';
import styles from './index.module.scss';

interface PackItemProps {
	title: ReactElement;
	quantity?: number;
	price: string;
	image: string;
	onAdd?: () => void;
	onRemove?: () => void;
}

const PackItem: FC<PackItemProps> = ({ title, quantity = 0, price, onAdd, onRemove, image }) => {
	const [popoverOpen, setPopoverOpen] = useState(false);
	return (
		<div className={styles.item}>
			{image && (
				// <Popover className={styles.popover} content={<img src={image} alt="no-data" width={232} height={232} />}>
				<span className={styles.icon} onMouseOver={() => setPopoverOpen(true)} onMouseOut={() => setPopoverOpen(false)}>
					<Icon imageName="Photo" />
				</span>
				// </Popover>
			)}
			{(!!quantity && (
				<div style={{ display: 'flex', flex: 1, alignItems: 'center', height: '100%' }}>
					<Title title={title} price={price} hasImage={!!image} />
					<Counter value={quantity} onAdd={onAdd} onRemove={onRemove} />
				</div>
			)) || (
				<div onClick={onAdd} style={{ display: 'flex', flex: 1 }}>
					<Title price={price} title={title} hasImage={!!image} />
					<div style={{ flexBasis: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Icon imageName="Plus" fontSize={13} stroke={colors.black} style={{ cursor: 'pointer' }} />
					</div>
				</div>
			)}
			<Popover
				className={styles.popover}
				content={<img src={image} alt="no-data" width={232} height={232} />}
				open={popoverOpen}
			>
				<span style={{ position: 'absolute', left: 160, top: 12 }}></span>
			</Popover>
		</div>
	);
};

export default PackItem;

const Title: FC<{ title: ReactElement; price: string; hasImage: boolean }> = ({ title, price, hasImage }) => (
	<>
		<span style={{ width: hasImage ? 600 - 33 : 600 }}>{title}</span>
		<b style={{ alignSelf: 'center', flexGrow: 1 }}>{price}</b>
	</>
);
