/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, optional, union, literal } = types;

const Notice = model('Notice', {
	message: '',
	type: optional(union(literal('error'), literal('success')), 'error'),
	hasButton: true,
	count: 0,
}).actions((self) => ({
	setNotice({
		message,
		type = 'error',
		hasButton = true,
	}: {
		message: string;
		type?: 'error' | 'success';
		hasButton?: boolean;
	}) {
		message && self.count++;
		self.message = message;
		self.type = type;
		self.hasButton = hasButton;
	},
}));

export default Notice;

export interface INotice extends SnapshotIn<typeof Notice> {}
